import { configureStore } from '@reduxjs/toolkit';
import { loadingSlice } from './loading';
import { accountSlice } from './account';
import { userSlice } from './user';
import { roleSlice } from './role';
import { popupSlice } from './popup';
import { errorSlice } from './error';
import { consolidationSlice } from './consolidation';
import { permissionSlice } from './permission';

export const store = configureStore({
  reducer: {
    loading: loadingSlice.reducer,
    account: accountSlice.reducer,
    user: userSlice.reducer,
    role: roleSlice.reducer,
    popup: popupSlice.reducer,
    error: errorSlice.reducer,
    consolidation: consolidationSlice.reducer,
    permission: permissionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

export * from './loading';
export * from './account';
export * from './user';
export * from './role';
export * from './popup';
export * from './error';
export * from './consolidation';
export * from './permission';

import { createSlice } from '@reduxjs/toolkit';
import { ILoadingState } from './types';
import * as reducers from './reducers';

export const loadingState: ILoadingState = {
  loadingScreen: {
    show: false,
  },
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: loadingState,
  reducers,
});

export const { setLoadingScreen } = loadingSlice.actions;

export * from './selectors';

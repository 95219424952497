import { createSlice } from '@reduxjs/toolkit';
import { IPopupState } from './types';
import * as reducers from './reducers';

export const popupState: IPopupState = {
  popupMessage: {
    show: false,
    type: 'success',
    title: '',
    message: '',
    onClose: undefined,
  },
  floatingUser: {
    show: false,
  },
  popupConfirmation: {
    show: false,
    title: '',
    message: '',
    cancelText: 'Tidak',
    confirmText: 'Ya',
    onCancel: undefined,
    onClose: undefined,
    onConfirmed: undefined,
  },
  formChangePassword: {
    show: false,
  },
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState: popupState,
  reducers,
});

export const {
  setPopupMessage,
  clearPopupMessage,
  setFloatingUser,
  setPopupConfirmation,
  clearPopupConfirmation,
  setFormChangePassword,
} = popupSlice.actions;

export * from './selectors';

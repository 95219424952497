import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  DefaultOptions,
  concat,
} from '@apollo/client';
import { appConfig } from 'configs';
import { createUploadLink } from 'apollo-upload-client';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {
    fetchPolicy: 'network-only',
  },
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem('reconAuth');

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  }));

  return forward(operation);
});

const uploadLink = createUploadLink({
  uri: appConfig.gqlEndpoint,
}) as unknown as ApolloLink;

export const apoloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, uploadLink),
  defaultOptions,
});

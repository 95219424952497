import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation loginMutation($user: LoginUserInput!) {
    login(user: $user) {
      id
      name
      email
      accessToken
    }
  }
`;

import { imgUser } from 'assets';
import {
  Wrapper,
  Avatar,
  Content,
  Title,
  Subtitle,
  DrodownIcon,
  IconWrapper,
} from './styles';

interface Props {
  name: string;
  role: string;
  onClick?: () => void;
}

function DropdownUser({ name, role, onClick }: Props): JSX.Element {
  return (
    <Wrapper>
      <Avatar src={imgUser} />
      <Content>
        <Title>{name}</Title>
        <Subtitle>{role}</Subtitle>
      </Content>
      <IconWrapper onClick={onClick}>
        <DrodownIcon />
      </IconWrapper>
    </Wrapper>
  );
}

export default DropdownUser;

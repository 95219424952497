import { gql } from '@apollo/client';

export const LIST_BANK = gql`
  query listBank($input: ListBankInput!) {
    listBank(listBankInput: $input) {
      page
      limit
      total
      totalPage
      banks {
        id
        code
        name
        bankCode
        createdAt
        updatedAt
      }
    }
  }
`;

import { memo } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Wrapper, ImageWrapper, Image, Label, Message } from './styles';

interface Props {
  label?: string;
  message?: string;
  success?: boolean;
  failed?: boolean;
  onClick?: () => void;
}

function CardRecon({
  label,
  message,
  success,
  failed,
  onClick,
}: Props): JSX.Element {
  return (
    <>
      <Card className="mb-4" style={{ height: 250 }}>
        <Card.Body>
          <Wrapper>
            <ImageWrapper>
              <Image />
            </ImageWrapper>
            {label && <Label>{label}</Label>}
            {message && <Message>{message}</Message>}
          </Wrapper>
        </Card.Body>
      </Card>
      {success && (
        <Button type="button" variant="info text-light" onClick={onClick}>
          Download Final Consolidation
        </Button>
      )}
      {failed && (
        <Button type="button" variant="danger text-light" onClick={onClick}>
          Download Unreconciled File
        </Button>
      )}
    </>
  );
}

export default memo(CardRecon);

import { imgDanger } from 'assets';
import { Modal, Button } from 'react-bootstrap';
import { confirmation } from 'controls';
import {
  IconWrapper,
  Icon,
  Message,
  Title,
  ContentWrapper,
  ButtonWrapper,
} from './styles';

type TextType = string | React.ReactElement | React.ReactNode | JSX.Element;

interface Props {
  show?: boolean;
  title?: TextType;
  message?: TextType;
  cancelText?: TextType;
  confirmText?: TextType;
  onClose?: () => void;
  onCancel?: () => void;
  onConfirmed?: () => void;
}

function PopupConfirmation({
  show,
  title,
  message,
  cancelText,
  confirmText,
  onClose,
  onCancel,
  onConfirmed,
}: Props): JSX.Element | null {
  function onClosePopup(): void {
    confirmation({ show: false });
    onClose?.();
  }

  function onClickCancel(): void {
    onClosePopup();
    onCancel?.();
  }

  function onClickConfirmed(): void {
    onClosePopup();
    onConfirmed?.();
  }

  function renderMessage(): JSX.Element | null {
    if (message) {
      return <Message>{message}</Message>;
    }

    return null;
  }

  function renderTitle(): JSX.Element | null {
    if (title) {
      return <Title className="text-warning">{title}</Title>;
    }
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onClosePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <ContentWrapper>
          <IconWrapper>
            <Icon src={imgDanger} />
          </IconWrapper>
          {renderTitle()}
          {renderMessage()}
          <ButtonWrapper>
            <Button
              variant="dark"
              onClick={onClickConfirmed}
              className="d-block w-100"
            >
              {confirmText || 'Ya'}
            </Button>
            <Button
              variant="secondary"
              onClick={onClickCancel}
              className="d-block w-100"
            >
              {cancelText || 'Tidak'}
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
      </Modal.Body>
    </Modal>
  );
}

export default PopupConfirmation;

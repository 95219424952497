export enum STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum STATUSREPORT {
  SUCCESS = 'SUCCESS',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  FAILED = 'FAILED',
  SYSTEM_FAILED = 'SYSTEM_FAILED',
  WAITING = 'WAITING',
}

export enum ISSUERS {
  OVO = 'Ovo',
  SHOPEE = 'Shopee',
  LINK_AJA = 'LinkAja',
  MANDIRI_LIVIN = 'Mandiri Livin',
  KREDIVO = 'Kredivo',
  UANGME = 'UangME',
}

export enum FILETYPES {
  KREDIVO_SETTLEMENT = 'LinkAja MCD Portal',
  LINKAJA_MCD_PORTAL = 'LinkAja MCD Portal',
  LINKAJA_MCD_REPORT = 'LinkAja MCD Report',
  LINKAJA_SETTLEMENT = 'LinkAja Settlement',
  MANDIRI_LIVIN_SETTLEMENT = 'Mandiri Livin Settlement',
  OVO_SETTLEMENT = 'Ovo Settlement',
  SHOPPE_SETTLEMENT = 'Shoppe Settlement',
  YTI_LINKAJA_MCD_SETTLEMENT = 'YTI LinkAja MCD Settlement',
  YTI_SETTLEMENT = 'YTI Settlement',
  PHP_DELIVERY_REPORT = 'PHP Delivery Report',
  DATA_EXPANSION = 'Data Expansion',
  LINKAJA_REPORT = 'LinkAja Report',
}

export enum MERCHANT_TYPES {
  MSME = 'MSME',
  MCD = 'MCD',
  BREADLIFE = 'BREADLIFE',
  SOLARIA = 'SOLARIA',
  EXPANSION = 'EXPANSION',
}

export enum RECON_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum PERMISSIONS {
  USER = 'user',
  ROLE = 'role',
  CHARGING_SETUP = 'charging_setup',
  MERCHANT_TYPE = 'merchant_type',
  CLEANUP_DATA = 'clean_up',
  CONSOLIDATE_DATA = 'consolidate_data',
  FINALIZE_SETTLEMENT = 'finalize_settlement',
  GENERATE_QR = 'generate_qr',
  CONSOLIDATE_REPORT = 'consolidate_report',
  MERGE_DATA = 'merge_data',
}

export enum PERMISSION_LABELS {
  USER = 'Management User',
  ROLE = 'Management Role',
  CHARGING_SETUP = 'Charging Setup',
  MERCHANT_TYPE = 'Recon Merchant Type',
  CLEANUP_DATA = 'Clean Up Data',
  CONSOLIDATE_DATA = 'Consolidate Data',
  FINALIZE_SETTLEMENT = 'Finalize Settlement',
  GENERATE_QR = 'Generate QR',
  CONSOLIDATE_REPORT = 'Consolidate Report',
  MERGE_DATA = 'Merge Data',
}

export enum NUMBER_TYPES {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export enum NUMBER_TYPE_LABELS {
  AMOUNT = 'Rp',
  PERCENTAGE = '%',
}

export enum TRANSACTION_TYPES {
  QRIS = 'QRIS',
  CARD = 'CARD',
  CARD_ONLINE = 'CARD_ONLINE',
  VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT',
  EWALLET = 'EWALLET',
  GIFTCARD = 'GIFTCARD',
}

export enum TRANSACTION_TYPE_LABELS {
  QRIS = 'QRIS',
  CARD = 'Card',
  CARD_ONLINE = 'Card Online',
  VIRTUAL_ACCOUNT = 'Virtual Account',
  EWALLET = 'E-Wallet',
  GIFTCARD = 'Gift Card',
}

import { createSlice } from '@reduxjs/toolkit';
import { IConsolidationState } from './types';
import * as reducers from './reducers';

const defaultState = {
  page: 0,
  limit: 10,
  total: 0,
  totalPage: 1,
  error: false,
  loading: false,
  data: [],
};

export const consolidationState: IConsolidationState = {
  listMerchant: defaultState,
  listMerchantType: defaultState,
  listReportConsolidate: defaultState,
  listIssuer: defaultState,
  listFileType: defaultState,
  listBank: {
    page: 0,
    total: 0,
    totalPage: 1,
    error: false,
    loading: false,
    data: [],
  },
  listAcquirer: {
    page: 0,
    total: 0,
    totalPage: 1,
    error: false,
    loading: false,
    data: [],
  },
};

export const consolidationSlice = createSlice({
  name: 'consolidation',
  initialState: consolidationState,
  reducers,
});

export const {
  setMerchants,
  clearMerchants,
  setMerchantTypes,
  clearMerchantTypes,
  setReportConsolidate,
  clearReportConsolidate,
  setIssuers,
  clearIssuers,
  setFileTypes,
  clearFileTypes,
  setBanks,
  clearBanks,
  clearAcquirers,
  setAcquirers,
} = consolidationSlice.actions;

import { apoloClient, cleanUpGql } from 'modules';
import {
  CleanUpRequest,
  CleanUpResponse,
  CleanUpMandiriMutate,
  CleanUpKredivoMutate,
  CleanUpLinkAjaMutate,
  CleanUpShopeeMutate,
  CleanUpOvoMutate,
  CleanUpUangMeMutate,
  CleanUpMutateVariables,
  CleanUpExpansionMutate,
} from './interfaces';
import { MESSAGES, ISSUERS, MERCHANT_TYPES } from 'utils';

export async function cleanUpMandiri(
  merchantTypeId: string | undefined,
  ytiMcdSettlementFile: string | undefined,
  settlementFile: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpMandiriMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_MANDIRI,
    variables: {
      merchantTypeId,
      ytiMcdSettlementFile,
      settlementFile,
    },
  });

  if (!response.data?.cleanUpMandiriV2) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const { ytiMcdSettlementCloudStorageUrl, mandiriCloudStorageUrl } =
    response.data.cleanUpMandiriV2;

  return {
    ytiMcdSettlement: ytiMcdSettlementCloudStorageUrl,
    issuerSettlement: mandiriCloudStorageUrl,
  };
}

export async function cleanUpKredivo(
  merchantTypeId: string | undefined,
  ytiMcdSettlementFile: string | undefined,
  settlementFile: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpKredivoMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_KREDIVO,
    variables: {
      merchantTypeId,
      ytiMcdSettlementFile,
      settlementFile,
    },
  });

  if (!response.data?.cleanUpKredivoV2) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const { ytiMcdSettlementCloudStorageUrl, kredivoCloudStorageUrl } =
    response.data.cleanUpKredivoV2;

  return {
    ytiMcdSettlement: ytiMcdSettlementCloudStorageUrl,
    issuerSettlement: kredivoCloudStorageUrl,
  };
}

export async function cleanUpShopee(
  merchantTypeId: string | undefined,
  ytiMcdSettlementFile: string | undefined,
  settlementFile: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpShopeeMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_SHOPEE,
    variables: {
      merchantTypeId,
      ytiMcdSettlementFile,
      settlementFile,
    },
  });

  if (!response.data?.cleanUpShopeeV2) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const { ytiMcdSettlementCloudStorageUrl, shopeeCloudStorageUrl } =
    response.data.cleanUpShopeeV2;

  return {
    ytiMcdSettlement: ytiMcdSettlementCloudStorageUrl,
    issuerSettlement: shopeeCloudStorageUrl,
  };
}

export async function cleanUpLinkAja(
  merchantTypeId?: string | undefined,
  ytiMcdSettlementFile?: string | undefined,
  ytiSettlementFile?: string | undefined,
  linkAjaMcdReportFile?: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpLinkAjaMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_LINKAJA,
    variables: {
      merchantTypeId,
      ytiMcdSettlementFile,
      ytiSettlementFile,
      linkAjaMcdReportFile,
    },
  });

  if (!response.data?.cleanUpLinkAjaV2) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const {
    linkAjaMcdReportCloudStorageUrl,
    ytiMcdSettlementCloudStorageUrl,
    ytiSettlementCloudStorageUrl,
  } = response.data.cleanUpLinkAjaV2;

  return {
    linkAjaMcdReport: linkAjaMcdReportCloudStorageUrl,
    ytiMcdSettlement: ytiMcdSettlementCloudStorageUrl,
    ytiSettlement: ytiSettlementCloudStorageUrl,
  };
}

export async function cleanUpOvo(
  ytiMcdSettlementFile?: string | undefined,
  ovoSettlementFile?: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpOvoMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_OVO,
    variables: {
      ovoSettlementFile,
      ytiMcdSettlementFile,
    },
  });

  if (!response.data?.cleanUpOvo) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const { ovoSettlementUrl, ytiMcdSettlementUrl } = response.data.cleanUpOvo;

  return {
    ovoSettlement: ovoSettlementUrl,
    ytiMcdSettlement: ytiMcdSettlementUrl,
  };
}

export async function cleanUpUangMe(
  ytiMcdSettlementFile?: string | undefined,
  ytiSettlementFile?: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpUangMeMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_UANGME,
    variables: {
      ytiSettlementFile,
      ytiMcdSettlementFile,
    },
  });

  if (!response.data?.cleanUpUangMe) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const { ytiSettlementUrl, ytiMcdSettlementUrl } = response.data.cleanUpUangMe;

  return {
    ytiSettlement: ytiSettlementUrl,
    ytiMcdSettlement: ytiMcdSettlementUrl,
  };
}

export async function cleanUpExpansion(
  merchantTypeId?: string | undefined,
  ytiSettlementExpansionFile?: string | undefined,
): Promise<CleanUpResponse> {
  const response = await apoloClient.mutate<
    CleanUpExpansionMutate,
    CleanUpMutateVariables
  >({
    mutation: cleanUpGql.CLEANUP_UANGME,
    variables: {
      merchantTypeId,
      ytiSettlementExpansionFile,
    },
  });

  if (!response.data?.cleanUpExpansion) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  const { ytiMcdSettlementCloudStorageUrl } = response.data.cleanUpExpansion;

  return {
    ytiSettlementExpansion: ytiMcdSettlementCloudStorageUrl,
  };
}

export async function cleanUp(
  request: CleanUpRequest,
): Promise<CleanUpResponse> {
  switch (request.issuer?.label) {
    case ISSUERS.MANDIRI_LIVIN:
      return await cleanUpMandiri(
        request.merchantType?.value,
        request.ytiSettlementFile,
        request.issuerFile,
      );
    case ISSUERS.KREDIVO:
      return await cleanUpKredivo(
        request.merchantType?.value,
        request.ytiSettlementFile,
        request.issuerFile,
      );
    case ISSUERS.SHOPEE:
      return await cleanUpShopee(
        request.merchantType?.value,
        request.ytiSettlementFile,
        request.issuerFile,
      );
    case ISSUERS.LINK_AJA:
      if (request.merchantType?.label === MERCHANT_TYPES.EXPANSION) {
        return await cleanUpExpansion(
          request.merchantType?.value,
          request.ytiSettlementExpansionFile,
        );
      }
      return await cleanUpLinkAja(
        request.merchantType?.value,
        request.ytiMcdSettlementFile,
        request.ytiSettlementFile,
        request.linkAjaMcdReportFile,
      );
    case ISSUERS.OVO:
      return await cleanUpOvo(
        request.ovoSettlementFile,
        request.ytiMcdSettlementFile,
      );
    case ISSUERS.UANGME:
      return await cleanUpUangMe(
        request.ytiSettlementFile,
        request.ytiMcdSettlementFile,
      );
    default:
      throw new Error(MESSAGES.NO_RETURN_DATA);
  }
}

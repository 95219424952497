import { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector, shallowEqual } from 'react-redux';
import { MainContent, PageInfo } from 'components';
import { useForm, useDebounce } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import {
  getBanks,
  getAcquirers,
  getMerchantTypes,
  getMerchants,
  deleteMerchant,
  createMerchant,
  updateMerchant,
  bulkCreateMerchant,
} from 'services';
import { IChargingSetupInput as IChargingSetup } from 'services/merchant/interfaces';
import {
  IMerchant,
  OptionsType,
  IFormButtonOptionsChange,
  ILabelValue,
  MergeTypes,
} from 'types';
import { listMerchantSelector, profileSelector } from 'modules';
import FormComponent from './Form';
import FormUpload from './FormUpload';
import ContentMessage from './ContentMessage';
import Table from './Table';
import { ActionMeta, SingleValue } from 'react-select';
import {
  numberTypeOptions,
  isAuthenticated,
  NUMBER_TYPES,
  NUMBER_TYPE_LABELS,
  PERMISSION_LABELS,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_LABELS,
} from 'utils';
import { popupMessage, errorBag, loadingScreen, confirmation } from 'controls';

export interface FormChargingSetup1 {
  id?: string;
  acquiringBank?: ILabelValue;
  mdrOnUs: number;
  mdrOnUsType: ILabelValue;
  mdrOffUs: number;
  mdrOffUsType: ILabelValue;
  chargingOnUs: number;
  chargingOnUsType: ILabelValue;
  chargingOffUs: number;
  chargingOffUsType: ILabelValue;
  serviceFee: number;
  serviceFeeType: ILabelValue;
}
export interface FormChargingSetup2 {
  id?: string;
  acquiringBank?: ILabelValue;
  mdr: number;
  mdrType: ILabelValue;
  charging: number;
  chargingType: ILabelValue;
  serviceFee: number;
  serviceFeeType: ILabelValue;
}

export interface ErrorChargingSetup1 {
  acquiringBank: string;
  mdrOnUs: string;
  mdrOnUsType: string;
  mdrOffUs: string;
  mdrOffUsType: string;
  chargingOnUs: string;
  chargingOnUsType: string;
  chargingOffUs: string;
  chargingOffUsType: string;
  serviceFee: string;
  serviceFeeType: string;
}

export interface ErrorChargingSetup2 {
  acquiringBank: string;
  mdr: string;
  mdrType: string;
  charging: string;
  chargingType: string;
  serviceFee: string;
  serviceFeeType: string;
}

export type FormChargingSetup = MergeTypes<
  FormChargingSetup1 | FormChargingSetup2
>;

export type ErrorChargingSetup = MergeTypes<
  ErrorChargingSetup1 | ErrorChargingSetup2
>;

export type FormState = {
  id: string;
  mid: string;
  name: string;
  merchantType?: ILabelValue;
  address: string;
  ownerName: string;
  bank?: ILabelValue;
  bankAccount: string;
  merchantFile?: File | Blob;
};

export type ErrorState = {
  id: string;
  mid: string;
  name: string;
  merchantType: string;
  address: string;
  ownerName: string;
  bank: string;
  bankAccount: string;
  merchantFile: string;
};

export interface MapForm {
  name: string;
  label: string;
  form: FormChargingSetup;
  error: ErrorChargingSetup;
}

const defaultNumberType = {
  label: NUMBER_TYPE_LABELS.AMOUNT,
  value: NUMBER_TYPES.AMOUNT,
};

const formState: FormState = {
  id: '',
  mid: '',
  name: '',
  merchantType: undefined,
  address: '',
  ownerName: '',
  bank: undefined,
  bankAccount: '',
  merchantFile: undefined,
};

const errorState: ErrorState = {
  id: '',
  mid: '',
  name: '',
  merchantType: '',
  address: '',
  ownerName: '',
  bank: '',
  bankAccount: '',
  merchantFile: '',
};

const formChargingSetup1: FormChargingSetup1 = {
  acquiringBank: undefined,
  mdrOnUs: 0,
  mdrOnUsType: defaultNumberType,
  mdrOffUs: 0,
  mdrOffUsType: defaultNumberType,
  chargingOnUs: 0,
  chargingOnUsType: defaultNumberType,
  chargingOffUs: 0,
  chargingOffUsType: defaultNumberType,
  serviceFee: 0,
  serviceFeeType: defaultNumberType,
};

const errorChargingSetup1: ErrorChargingSetup1 = {
  acquiringBank: '',
  mdrOnUs: '',
  mdrOnUsType: '',
  mdrOffUs: '',
  mdrOffUsType: '',
  chargingOnUs: '',
  chargingOnUsType: '',
  chargingOffUs: '',
  chargingOffUsType: '',
  serviceFee: '',
  serviceFeeType: '',
};

const formChargingSetup2: FormChargingSetup2 = {
  acquiringBank: undefined,
  mdr: 0,
  mdrType: defaultNumberType,
  charging: 0,
  chargingType: defaultNumberType,
  serviceFee: 0,
  serviceFeeType: defaultNumberType,
};

const errorChargingSetup2: ErrorChargingSetup2 = {
  acquiringBank: '',
  mdr: '',
  mdrType: '',
  charging: '',
  chargingType: '',
  serviceFee: '',
  serviceFeeType: '',
};

function ChargingSetup(): JSX.Element {
  // state
  const profile = useSelector(profileSelector, shallowEqual);
  const list = useSelector(listMerchantSelector, shallowEqual);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formComponent, setFormComponent] = useState<boolean>(false);
  const [formUpload, setFormUpload] = useState<boolean>(false);

  const [form, setForm] = useForm(formState);
  const [error, setError] = useForm(errorState);
  const [qrisForm, setQrisForm] = useForm(formChargingSetup1);
  const [qrisError, setQrisError] = useForm(errorChargingSetup1);
  const [cardForm, setCardForm] = useForm(formChargingSetup1);
  const [cardError, setCardError] = useForm(errorChargingSetup1);
  const [cardOnlineForm, setCardOnlineForm] = useForm(formChargingSetup1);
  const [cardOnlineError, setCardOnlineError] = useForm(errorChargingSetup1);
  const [vaForm, setVaForm] = useForm(formChargingSetup2);
  const [vaError, setVaError] = useForm(errorChargingSetup2);
  const [ewalletForm, setEwalletForm] = useForm(formChargingSetup2);
  const [ewalletError, setEwalletError] = useForm(errorChargingSetup2);
  const [giftCardForm, setGiftCardForm] = useForm(formChargingSetup2);
  const [giftCardError, setGiftCardError] = useForm(errorChargingSetup2);

  const [searchParams, setSearchParam] = useSearchParams();
  const defaultLimit = 10;
  const page = searchParams.get('page') || 0;
  const limit = searchParams.get('limit') || defaultLimit;
  const search = searchParams.get('search') || '';

  const debouncedSearchQuery = useDebounce(search, 500);

  const items = list.data || [];
  const isLogin = isAuthenticated();

  // variable
  const isEdit = Boolean(form.id);

  const cTx1 = ['qris', 'card', 'card_online'];
  const cTx2 = ['virtual_account', 'ewallet', 'giftcard'];

  function onChangePage(e: React.MouseEvent<HTMLAnchorElement>): void {
    const dataPage = e.currentTarget.getAttribute('data-page');
    const pageNumber = Number(dataPage);
    const urlParams = new URLSearchParams();
    urlParams.set('page', String(pageNumber));

    if (limit) {
      urlParams.set('limit', String(limit));
    }
    setSearchParam(urlParams);
  }

  function onChange(formName: string, name: string, value: unknown): void {
    switch (formName) {
      case 'qris':
        if (qrisError[name as keyof typeof qrisError] !== '') {
          setQrisError(name, '');
        }
        setQrisForm(name, value);
        break;
      case 'card':
        if (cardError[name as keyof typeof cardError] !== '') {
          setCardError(name, '');
        }
        setCardForm(name, value);
        break;
      case 'cardOnline':
        if (cardOnlineError[name as keyof typeof cardOnlineError] !== '') {
          setCardOnlineError(name, '');
        }
        setCardOnlineForm(name, value);
        break;
      case 'va':
        if (vaError[name as keyof typeof vaError] !== '') {
          setVaError(name, '');
        }
        setVaForm(name, value);
        break;
      case 'ewallet':
        if (ewalletError[name as keyof typeof ewalletError] !== '') {
          setEwalletError(name, '');
        }
        setEwalletForm(name, value);
        break;
      case 'giftCard':
        if (giftCardError[name as keyof typeof giftCardError] !== '') {
          setGiftCardError(name, '');
        }
        setGiftCardForm(name, value);
        break;
      default:
        // eslint-disable-next-line security/detect-object-injection
        if (error[name as keyof typeof error] !== '') {
          setError(name, '');
        }
        setForm(name, value);
        break;
    }
  }

  function onChangeFile(name: string, value: File): void {
    if (value !== form[name as keyof typeof form]) {
      setForm(name, value);

      if (error[name as keyof typeof error]) {
        setError(name, '');
      }
    }
  }

  function resetForm(): void {
    setForm('reset', null);
    setQrisForm('reset', null);
    setCardForm('reset', null);
    setCardOnlineForm('reset', null);
    setVaForm('reset', null);
    setEwalletForm('reset', null);
    setGiftCardForm('reset', null);

    setError('reset', null);
    setQrisError('reset', null);
    setCardError('reset', null);
    setCardOnlineError('reset', null);
    setVaError('reset', null);
    setEwalletError('reset', null);
    setGiftCardError('reset', null);
  }

  function onChangeFormInput(e: React.ChangeEvent<HTMLInputElement>): void {
    let { name, value }: { name: string; value: unknown } = e.target;
    let formName = '';

    if (name.indexOf('.') !== -1) {
      const [a, b] = name.split('.');
      formName = a;
      name = b;
    }
    onChange(formName, name, value);
  }

  function onChangeSelectInput(
    value: SingleValue<OptionsType>,
    meta: ActionMeta<OptionsType>,
  ): void {
    let name = meta.name || '';
    let formName = '';

    if (name.indexOf('.') !== -1) {
      const [a, b] = name.split('.');
      formName = a;
      name = b;
    }

    onChange(formName, name, value?.value ? value : undefined);
  }

  function onChangeOptionButton({
    name,
    value,
  }: IFormButtonOptionsChange): void {
    let formName = '';

    if (name.indexOf('.') !== -1) {
      const [a, b] = name.split('.');
      formName = a;
      name = b;
    }

    onChange(formName, name, value);
  }

  function onCloseForm(): void {
    setFormComponent(false);
    resetForm();
  }

  function onCloseFormUpload(): void {
    setFormUpload(false);
    resetForm();
  }

  function onAdd(): void {
    setFormComponent(true);
    getBanks({
      page: 0,
      order: {
        orderBy: 'NAME',
        sortBy: 'ASC',
      },
    });
    getAcquirers({
      page: 0,
      order: {
        orderBy: 'NAME',
        sortBy: 'ASC',
      },
    });
    getMerchantTypes({
      page: 0,
      limit: 25,
      order: {
        orderBy: 'NAME',
        sortBy: 'ASC',
      },
    });
  }

  function onBulkAdd(): void {
    setFormUpload(true);
  }

  function createNumberType(value?: string): ILabelValue {
    if (!value) return defaultNumberType;

    const result = numberTypeOptions.filter((v) => v.value === value)[0];

    return result ? result : defaultNumberType;
  }

  function onEdit(data: IMerchant): void {
    setForm({
      id: data.id || '',
      name: data.name || '',
      address: data.address || '',
      bankAccount: data.bankAccount || '',
      mid: data.mid || '',
      ownerName: data.ownerName || '',
      bank: {
        label: data.bankName || '',
        value: data.bankId || '',
      },
      merchantType: {
        label: data.merchantType?.name || '',
        value: data.merchantType?.id || '',
      },
    });

    data.chargingSetups?.forEach((setup) => {
      if (setup?.transactionType && cTx1.includes(setup.transactionType)) {
        const cForm1: FormChargingSetup1 = {
          id: setup.id,
          acquiringBank: {
            label: setup.acquirer?.name || '',
            value: setup.acquirer?.id || '',
            ...setup.acquirer,
          },
          mdrOnUs: Number(setup.mdrOnUs),
          mdrOnUsType: createNumberType(setup.mdrOnUsType),
          mdrOffUs: Number(setup.mdrOffUs),
          mdrOffUsType: createNumberType(setup.mdrOffUsType),
          chargingOnUs: Number(setup.chargingOnUs),
          chargingOnUsType: createNumberType(setup.chargingOnUsType),
          chargingOffUs: Number(setup.chargingOffUs),
          chargingOffUsType: createNumberType(setup.chargingOffUsType),
          serviceFee: Number(setup.serviceFee),
          serviceFeeType: createNumberType(setup.serviceFeeType),
        };

        switch (setup.transactionType) {
          case 'qris':
            setQrisForm(cForm1);
            break;
          case 'card':
            setCardForm(cForm1);
            break;
          case 'card_online':
            setCardOnlineForm(cForm1);
            break;
          default:
            break;
        }
      }
      if (setup?.transactionType && cTx2.includes(setup.transactionType)) {
        const cForm2: FormChargingSetup2 = {
          id: setup.id,
          mdr: Number(setup.mdr),
          mdrType: createNumberType(setup.mdrType),
          charging: Number(setup.charging),
          chargingType: createNumberType(setup.chargingType),
          serviceFee: Number(setup.serviceFee),
          serviceFeeType: createNumberType(setup.serviceFeeType),
        };

        switch (setup.transactionType) {
          case 'va':
            setVaForm(cForm2);
            break;
          case 'ewallet':
            setEwalletForm(cForm2);
            break;
          case 'giftcard':
            setGiftCardForm(cForm2);
            break;
          default:
            break;
        }
      }
    });

    setFormComponent(true);
    getBanks({
      page: 0,
      order: {
        orderBy: 'NAME',
        sortBy: 'ASC',
      },
    });
    getAcquirers({
      page: 0,
      limit: 25,
      order: {
        orderBy: 'NAME',
        sortBy: 'ASC',
      },
    });
    getMerchantTypes({
      page: 0,
      limit: 25,
      order: {
        orderBy: 'NAME',
        sortBy: 'ASC',
      },
    });
  }

  function confirmDelete(data: IMerchant): void {
    loadingScreen(true);
    deleteMerchant(data?.id || '')
      .then(function () {
        loadingScreen(false);
        popupMessage({
          show: true,
          type: 'success',
          title: 'Berhasil Dihapus',
          message: 'Hi, Merchant berhasil dihapus',
        });
        getMerchants({
          page: Number(page),
          limit: Number(limit),
        });
      })
      .catch(function (err) {
        errorBag(err);
      });
  }

  function onDelete(data: IMerchant): void {
    confirmation({
      title: 'Mau Hapus?',
      onConfirmed: () => confirmDelete(data),
    });
  }

  function validateForm(): [boolean, ErrorState] {
    const newError = { ...error };

    if (!form.mid) {
      newError.mid = 'Mohon masukkan MID';
    }

    if (!form.name) {
      newError.name = 'Mohon masukkan nama merchant type';
    }

    if (!form.merchantType) {
      newError.merchantType = 'Mohon pilih merchant type';
    }

    if (!form.address) {
      newError.address = 'Mohon masukan alamat';
    }

    if (!form.ownerName) {
      newError.ownerName = 'Mohon masukan nama owner';
    }

    if (!form.bank) {
      newError.bank = 'Mohon pilih bank';
    }

    if (!form.bankAccount) {
      newError.bankAccount = 'Mohon masukan akun bank';
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  function validateFormUpload(): [boolean, ErrorState] {
    const newError = { ...error };

    if (!form.merchantFile) {
      newError.merchantFile = 'Mohon masukan filenya';
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  function mapChargingSetup(): Array<IChargingSetup> {
    const result = [];
    const qris: IChargingSetup = {
      id: qrisForm.id || null,
      transactionType: TRANSACTION_TYPES.QRIS,
      acquirerId: qrisForm.acquiringBank?.value || null,
      chargingOffUs: Number(qrisForm.chargingOffUs),
      chargingOffUsType: qrisForm.chargingOffUsType.value.toUpperCase(),
      chargingOnUs: Number(qrisForm.chargingOnUs),
      chargingOnUsType: qrisForm.chargingOnUsType.value.toUpperCase(),
      mdrOffUs: Number(qrisForm.mdrOffUs),
      mdrOffUsType: qrisForm.mdrOffUsType.value.toUpperCase(),
      mdrOnUs: Number(qrisForm.mdrOnUs),
      mdrOnUsType: qrisForm.mdrOnUsType.value.toUpperCase(),
      serviceFee: Number(qrisForm.serviceFee),
      serviceFeeType: qrisForm.serviceFeeType.value.toUpperCase(),
    };

    const card: IChargingSetup = {
      id: cardForm.id || null,
      transactionType: TRANSACTION_TYPES.CARD,
      acquirerId: cardForm.acquiringBank?.value || null,
      chargingOffUs: Number(cardForm.chargingOffUs),
      chargingOffUsType: cardForm.chargingOffUsType.value.toUpperCase(),
      chargingOnUs: Number(cardForm.chargingOnUs),
      chargingOnUsType: cardForm.chargingOnUsType.value.toUpperCase(),
      mdrOffUs: Number(cardForm.mdrOffUs),
      mdrOffUsType: cardForm.mdrOffUsType.value.toUpperCase(),
      mdrOnUs: Number(cardForm.mdrOnUs),
      mdrOnUsType: cardForm.mdrOnUsType.value.toUpperCase(),
      serviceFee: Number(cardForm.serviceFee),
      serviceFeeType: cardForm.serviceFeeType.value.toUpperCase(),
    };

    const cardOnline: IChargingSetup = {
      id: cardOnlineForm.id || null,
      transactionType: TRANSACTION_TYPES.CARD_ONLINE,
      acquirerId: cardOnlineForm.acquiringBank?.value || null,
      chargingOffUs: Number(cardOnlineForm.chargingOffUs),
      chargingOffUsType: cardOnlineForm.chargingOffUsType.value.toUpperCase(),
      chargingOnUs: Number(cardOnlineForm.chargingOnUs),
      chargingOnUsType: cardOnlineForm.chargingOnUsType.value.toUpperCase(),
      mdrOffUs: Number(cardOnlineForm.mdrOffUs),
      mdrOffUsType: cardOnlineForm.mdrOffUsType.value.toUpperCase(),
      mdrOnUs: Number(cardOnlineForm.mdrOnUs),
      mdrOnUsType: cardOnlineForm.mdrOnUsType.value.toUpperCase(),
      serviceFee: Number(cardOnlineForm.serviceFee),
      serviceFeeType: cardOnlineForm.serviceFeeType.value.toUpperCase(),
    };

    const va: IChargingSetup = {
      id: vaForm.id || null,
      transactionType: TRANSACTION_TYPES.VIRTUAL_ACCOUNT,
      acquirerId: vaForm.acquiringBank?.value || null,
      charging: Number(vaForm.charging),
      chargingType: vaForm.chargingType.value.toUpperCase(),
      mdr: Number(vaForm.mdr),
      mdrType: vaForm.mdrType.value.toUpperCase(),
      serviceFee: Number(vaForm.serviceFee),
      serviceFeeType: vaForm.serviceFeeType.value.toUpperCase(),
    };

    const ewallet: IChargingSetup = {
      id: ewalletForm.id || null,
      transactionType: TRANSACTION_TYPES.EWALLET,
      acquirerId: ewalletForm.acquiringBank?.value || null,
      charging: Number(ewalletForm.charging),
      chargingType: ewalletForm.chargingType.value.toUpperCase(),
      mdr: Number(ewalletForm.mdr),
      mdrType: ewalletForm.mdrType.value.toUpperCase(),
      serviceFee: Number(ewalletForm.serviceFee),
      serviceFeeType: ewalletForm.serviceFeeType.value.toUpperCase(),
    };

    const giftCard: IChargingSetup = {
      id: giftCardForm.id || null,
      transactionType: TRANSACTION_TYPES.GIFTCARD,
      acquirerId: giftCardForm.acquiringBank?.value || null,
      charging: Number(giftCardForm.charging),
      chargingType: giftCardForm.chargingType.value.toUpperCase(),
      mdr: Number(giftCardForm.mdr),
      mdrType: giftCardForm.mdrType.value.toUpperCase(),
      serviceFee: Number(giftCardForm.serviceFee),
      serviceFeeType: giftCardForm.serviceFeeType.value.toUpperCase(),
    };

    if (
      qris.acquirerId ||
      qris.mdrOnUs ||
      qris.mdrOffUs ||
      qris.chargingOnUs ||
      qris.chargingOffUs ||
      qris.serviceFee
    ) {
      result.push(qris);
    }
    if (
      card.acquirerId ||
      card.mdrOnUs ||
      card.mdrOffUs ||
      card.chargingOnUs ||
      card.chargingOffUs ||
      card.serviceFee
    ) {
      result.push(card);
    }

    if (
      cardOnline.acquirerId ||
      cardOnline.mdrOnUs ||
      cardOnline.mdrOffUs ||
      cardOnline.chargingOnUs ||
      cardOnline.chargingOffUs ||
      cardOnline.serviceFee
    ) {
      result.push(cardOnline);
    }

    if (va.acquirerId || va.mdr || va.charging || va.serviceFee) {
      result.push(va);
    }

    if (
      ewallet.acquirerId ||
      ewallet.mdr ||
      ewallet.charging ||
      ewallet.serviceFee
    ) {
      result.push(ewallet);
    }

    if (
      giftCard.acquirerId ||
      giftCard.mdr ||
      giftCard.charging ||
      giftCard.serviceFee
    ) {
      result.push(giftCard);
    }

    return result;
  }

  function createChargingForm(
    name: string,
    label: string,
    form: FormChargingSetup,
    error: ErrorChargingSetup,
  ): MapForm {
    return {
      name,
      label,
      form,
      error,
    };
  }

  function mapChargingSetupForm(): Array<MapForm> {
    const qris = createChargingForm(
      'qris',
      TRANSACTION_TYPE_LABELS.QRIS,
      qrisForm,
      qrisError,
    );

    const card = createChargingForm(
      'card',
      TRANSACTION_TYPE_LABELS.CARD,
      cardForm,
      cardError,
    );

    const cardOnline = createChargingForm(
      'cardOnline',
      TRANSACTION_TYPE_LABELS.CARD_ONLINE,
      cardOnlineForm,
      cardOnlineError,
    );

    const va = createChargingForm(
      'va',
      TRANSACTION_TYPE_LABELS.VIRTUAL_ACCOUNT,
      vaForm,
      vaError,
    );

    const ewallet = createChargingForm(
      'ewallet',
      TRANSACTION_TYPE_LABELS.EWALLET,
      ewalletForm,
      ewalletError,
    );

    const giftCard = createChargingForm(
      'giftCard',
      TRANSACTION_TYPE_LABELS.GIFTCARD,
      giftCardForm,
      giftCardError,
    );

    return [qris, card, cardOnline, va, ewallet, giftCard];
  }

  async function submitForm(): Promise<void> {
    try {
      setLoadingForm(true);
      if (isEdit) {
        await updateMerchant({
          id: form.id,
          name: form.name,
          merchantTypeId: form.merchantType?.value || '',
          mid: form.mid,
          address: form.address,
          bankAccount: form.bankAccount,
          bankId: form.bank?.value || '',
          bankName: form.bank?.label || '',
          ownerName: form.ownerName,
          chargingSetups: mapChargingSetup(),
        });
      } else {
        await createMerchant({
          name: form.name,
          merchantTypeId: form.merchantType?.value || '',
          mid: form.mid,
          address: form.address,
          bankAccount: form.bankAccount,
          bankId: form.bank?.value || '',
          bankName: form.bank?.label || '',
          ownerName: form.ownerName,
          chargingSetups: mapChargingSetup(),
        });
      }
      setLoadingForm(false);
      onCloseForm();

      popupMessage({
        show: true,
        type: 'success',
        title: isEdit ? 'Berhasil Diubah' : 'Berhasil Dibuat',
        message: isEdit
          ? 'Hi, Data User berhasil diubah'
          : 'Hi, User baru berhasil dibuat',
      });

      getMerchants({
        page: Number(page),
        limit: Number(limit),
      });
    } catch (err) {
      setLoadingForm(false);
      setFormComponent(true);
      errorBag(err);
    }
  }

  function onSubmit(e: React.SyntheticEvent): void {
    e?.preventDefault();

    const [valid, newError] = validateForm();
    if (!valid) {
      setError(newError);
      return;
    }
    setFormComponent(false);
    const title = `${isEdit ? 'Edit' : 'Tambah'}  merchant?`;
    // eslint-disable-next-line prettier/prettier
    const message = `Apakah yakin ingin ${
      isEdit ? 'mengubah data' : 'menambah'
    } merchant? harap pastikan formulir terisi dengan benar!`;
    confirmation({
      title,
      message,
      onConfirmed: () => submitForm(),
      onCancel: () => setFormComponent(true),
    });
  }

  function onSubmitFormUpload(e: React.SyntheticEvent): void {
    e?.preventDefault();
    const [valid, newError] = validateFormUpload();
    if (!valid) {
      setError(newError);
      return;
    }

    if (form.merchantFile) {
      setLoadingForm(true);
      bulkCreateMerchant(form.merchantFile)
        .then((data) => {
          setLoadingForm(false);
          popupMessage({
            show: true,
            type: 'success',
            title: 'Berhasil upload merchant',
            message: (
              <ContentMessage
                errors={data.errorMessages}
                failed={data.failedData}
                success={data.successfulData}
                uploaded={data.uploadedData}
              />
            ),
          });

          getMerchants({
            page: Number(page),
            limit: Number(limit),
          });
        })
        .catch((err) => {
          setLoadingForm(false);
          errorBag(err);
        });
    }
  }

  function onSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target;
    const urlParams = new URLSearchParams();

    if (value) {
      urlParams.set('search', value);
    } else {
      urlParams.delete('search');
    }
    urlParams.set('page', String(0));
    if (limit) {
      urlParams.set('limit', String(defaultLimit));
    }
    setSearchParam(urlParams);
  }

  function onSubmitSearch(e: React.SyntheticEvent): void {
    e?.preventDefault();
  }

  useEffect(
    function () {
      if (profile && isLogin) {
        getMerchants({
          search: {
            keyword: search,
          },
          page: Number(page),
          limit: Number(limit),
        }).catch(function (err) {
          errorBag(err);
        });
      }
    },
    [profile, isLogin, debouncedSearchQuery, page, limit],
  );

  return (
    <>
      <PageInfo title={PERMISSION_LABELS.CHARGING_SETUP} />
      <MainContent
        title={PERMISSION_LABELS.CHARGING_SETUP}
        headerRightElement={
          <Form onSubmit={onSubmitSearch} style={{ width: 700 }}>
            <Row className="g-0">
              <Col sm={5} className="py-0 ps-0  pe-3">
                <Form.Control
                  onChange={onSearch}
                  placeholder={'Search...'}
                  value={search}
                />
              </Col>
              <Col sm={4} className="py-0 ps-0  pe-3">
                <Button onClick={onBulkAdd} className="d-block w-100">
                  Tambah Banyak Merchant
                </Button>
              </Col>
              <Col sm={3}>
                <Button onClick={onAdd} className="d-block w-100">
                  Tambah Merchant
                </Button>
              </Col>
            </Row>
          </Form>
        }
      >
        <Table
          limit={Number(list.limit) || Number(limit)}
          page={Number(list.page) || Number(page)}
          totalPage={Number(list.total)}
          onChangePage={onChangePage}
          data={items}
          loading={list.loading}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </MainContent>
      <FormComponent
        edit={isEdit}
        show={formComponent}
        form={form}
        error={error}
        chargingForms={mapChargingSetupForm()}
        disabled={loadingForm}
        onSubmit={onSubmit}
        onChange={onChangeFormInput}
        onSelectChange={onChangeSelectInput}
        onChangeOptionButton={onChangeOptionButton}
        onClose={onCloseForm}
      />
      <FormUpload
        onSubmit={onSubmitFormUpload}
        onClose={onCloseFormUpload}
        show={formUpload}
        error={error}
        disabled={loadingForm}
        onChangeFile={onChangeFile}
      />
    </>
  );
}

export default ChargingSetup;

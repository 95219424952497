import { imgChecklist, imgDanger, imgWaiting } from 'assets';
import { Modal, Button } from 'react-bootstrap';
import { popupMessage } from 'controls';
import { IconWrapper, Icon, Message, Title, ContentWrapper } from './styles';

type PopupType = 'success' | 'danger' | 'warning' | 'waiting';
type TextType = string | React.ReactElement | React.ReactNode | JSX.Element;

interface Props {
  show?: boolean;
  type?: PopupType;
  title?: TextType;
  message?: TextType;
  onClose?: () => void;
}

function PopupMessage({
  show,
  type,
  title,
  message,
  onClose,
}: Props): JSX.Element | null {
  function onClosePopup(): void {
    popupMessage({ show: false });
    onClose?.();
  }

  function renderIcon(): JSX.Element | null {
    switch (type) {
      case 'success':
        return <Icon src={imgChecklist} />;
      case 'warning':
        return <Icon src={imgDanger} />;
      case 'waiting':
        return <Icon src={imgWaiting} />;
      default:
        return null;
    }
  }

  function renderMessage(): JSX.Element | null {
    if (message) {
      return <Message>{message}</Message>;
    }

    return null;
  }

  function renderTitle(): JSX.Element | null {
    if (title) {
      return <Title className={`text-${type}`}>{title}</Title>;
    }
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onClosePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <ContentWrapper>
          <IconWrapper>{renderIcon()}</IconWrapper>
          {renderTitle()}
          {renderMessage()}
          <Button
            variant="dark"
            onClick={onClosePopup}
            className="d-block w-100"
          >
            OK
          </Button>
        </ContentWrapper>
      </Modal.Body>
    </Modal>
  );
}

export default PopupMessage;

import { Wrapper } from './styles';
import { profileSelector, floatingUserSelector } from 'modules';
import { floatingUser as floatingUserAction } from 'controls';
import { useSelector, shallowEqual } from 'react-redux';
import DropdownUser from './DropdownUser';
import FloatingUser from './FloatingUser';

function Navbar(): JSX.Element {
  const profile = useSelector(profileSelector, shallowEqual);
  const floatingUser = useSelector(floatingUserSelector, shallowEqual);

  return (
    <>
      <Wrapper>
        {profile && (
          <DropdownUser
            onClick={(): void => floatingUserAction(true)}
            name={profile.name}
            role={profile.role.displayName}
          />
        )}
      </Wrapper>
      <FloatingUser {...floatingUser} />
    </>
  );
}

export default Navbar;

import { gql } from '@apollo/client';

export const LIST_PERMISSION = gql`
  query getPermissions($input: ListPermissionInput!) {
    listPermission(listPermissionInput: $input) {
      page
      limit
      total
      totalPage
      permissions {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const DETAIL_PERMISSION = gql`
  query getDetailPermission($id: String!) {
    detailPermission(id: $id) {
      id
      name
      status
    }
  }
`;

export const LIST_PREFIX_PERMISSION = gql`
  query {
    listPrefixPermission
  }
`;

import { gql } from '@apollo/client';

export const CLEANUP_MANDIRI = gql`
  mutation cleanUpMandiriV2(
    $merchantTypeId: String!
    $ytiMcdSettlementFile: String!
    $settlementFile: String!
  ) {
    cleanUpMandiriV2(
      merchantTypeId: $merchantTypeId
      ytiMcdSettlement: $ytiMcdSettlementFile
      mandiriSettlement: $settlementFile
    ) {
      ytiMcdSettlementCloudStorageUrl
      mandiriCloudStorageUrl
    }
  }
`;

export const CLEANUP_KREDIVO = gql`
  mutation cleanUpKredivoV2(
    $merchantTypeId: String!
    $ytiMcdSettlementFile: String!
    $settlementFile: String!
  ) {
    cleanUpKredivoV2(
      merchantTypeId: $merchantTypeId
      ytiMcdSettlement: $ytiMcdSettlementFile
      kredivoSettlement: $settlementFile
    ) {
      ytiMcdSettlementCloudStorageUrl
      kredivoCloudStorageUrl
    }
  }
`;

export const CLEANUP_SHOPEE = gql`
  mutation cleanUpShopeeV2(
    $merchantTypeId: String!
    $ytiMcdSettlementFile: String!
    $settlementFile: String!
  ) {
    cleanUpShopeeV2(
      merchantTypeId: $merchantTypeId
      ytiMcdSettlement: $ytiMcdSettlementFile
      shopeeSettlement: $settlementFile
    ) {
      ytiMcdSettlementCloudStorageUrl
      shopeeCloudStorageUrl
    }
  }
`;

export const CLEANUP_LINKAJA = gql`
  mutation cleanUpLinkAjaV2(
    $merchantTypeId: String!
    $ytiMcdSettlementFile: String!
    $ytiSettlementFile: String!
    $linkAjaMcdReportFile: String!
  ) {
    cleanUpLinkAjaV2(
      merchantTypeId: $merchantTypeId
      ytiMcdSettlement: $ytiMcdSettlementFile
      ytiSettlement: $ytiSettlementFile
      linkAjaMcdReport: $linkAjaMcdReportFile
    ) {
      linkAjaMcdReportCloudStorageUrl
      ytiSettlementCloudStorageUrl
      ytiMcdSettlementCloudStorageUrl
    }
  }
`;

export const CLEANUP_EXPANSION = gql`
  mutation cleanUpExpansion($ytiSettlement: String, $merchantTypeId: String!) {
    cleanUpExpansion(
      ytiSettlement: $ytiSettlement
      merchantTypeId: $merchantTypeId
    ) {
      ytiSettlementCloudStorageUrl
    }
  }
`;

export const CLEANUP_OVO = gql`
  mutation cleanUpOvo($cleanupOvoInput: CleanUpOvoInput) {
    cleanUpOvo(cleanupOvoInput: $cleanupOvoInput) {
      ytiMcdSettlementUrl
      ovoSettlementUrl
    }
  }
`;

export const CLEANUP_UANGME = gql`
  mutation cleanUpUangMe($cleanupUangMeInput: CleanUpUangMeInput) {
    cleanUpUangMe(cleanupUangMeInput: $cleanupUangMeInput) {
      ytiMcdSettlementUrl
      ytiSettlementUrl
    }
  }
`;

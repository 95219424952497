import {
  apoloClient,
  store,
  reportConsolidateGql,
  setReportConsolidate,
} from 'modules';
import {
  IGetReportConsolidateRequest,
  IGetReportConsolidateResponse,
  IListReportConsolidateQuery,
  IListReportConsolidateQueryVariables,
  IUpdateReRunConsolidateMutate,
  IReRunConsolidateVariables,
} from './interfaces';
import { IReportConsolidate } from 'types';
import { MESSAGES } from 'utils';

export async function getReportConsolidate(
  input: IGetReportConsolidateRequest,
  storeToRedux = true,
): Promise<IGetReportConsolidateResponse> {
  try {
    if (storeToRedux) {
      store.dispatch(setReportConsolidate({ loading: true }));
    }
    const response = await apoloClient.query<
      IListReportConsolidateQuery,
      IListReportConsolidateQueryVariables
    >({
      query: reportConsolidateGql.LIST_CONSOLIDATE_REPORT,
      variables: {
        input,
      },
    });

    const {
      page,
      limit,
      total,
      totalPage,
      recons: data,
    } = response.data.listRecon;

    if (storeToRedux) {
      store.dispatch(
        setReportConsolidate({
          page,
          limit,
          total,
          totalPage,
          data,
          loading: false,
          error: false,
        }),
      );
    }

    return { page, limit, total, totalPage, data };
  } catch (err) {
    if (storeToRedux) {
      store.dispatch(setReportConsolidate({ loading: false, error: true }));
    }
    throw err;
  }
}

export async function updateReRunConsolidate(
  id: string,
): Promise<IReportConsolidate> {
  const response = await apoloClient.mutate<
    IUpdateReRunConsolidateMutate,
    IReRunConsolidateVariables
  >({
    mutation: reportConsolidateGql.RE_RUN,
    variables: {
      id,
    },
  });

  const result = response.data?.reRunRecon;

  if (!result) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return result;
}

import { memo } from 'react';
import { TablePagination } from 'components';
import { Table, Button } from 'react-bootstrap';
import { IRole } from 'types';
import { mapUserRolePermissions } from 'utils';

interface Props {
  loading?: boolean;
  onEdit: (data: IRole) => void;
  onDelete: (data: IRole) => void;
  data: Array<IRole>;
  page: number;
  totalPage: number;
  limit: number;
  onChangePage: React.MouseEventHandler;
}

function TableRole({
  data,
  onEdit,
  onDelete,
  loading,
  page,
  limit,
  totalPage,
  onChangePage,
}: Props): JSX.Element {
  function getNumber(index: number): number {
    return page * limit + index + 1;
  }

  function renderMenu(permissions: Array<string>): JSX.Element {
    return (
      <>
        {mapUserRolePermissions(permissions)
          .map((perm) => perm.label)
          .join(', ')}
      </>
    );
  }

  function renderTableBody(): JSX.Element {
    if (loading) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            Loading...
          </td>
        </tr>
      );
    }

    if (data.length > 0) {
      return (
        <>
          {data.map(function (value, index) {
            return (
              <tr key={value.id}>
                <td>{getNumber(index)}</td>
                <td>{value.displayName}</td>
                <td>{renderMenu(value.permissions)}</td>
                <td>{value.status}</td>
                <td style={{ textAlign: 'right' }}>
                  <Button
                    onClick={function (): void {
                      onEdit(value);
                    }}
                    size="sm"
                  >
                    Edit
                  </Button>{' '}
                  <Button
                    variant="danger"
                    onClick={function (): void {
                      onDelete(value);
                    }}
                    size="sm"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={6} className="text-center">
          Tidak ada data
        </td>
      </tr>
    );
  }

  return (
    <>
      <Table responsive hover bordered>
        <thead>
          <tr>
            <th>No</th>
            <th style={{ minWidth: 150, maxWidth: 150 }}>Role Name</th>
            <th>Menu</th>
            <th>Status</th>
            <th style={{ minWidth: 150 }}>Action</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      <TablePagination
        limit={limit}
        last={totalPage}
        current={page}
        onClick={onChangePage}
      />
    </>
  );
}

export default memo(TableRole);

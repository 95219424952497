import styled from 'styled-components';

export const MainWrapper = styled.div`
  position: relative;
  margin-top: 110px;
  margin-left: 240px;
  width: 100%;
  max-width: calc(100% - 240px);
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 24px;
`;

import { ModalDialog as NativeModalDialog } from 'react-bootstrap';
import styled from 'styled-components';
import { COLORS } from 'utils';

export const Wrapper = styled.div`
  position: relative;
`;

export const AvatarWrapper = styled.div`
  padding-bottom: 16px;
  text-align: center;
`;

export const Avatar = styled.img.attrs({
  alt: '',
})`
  width: 80px;
  height: 80px;
`;

export const ContentWrapper = styled.div`
  padding-bottom: 16px;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${COLORS.blue};
`;

export const Subtitle = styled.div`
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
`;

export const ModalDialog = styled(NativeModalDialog)`
  background-color: 'green';
  margin-right: 1.75rem;
`;

interface CloseIconProps {
  onClick?: () => void;
}

export const CloseIcon = styled.button<Pick<CloseIconProps, 'onClick'>>`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border: none;
  background-color: ${COLORS.dark};
  border-radius: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 3px;
    width: 15px;
    background-color: white;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 3px;
    width: 15px;
    background-color: white;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

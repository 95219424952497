import styled from 'styled-components';
import { Modal, Button, Form, Accordion } from 'react-bootstrap';
import { FormState, ErrorState, MapForm } from '../index';
import { FormInput, FormOptionButton } from 'components';
import { numberTypeOptions } from 'utils';
import {
  OnSelectChangeType,
  OnButtonOptionChangeType,
  IBank,
  IMerchantType,
} from 'types';
import { createOptions } from 'utils';
import { useSelector, shallowEqual } from 'react-redux';
import {
  listMerchantTypeSelector,
  listBankSelector,
  listAcquirerSelector,
} from 'modules';

type Props = {
  edit: boolean;
  show: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  form: FormState;
  error: ErrorState;
  disabled?: boolean;
  chargingForms: Array<MapForm>;
  onSubmit: (e: React.SyntheticEvent) => void;
  onClose: () => void;
  onSelectChange: OnSelectChangeType;
  onChangeOptionButton: OnButtonOptionChangeType;
};

const BtnWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`;

function FormComponent({
  show,
  edit,
  form,
  error,
  disabled,
  chargingForms,
  onClose,
  onChange,
  onSubmit,
  onSelectChange,
  onChangeOptionButton,
}: Props): JSX.Element {
  const cForm1 = ['qris', 'card', 'cardOnline'];
  const cForm2 = ['va', 'ewallet', 'giftCard'];

  const { data: merchantTypes } = useSelector(
    listMerchantTypeSelector,
    shallowEqual,
  );
  const { data: banks } = useSelector(listBankSelector, shallowEqual);
  const { data: acquirers } = useSelector(listAcquirerSelector, shallowEqual);

  const merchantTypeOptions = createOptions<IMerchantType>(
    merchantTypes || [],
    'name',
    'id',
    true,
  );
  const bankOptions = createOptions<IBank>(banks || [], 'name', 'id', true);
  const acquirerOptions = createOptions(acquirers || [], 'name', 'id', true);

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {edit ? 'Edit Charging Merchant' : 'Tambah Charging Merchant'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormInput
            type="text"
            name="mid"
            label="MID"
            placeholder="Masukan MID"
            value={form.mid}
            onChange={onChange}
            error={Boolean(error.mid)}
            errorMessage={error.mid}
            horizontal
            disabled={disabled}
            max={20}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="text"
            name="name"
            label="Nama Merchant"
            placeholder="Masukan Nama Merchant"
            value={form.name}
            onChange={onChange}
            error={Boolean(error.name)}
            errorMessage={error.name}
            horizontal
            disabled={edit || disabled}
            max={50}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="select"
            name="merchantType"
            label="Merchant Type"
            placeholder="Pilih Merchant Type"
            value={form.merchantType?.value}
            onSelectChange={onSelectChange}
            error={Boolean(error.merchantType)}
            errorMessage={error.merchantType}
            options={merchantTypeOptions}
            horizontal
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="text"
            name="address"
            label="Address"
            placeholder="Masukan alamat"
            value={form.address}
            onChange={onChange}
            error={Boolean(error.address)}
            errorMessage={error.address}
            horizontal
            disabled={disabled}
            max={20}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="text"
            name="ownerName"
            label="Owner Name"
            placeholder="Masukan nama owner"
            value={form.ownerName}
            onChange={onChange}
            error={Boolean(error.ownerName)}
            errorMessage={error.ownerName}
            horizontal
            disabled={disabled}
            max={50}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="select"
            name="bank"
            label="Bank Name"
            placeholder="Pilih Bank"
            value={form.bank?.value}
            onSelectChange={onSelectChange}
            // loadOptions={loadBankOptions}
            error={Boolean(error.bank)}
            errorMessage={error.bank}
            options={bankOptions}
            horizontal
            // async
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="text"
            name="bankAccount"
            label="Bank Account"
            placeholder="Masukan Akun Bank"
            value={form.bankAccount}
            onChange={onChange}
            error={Boolean(error.bankAccount)}
            errorMessage={error.bankAccount}
            horizontal
            disabled={disabled}
            max={20}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <Accordion>
            {chargingForms.map((chargingForm, index) => {
              if (cForm1.includes(chargingForm.name)) {
                return (
                  <Accordion.Item eventKey={`${index}`}>
                    <Accordion.Header>{chargingForm.label}</Accordion.Header>
                    <Accordion.Body>
                      <FormInput
                        type="select"
                        name={`${chargingForm.name}.acquiringBank`}
                        label="Acquiring Bank"
                        placeholder="Pilih Bank"
                        value={chargingForm.form.acquiringBank?.value}
                        onSelectChange={onSelectChange}
                        error={Boolean(chargingForm.error.acquiringBank)}
                        errorMessage={chargingForm.error.acquiringBank}
                        options={acquirerOptions}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 8,
                        }}
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.mdrOnUs`}
                        label="MDR on us"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form?.mdrOnUs}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.mdrOnUs)}
                        errorMessage={chargingForm.error.mdrOnUs}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.mdrOnUsType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.mdrOnUsType}
                          />
                        }
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.mdrOffUs`}
                        label="MDR off us"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.mdrOffUs}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.mdrOffUs)}
                        errorMessage={chargingForm.error.mdrOffUs}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.mdrOffUsType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.mdrOffUsType}
                          />
                        }
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.chargingOnUs`}
                        label="Charging on us"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.chargingOnUs}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.chargingOnUs)}
                        errorMessage={chargingForm.error.chargingOnUs}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.chargingOnUsType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.chargingOnUsType}
                          />
                        }
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.chargingOffUs`}
                        label="Charging off us"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.chargingOffUs}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.chargingOffUs)}
                        errorMessage={chargingForm.error.chargingOffUs}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.chargingOffUsType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.chargingOffUsType}
                          />
                        }
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.serviceFee`}
                        label="Service Fee"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.serviceFee}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.serviceFee)}
                        errorMessage={chargingForm.error.serviceFee}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.serviceFeeType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.serviceFeeType}
                          />
                        }
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }

              if (cForm2.includes(chargingForm.name)) {
                return (
                  <Accordion.Item eventKey={`${index}`}>
                    <Accordion.Header>{chargingForm.label}</Accordion.Header>
                    <Accordion.Body>
                      <FormInput
                        type="select"
                        name={`${chargingForm.name}.acquiringBank`}
                        label="Acquiring Bank"
                        placeholder="Pilih Bank"
                        value={chargingForm.form.acquiringBank?.value}
                        onSelectChange={onSelectChange}
                        error={Boolean(chargingForm.error.acquiringBank)}
                        errorMessage={chargingForm.error.acquiringBank}
                        options={acquirerOptions}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 8,
                        }}
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.mdr`}
                        label="MDR"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.mdr}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.mdr)}
                        errorMessage={chargingForm.error.mdr}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.mdrType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.mdrType}
                          />
                        }
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.charging`}
                        label="Charging"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.charging}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.charging)}
                        errorMessage={chargingForm.error.charging}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.chargingType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.chargingType}
                          />
                        }
                      />
                      <FormInput
                        type="number"
                        min={0}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        step="0.01"
                        name={`${chargingForm.name}.serviceFee`}
                        label="Service Fee"
                        placeholder="Masukan Nilai"
                        value={chargingForm.form.serviceFee}
                        onChange={onChange}
                        error={Boolean(chargingForm.error.serviceFee)}
                        errorMessage={chargingForm.error.serviceFee}
                        horizontal
                        disabled={disabled}
                        horizontalSize={{
                          label: 4,
                          input: 4,
                          extendInput: 4,
                        }}
                        extendInput={
                          <FormOptionButton
                            name={`${chargingForm.name}.serviceFeeType`}
                            onChange={onChangeOptionButton}
                            options={numberTypeOptions}
                            value={chargingForm.form.serviceFeeType}
                          />
                        }
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
            })}
          </Accordion>
          <BtnWrapper>
            <Button disabled={disabled} type="submit">
              <strong>{edit ? 'Ubah Pengaturan' : 'Tambah Merchant'}</strong>
            </Button>
          </BtnWrapper>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FormComponent;

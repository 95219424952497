import { gql } from '@apollo/client';

export const LIST_ISSUER = gql`
  query listIssuer($input: ListIssuerInput!) {
    listIssuer(listIssuerInput: $input) {
      page
      limit
      total
      totalPage
      issuers {
        id
        name
        status
      }
    }
  }
`;

export const DETAIL_ISSUER = gql`
  query detailIssuer($id: String!) {
    detailIssuer(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_ISSUER = gql`
  mutation createIssuer($form: CreateIssuerInput!) {
    createIssuer(createIssuerInput: $form) {
      id
      name
      status
    }
  }
`;

export const UPDATE_ISSUER = gql`
  mutation updateIssuer($form: UpdateIssuerInput!) {
    updateIssuer(updateIssuerInput: $form) {
      id
      name
      status
    }
  }
`;

export const DELETE_ISSUER = gql`
  mutation deleteIssuer($id: String!) {
    deleteIssuer(id: $id) {
      id
      name
      status
    }
  }
`;

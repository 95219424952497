import { memo } from 'react';
import { Card } from 'react-bootstrap';
import { Header, HeaderContent, Title, Body, Footer } from './styles';

type MainContentProps = {
  title: string;
  header?: boolean;
  footer?: boolean;
  children?: React.ReactElement | React.ReactNode | null;
  footerElement?: React.ReactElement | React.ReactNode | JSX.Element | null;
  headerRightElement?:
    | React.ReactElement
    | React.ReactNode
    | JSX.Element
    | null;
};

function MainContent({
  title,
  header = true,
  footer,
  children,
  footerElement,
  headerRightElement,
}: MainContentProps): JSX.Element {
  function renderHeaderLeft(): JSX.Element | null {
    if (!title) return null;

    return (
      <HeaderContent className="w-30-percent">
        <Title>{title}</Title>
      </HeaderContent>
    );
  }

  function renderHeaderRight(): JSX.Element | null {
    if (!headerRightElement) return null;

    return (
      <HeaderContent className="w-70-percent">
        {headerRightElement}
      </HeaderContent>
    );
  }

  function renderHeader(): JSX.Element | null {
    if (!header) {
      return null;
    }

    return (
      <Header>
        {renderHeaderLeft()}
        {renderHeaderRight()}
      </Header>
    );
  }

  function renderBody(): JSX.Element | null {
    return <Body>{children}</Body>;
  }

  function renderFooter(): JSX.Element | null {
    if (!footer) return null;

    return <Footer>{footerElement}</Footer>;
  }

  return (
    <Card className="shadow mb-5">
      <Card.Body>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </Card.Body>
    </Card>
  );
}

export default memo(MainContent);

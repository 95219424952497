import { createSlice } from '@reduxjs/toolkit';
import { IUserState } from './types';
import * as reducers from './reducers';

export const userState: IUserState = {
  listUser: {
    page: 0,
    limit: 10,
    total: 0,
    totalPage: 1,
    error: false,
    loading: false,
    data: [],
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers,
});

export const { setUsers, clearUsers } = userSlice.actions;

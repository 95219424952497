import { lazy } from 'react';

const User = lazy(() => import('../pages/Dashboard/User'));
const Role = lazy(() => import('../pages/Dashboard/Role'));
const ChargingSetup = lazy(() => import('../pages/Dashboard/ChargingSetup'));
const MerchantType = lazy(() => import('../pages/Dashboard/MerchantType'));
const ConsolidateData = lazy(
  () => import('../pages/Dashboard/ConsolidateData'),
);
const ConsolidateReport = lazy(
  () => import('../pages/Dashboard/ConsolidateData/Report'),
);

const CleanUpData = lazy(() => import('../pages/Dashboard/CleanUpData'));

const FinalizeSettlement = lazy(
  () => import('../pages/Dashboard/FinalizeSettlement'),
);
const DashboardHome = lazy(() => import('../pages/Dashboard/Home'));
const NotFound404 = lazy(() => import('../pages/NotFound404'));
const GenerateQr = lazy(() => import('../pages/Dashboard/GenerateQr'));
const MergeData = lazy(() => import('../pages/Dashboard/MergeData'));

import { routePaths } from './path';

export const adminRoutes = [
  { path: routePaths.MANAGEMENT_USER, Element: User },
  { path: routePaths.MANAGEMENT_ROLE, Element: Role },
];

export const opsRoutes = [
  { path: routePaths.GENERATE_QR, Element: GenerateQr },
];

export const financeRoutes = [
  { path: routePaths.CLEANUP_DATA, Element: CleanUpData },
  { path: routePaths.CONSOLIDATE_DATA, Element: ConsolidateData },
  { path: routePaths.CHARGING_SETUP, Element: ChargingSetup },
  { path: routePaths.MERCHANT_TYPE, Element: MerchantType },
  { path: routePaths.FINALIZE_SETTLEMENT, Element: FinalizeSettlement },
  { path: routePaths.CONSOLIDATE_REPORT, Element: ConsolidateReport },
  { path: routePaths.MERGE_DATA, Element: MergeData },
];

export const dashboardMainRoutes = [
  { path: '/', Element: DashboardHome },
  { path: '*', Element: NotFound404 },
];

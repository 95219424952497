import styled from 'styled-components';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormState, ErrorState } from '../index';
import { FormInput } from 'components';
import { statusOptions } from 'utils';
import { OnSelectChangeType } from 'types';

type Props = {
  edit: boolean;
  show: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  form: FormState;
  error: ErrorState;
  disabled?: boolean;
  onSubmit: (e: React.SyntheticEvent) => void;
  onClose: () => void;
  onSelectChange: OnSelectChangeType;
};

const BtnWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`;

function FormComponent({
  show,
  edit,
  form,
  error,
  disabled,
  onClose,
  onChange,
  onSubmit,
  onSelectChange,
}: Props): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {edit ? 'Edit Recon Merchant Type' : 'Tambah Recon Merchant Type'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormInput
            type="text"
            name="name"
            label="Recon Merchant Type"
            placeholder="Masukan Nama Type"
            value={form.name}
            onChange={onChange}
            error={Boolean(error.name)}
            errorMessage={error.name}
            horizontal
            disabled={disabled}
            max={20}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="select"
            name="status"
            label="Status"
            placeholder="Pilih Status"
            value={form.status}
            onSelectChange={onSelectChange}
            error={Boolean(error.status)}
            errorMessage={error.status}
            options={statusOptions}
            horizontal
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <BtnWrapper>
            <Button disabled={disabled} type="submit">
              <strong>
                {edit ? 'Ubah Pengaturan' : 'Tambah Merchant Type'}
              </strong>
            </Button>
          </BtnWrapper>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FormComponent;

import { apoloClient, mergeGql } from 'modules';
import {
  IMergeResponse,
  IMergeMutateVariables,
  AsyncMergeExcelInputExcel,
  AsyncMergeExcelInputCsv,
  IMergeDataRequestExcel,
  IMergeDataRequestCsv,
  // IMergeDataResponse,
  IMergeDataResponseExcel,
  IMergeDataResponseCsv,
} from './interfaces';
import { MESSAGES } from 'utils';

export async function mergeLinkAja(
  laReport1?: File | Blob,
  laReport2?: File | Blob,
  laReport3?: File | Blob,
): Promise<IMergeResponse> {
  const response = await apoloClient.mutate<
    IMergeResponse,
    IMergeMutateVariables
  >({
    mutation: mergeGql.MERGE_LINKAJA,
    variables: {
      laReport1,
      laReport2,
      laReport3,
    },
  });

  if (!response.data?.mergeDataLinkAja?.mergedLinkAjaReport) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data;
}

export async function mergeExcel(
  input: IMergeDataRequestExcel,
): Promise<IMergeDataResponseExcel> {
  const response = await apoloClient.mutate<
    IMergeDataResponseExcel,
    AsyncMergeExcelInputExcel
  >({
    mutation: mergeGql.MERGE_DATA_EXCEL,
    variables: {
      mergeDataInput: {
        reportUrl1: input.reportUrl1,
        reportUrl2: input.reportUrl2,
        reportUrl3: input.reportUrl3,
        headerType: input.headerType,
      },
    },
  });

  if (!response.data?.mergeDataExcel?.mergedReport) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data;
}

export async function mergeCsv(
  input: IMergeDataRequestCsv,
): Promise<IMergeDataResponseCsv> {
  const response = await apoloClient.mutate<
    IMergeDataResponseCsv,
    AsyncMergeExcelInputCsv
  >({
    mutation: mergeGql.MERGE_DATA_CSV,
    variables: {
      mergeDataCsvInput: {
        reportUrl1: input.reportUrl1,
        reportUrl2: input.reportUrl2,
        reportUrl3: input.reportUrl3,
        separator: input.separator,
      },
    },
  });

  if (!response.data?.mergeDataCsv) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }
  return response.data;
}

import styled from 'styled-components';
import { imgDocSearch } from 'assets';

export const Wrapper = styled.div`
  text-align: center;
  padding-top: 36px;
  padding-bottom: 24px;
`;
export const ImageWrapper = styled.div`
  margin-bottom: 8px;
`;
export const Image = styled.img.attrs({
  alt: '',
  src: imgDocSearch,
})`
  width: 75px;
`;
export const Label = styled.span`
  display: block;
  font-size: 14px;
  margin-bottom: 16px;
`;
export const ButtonWrapper = styled.span`
  float: right;
`;
export const Message = styled.span``;

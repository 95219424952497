import { PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from 'types';
import { IAccountState } from './types';
import { accountState as initialState } from './states';

export function setProfile(
  state: IAccountState,
  action: PayloadAction<IProfile>,
): void {
  state.profile = {
    ...state.profile,
    ...action.payload,
  };
}

export function clearProfile(state: IAccountState): void {
  state.profile = initialState.profile;
}

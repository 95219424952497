import styled from 'styled-components';

export const Wrapper = styled.div``;
export const ErrorWrapper = styled.ol`
  margin: 0;
  padding: 0;
  padding-left: 16px;
`;
export const ErrorText = styled.li`
  margin-bottom: 8px;
  font-size: 12px;
  color: red;
  text-align: left;
`;
export const CountWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 8px;
`;
export const CountBox = styled.div`
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #ddd;
`;
export const CountTitle = styled.h5`
  font-size: 16px;
  font-weight: bold;
`;
export const CountValue = styled.span``;

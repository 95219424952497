import { Wrapper, Content } from './styles';
import SidebarMenu, { SideMenuProps } from './SidebarMenu';

type SidebarProps = {
  items: Array<SideMenuProps>;
};

function Sidebar({ items }: SidebarProps): JSX.Element {
  return (
    <Wrapper>
      <Content>
        {items.map((item) => {
          return (
            <SidebarMenu key={item.id} title={item.title} items={item.items} />
          );
        })}
      </Content>
    </Wrapper>
  );
}

export default Sidebar;

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormInput } from 'components';
import { useForm } from 'hooks';
import { validator } from 'utils';
import { changePassword } from 'services';
import { errorBag, popupMessage, formChangePassword } from 'controls';

type Props = {
  show?: boolean;
};

interface FormState {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

interface ErrorState {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
}

const initialFormState: FormState = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
};

const initialErrorState: ErrorState = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
};

const BtnWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`;

function FormChangePassword({ show }: Props): JSX.Element {
  const [form, setForm] = useForm(initialFormState);
  const [error, setError] = useState(initialErrorState);
  const [loading, setLoading] = useState<boolean>(false);

  function onClose(): void {
    formChangePassword(false);
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setForm(name, value);
    const field = name as keyof typeof error;
    // eslint-disable-next-line security/detect-object-injection
    if (error[field] !== '') {
      setError((prev) => ({ ...prev, [name]: '' }));
    }
  }

  function validateForm(): [boolean, ErrorState] {
    const newError = { ...error };

    if (!form.oldPassword) {
      newError.oldPassword = 'Masukan password lama';
    }

    if (!form.password) {
      newError.password = 'Masukan password';
    } else if (!validator.min(form.password, 8)) {
      newError.password = 'Password minimal 8 karakter';
    }

    if (!form.passwordConfirmation) {
      newError.passwordConfirmation = 'Masukan konfirmasi password';
    } else if (form.passwordConfirmation !== form.password) {
      newError.passwordConfirmation = 'Kombinasi password tidak sama';
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  async function onSubmit(e: React.SyntheticEvent): Promise<void> {
    e?.preventDefault();

    const [valid, newError] = validateForm();
    if (!valid) {
      setError(newError);
      return;
    }

    try {
      setLoading(true);
      await changePassword(form);
      setLoading(false);
      popupMessage({
        show: true,
        type: 'success',
        title: 'Berhasil Diganti',
        message: 'Hi, Password berhasil di ganti yah..',
      });
      setForm('reset');
      formChangePassword(false);
    } catch (err) {
      setLoading(false);
      errorBag(err);
    }
  }

  useEffect(
    function () {
      if (!show) {
        setForm('reset');
        setError(initialErrorState);
      }
    },
    [show],
  );

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormInput
            type="password"
            name="oldPassword"
            label="Password Lama"
            placeholder="Masukan password lama"
            value={form.oldPassword}
            onChange={onChange}
            error={Boolean(error.oldPassword)}
            errorMessage={error.oldPassword}
            horizontal
            disabled={loading}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="password"
            name="password"
            label="New Password"
            placeholder="Masukan password baru"
            value={form.password}
            onChange={onChange}
            error={Boolean(error.password)}
            errorMessage={error.password}
            horizontal
            disabled={loading}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="password"
            name="passwordConfirmation"
            label="Re-type new password"
            placeholder="Ketik ulang password baru"
            value={form.passwordConfirmation}
            onChange={onChange}
            error={Boolean(error.passwordConfirmation)}
            errorMessage={error.passwordConfirmation}
            horizontal
            disabled={loading}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <BtnWrapper>
            <Button disabled={loading} type="submit">
              <strong>Ganti Password</strong>
            </Button>
          </BtnWrapper>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FormChangePassword;

import { PayloadAction } from '@reduxjs/toolkit';
import { IListUserState, IUserState } from './types';
import { userState as initialState } from './states';

export function setUsers(
  state: IUserState,
  action: PayloadAction<IListUserState>,
): void {
  state.listUser = {
    ...state.listUser,
    ...action.payload,
  };
}

export function clearUsers(state: IUserState): void {
  state.listUser = initialState.listUser;
}

import { gql } from '@apollo/client';

export const CREATE_RECON_MANDIRI = gql`
  mutation uploadReconFile(
    $mandiriSettlement: Upload
    $ytiMcdSettlement: Upload
  ) {
    uploadReconFile(
      mandiriSettlement: $mandiriSettlement
      ytiMcdSettlement: $ytiMcdSettlement
    ) {
      mandiriSettlement
      ytiMcdSettlement
    }
  }
`;

export const CREATE_RECON_KREDIVO = gql`
  mutation uploadReconFile(
    $kredivoSettlement: Upload
    $ytiMcdSettlement: Upload
  ) {
    uploadReconFile(
      kredivoSettlement: $kredivoSettlement
      ytiMcdSettlement: $ytiMcdSettlement
    ) {
      kredivoSettlement
      ytiMcdSettlement
    }
  }
`;

export const CREATE_RECON_SHOPEE = gql`
  mutation uploadReconFile(
    $shopeeSettlement: Upload
    $ytiMcdSettlement: Upload
  ) {
    uploadReconFile(
      shopeeSettlement: $shopeeSettlement
      ytiMcdSettlement: $ytiMcdSettlement
    ) {
      shopeeSettlement
      ytiMcdSettlement
    }
  }
`;

export const CREATE_RECON_LINKAJA = gql`
  mutation uploadReconFile(
    $ytiSettlement: Upload!
    $ytiMcdSettlement: Upload!
    $linkAjaMCDReport: Upload!
  ) {
    uploadReconFile(
      ytiSettlement: $ytiSettlement
      ytiMcdSettlement: $ytiMcdSettlement
      linkAjaMCDReport: $linkAjaMCDReport
    ) {
      ytiSettlement
      ytiMcdSettlement
      linkAjaMCDReport
    }
  }
`;

export const CREATE_RECON_LINKAJA_MSME = gql`
  mutation uploadReconFile(
    $balanceReport: Upload!
    $ytiSettlement: Upload!
    $linkAjaMCDReport: Upload!
    $phpDeliveryReport: Upload!
    $opsSettlement: Upload
  ) {
    uploadReconFile(
      balanceReport: $balanceReport
      ytiSettlement: $ytiSettlement
      linkAjaMCDReport: $linkAjaMCDReport
      phpDeliveryReport: $phpDeliveryReport
      opsSettlement: $opsSettlement
    ) {
      ytiSettlement
      balanceReport
      linkAjaMCDReport
      phpDeliveryReport
      opsSettlement
    }
  }
`;

export const ASYNC_RECON_LINKAJA = gql`
  mutation asyncReconLinkAja($asyncReconLinkAjaInput: AsyncReconLinkAjaInput!) {
    asyncReconLinkAja(asyncReconLinkAjaInput: $asyncReconLinkAjaInput) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_LINKAJA_MSME = gql`
  mutation asyncReconLinkAjaMSME(
    $asyncReconLinkAjaMSMEInput: AsyncReconLinkAjaMSMEInput!
  ) {
    asyncReconLinkAjaMSME(
      asyncReconLinkAjaMSMEInput: $asyncReconLinkAjaMSMEInput
    ) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_MANDIRI = gql`
  mutation asyncReconMandiri($asyncReconMandiriInput: AsyncReconMandiriInput!) {
    asyncReconMandiri(asyncReconMandiriInput: $asyncReconMandiriInput) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_SHOPEE = gql`
  mutation asyncReconShopee($asyncReconShopeeInput: AsyncReconShopeeInput!) {
    asyncReconShopee(asyncReconShopeeInput: $asyncReconShopeeInput) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_KREDIVO = gql`
  mutation asyncReconKredivo($asyncReconKredivoInput: AsyncReconKredivoInput!) {
    asyncReconKredivo(asyncReconKredivoInput: $asyncReconKredivoInput) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_OVO = gql`
  mutation asyncReconOvo($asyncReconOvoInput: AsyncReconOvoInput!) {
    asyncReconOvo(asyncReconOvoInput: $asyncReconOvoInput) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_UANGME = gql`
  mutation asyncReconUangMe($asyncReconUangMeInput: AsyncReconUangMeInput!) {
    asyncReconUangMe(asyncReconUangMeInput: $asyncReconUangMeInput) {
      id
      status
      accepted
    }
  }
`;
export const ASYNC_RECON_EXPANSION = gql`
  mutation asyncReconExpansion(
    $asyncReconExpansionInput: AsyncReconExpansionInput!
  ) {
    asyncReconExpansion(asyncReconExpansionInput: $asyncReconExpansionInput) {
      id
      status
      accepted
    }
  }
`;

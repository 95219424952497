import { memo } from 'react';
import { TablePagination } from 'components';
import { Table, Button } from 'react-bootstrap';
import { IMerchantType } from 'types';
import { getTableNumber } from 'utils';

interface Props {
  loading?: boolean;
  onEdit: (data: IMerchantType) => void;
  onDelete: (data: IMerchantType) => void;
  data: Array<IMerchantType>;
  onChangePage: React.MouseEventHandler;
  page: number;
  limit: number;
  totalPage: number;
}

function TableComponent({
  data,
  onEdit,
  onDelete,
  loading,
  onChangePage,
  page,
  limit,
  totalPage,
}: Props): JSX.Element {
  function renderTableBody(): JSX.Element {
    if (loading) {
      return (
        <tr>
          <td colSpan={4} className="text-center">
            Loading...
          </td>
        </tr>
      );
    }

    if (data.length > 0) {
      return (
        <>
          {data.map(function (item, index) {
            return (
              <tr key={item.id}>
                <td>{getTableNumber(index, page, limit)}</td>
                <td>{item.name}</td>
                <td>{item.status}</td>
                <td style={{ textAlign: 'right' }}>
                  <Button
                    onClick={function (): void {
                      onEdit(item);
                    }}
                    size="sm"
                  >
                    Edit
                  </Button>{' '}
                  <Button
                    variant="danger"
                    onClick={function (): void {
                      onDelete(item);
                    }}
                    size="sm"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={4} className="text-center">
          Tidak ada data
        </td>
      </tr>
    );
  }

  return (
    <>
      <Table responsive hover bordered>
        <thead>
          <tr>
            <th style={{ minWidth: 50 }}>No</th>
            <th>Recon Merchant Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      <TablePagination
        limit={limit}
        last={totalPage}
        current={page}
        onClick={onChangePage}
      />
    </>
  );
}

export default memo(TableComponent);

import {
  GlobalStyle,
  PopupMessage,
  LoadingScreen,
  ErrorBag,
  PopupConfirmation,
  FormChangePassword,
} from 'components';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Login, Dashboard, NotFound404 } from 'pages';
import {
  popupMessageSelector,
  loadingScreenSelector,
  popupConfirmationSelector,
  formChangePasswordSelector,
} from 'modules';
import { useSelector, shallowEqual } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { appConfig } from 'configs';

function App(): JSX.Element {
  const popupMessage = useSelector(popupMessageSelector, shallowEqual);
  const loadingScreen = useSelector(loadingScreenSelector, shallowEqual);
  const formChangePassword = useSelector(
    formChangePasswordSelector,
    shallowEqual,
  );
  const popupConfirmation = useSelector(
    popupConfirmationSelector,
    shallowEqual,
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route
            path="/login"
            element={
              <GoogleReCaptchaProvider
                reCaptchaKey={appConfig.recaptchaClientKey}
                scriptProps={{
                  appendTo: 'head',
                  async: true,
                }}
              >
                <Login />
              </GoogleReCaptchaProvider>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
        <FormChangePassword {...formChangePassword} />
        <ErrorBag />
        <GlobalStyle />
        <PopupConfirmation {...popupConfirmation} />
        <PopupMessage {...popupMessage} />
        <LoadingScreen {...loadingScreen} />
      </BrowserRouter>
    </>
  );
}

export default App;

import { memo } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Wrapper, ImageWrapper, Image, Label, Message } from './styles';

interface Props {
  label?: string;
  message?: string;
  success?: boolean;
  onClick?: () => void;
}

function CardCleanUp({ label, message, success, onClick }: Props): JSX.Element {
  return (
    <>
      <Card className="mb-3" style={{ height: 250 }}>
        <Card.Body>
          <Wrapper>
            <ImageWrapper>
              <Image />
            </ImageWrapper>
            {label && <Label>{label}</Label>}
            {message && <Message>{message}</Message>}
          </Wrapper>
        </Card.Body>
      </Card>
      {success && (
        <Button type="button" variant="info text-light" onClick={onClick}>
          Download File
        </Button>
      )}
    </>
  );
}

export default memo(CardCleanUp);

import { apoloClient, store, setIssuers, issuerGql } from 'modules';
import { IIssuer } from 'types';
import { MESSAGES } from 'utils';
import {
  IGetIssuersRequest,
  IGetIssuersResponse,
  IListIssuerQuery,
  IListIssuerQueryVariables,
  IDetailIssuerQuery,
  IDetailIssuerQueryVariables,
  ICreateIssuerRequest,
  ICreateIssuerMutate,
  ICreateIssuerMutateVariables,
  IUpdateIssuerRequest,
  IUpdateIssuerMutate,
  IUpdateIssuerMutateVariables,
  IDeleteIssuerMutate,
  IDeleteIssuerMutateVariables,
} from './interface';

export async function getIssuers(
  input: IGetIssuersRequest,
  storeToRedux = true,
): Promise<IGetIssuersResponse> {
  try {
    if (storeToRedux) {
      store.dispatch(setIssuers({ loading: true }));
    }
    const response = await apoloClient.query<
      IListIssuerQuery,
      IListIssuerQueryVariables
    >({
      query: issuerGql.LIST_ISSUER,
      variables: {
        input,
      },
    });

    if (!response.data?.listIssuer) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    const {
      page,
      limit,
      total,
      totalPage,
      issuers: data,
    } = response.data.listIssuer;

    if (storeToRedux) {
      store.dispatch(
        setIssuers({
          page,
          limit,
          total,
          totalPage,
          data,
          loading: false,
          error: false,
        }),
      );
    }

    return {
      page,
      limit,
      total,
      totalPage,
      data,
    };
  } catch (err) {
    if (storeToRedux) {
      store.dispatch(setIssuers({ loading: false, error: true }));
    }
    throw err;
  }
}

export async function getIssuer(id: string): Promise<IIssuer> {
  const response = await apoloClient.query<
    IDetailIssuerQuery,
    IDetailIssuerQueryVariables
  >({
    query: issuerGql.DETAIL_ISSUER,
    variables: {
      id,
    },
  });

  if (!response.data?.detailIssuer) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.detailIssuer;
}

export async function createIssuer(
  form: ICreateIssuerRequest,
): Promise<IIssuer> {
  const response = await apoloClient.mutate<
    ICreateIssuerMutate,
    ICreateIssuerMutateVariables
  >({
    mutation: issuerGql.CREATE_ISSUER,
    variables: {
      form,
    },
  });

  if (!response.data?.createIssuer) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.createIssuer;
}

export async function updateIssuer(
  form: IUpdateIssuerRequest,
): Promise<IIssuer> {
  const response = await apoloClient.mutate<
    IUpdateIssuerMutate,
    IUpdateIssuerMutateVariables
  >({
    mutation: issuerGql.UPDATE_ISSUER,
    variables: {
      form,
    },
  });

  if (!response.data?.updateIssuer) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.updateIssuer;
}

export async function deleteIssuer(id: string): Promise<IIssuer> {
  const response = await apoloClient.mutate<
    IDeleteIssuerMutate,
    IDeleteIssuerMutateVariables
  >({
    mutation: issuerGql.DELETE_ISSUER,
    variables: {
      id,
    },
  });

  if (!response.data?.deleteIssuer) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.deleteIssuer;
}

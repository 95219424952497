import { gql } from '@apollo/client';

export const LIST_FILE_TYPE = gql`
  query listFileType($input: ListFileTypeInput!) {
    listFileType(listFileTypeInput: $input) {
      page
      limit
      total
      totalPage
      fileTypes {
        id
        name
        status
      }
    }
  }
`;

export const DETAIL_FILE_TYPE = gql`
  query detailFileType($id: String!) {
    detailFileType(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_FILE_TYPE = gql`
  mutation createFileType($form: CreateFileTypeInput!) {
    createFileType(createFileTypeInput: $form) {
      id
      name
      status
    }
  }
`;

export const UPDATE_FILE_TYPE = gql`
  mutation updateFileType($form: UpdateFileTypeInput!) {
    updateFileType(updateFileTypeInput: $form) {
      id
      name
      status
    }
  }
`;

export const DELETE_FILE_TYPE = gql`
  mutation deleteFileType($id: String!) {
    deleteFileType(id: $id) {
      id
      name
      status
    }
  }
`;

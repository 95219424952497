import { PayloadAction } from '@reduxjs/toolkit';
import {
  IListMerchantTypeState,
  IListIssuerState,
  IConsolidationState,
  IListFileTypeState,
  IListMerchantState,
  IListBankState,
  IListAcquirerState,
  IReportConsolidateState,
} from './types';
import { consolidationState as initialState } from './states';

export function setMerchants(
  state: IConsolidationState,
  action: PayloadAction<IListMerchantState>,
): void {
  state.listMerchant = {
    ...state.listMerchant,
    ...action.payload,
  };
}

export function clearMerchants(state: IConsolidationState): void {
  state.listMerchant = initialState.listMerchant;
}

export function setMerchantTypes(
  state: IConsolidationState,
  action: PayloadAction<IListMerchantTypeState>,
): void {
  state.listMerchantType = {
    ...state.listMerchantType,
    ...action.payload,
  };
}

export function clearMerchantTypes(state: IConsolidationState): void {
  state.listMerchantType = initialState.listMerchantType;
}

export function setReportConsolidate(
  state: IConsolidationState,
  action: PayloadAction<IReportConsolidateState>,
): void {
  state.listReportConsolidate = {
    ...state.listReportConsolidate,
    ...action.payload,
  };
}

export function clearReportConsolidate(state: IConsolidationState): void {
  state.listReportConsolidate = initialState.listReportConsolidate;
}

export function setIssuers(
  state: IConsolidationState,
  action: PayloadAction<IListIssuerState>,
): void {
  state.listIssuer = {
    ...state.listIssuer,
    ...action.payload,
  };
}

export function clearIssuers(state: IConsolidationState): void {
  state.listIssuer = initialState.listIssuer;
}

export function setFileTypes(
  state: IConsolidationState,
  action: PayloadAction<IListFileTypeState>,
): void {
  state.listFileType = {
    ...state.listFileType,
    ...action.payload,
  };
}

export function clearFileTypes(state: IConsolidationState): void {
  state.listFileType = initialState.listFileType;
}

export function setBanks(
  state: IConsolidationState,
  action: PayloadAction<IListBankState>,
): void {
  state.listBank = {
    ...state.listBank,
    ...action.payload,
  };
}

export function clearBanks(state: IConsolidationState): void {
  state.listBank = initialState.listBank;
}

export function setAcquirers(
  state: IConsolidationState,
  action: PayloadAction<IListAcquirerState>,
): void {
  state.listAcquirer = {
    ...state.listAcquirer,
    ...action.payload,
  };
}

export function clearAcquirers(state: IConsolidationState): void {
  state.listAcquirer = initialState.listAcquirer;
}

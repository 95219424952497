import { memo } from 'react';
import { Table, Button } from 'react-bootstrap';
import { TablePagination } from 'components';
import { IUser } from 'types';

interface Props {
  loading?: boolean;
  onEdit: (data: IUser) => void;
  onDelete: (data: IUser) => void;
  onChangePage: React.MouseEventHandler;
  data: Array<IUser>;
  page: number;
  limit: number;
  totalPage: number;
}

function TableUser({
  data,
  onEdit,
  onDelete,
  loading,
  page = 0,
  limit = 0,
  totalPage = 1,
  onChangePage,
}: Props): JSX.Element {
  function getNumber(index: number): number {
    return page * limit + index + 1;
  }

  function renderTableBody(): JSX.Element {
    if (loading) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            Loading...
          </td>
        </tr>
      );
    }

    if (data.length > 0) {
      return (
        <>
          {data.map(function (user, index) {
            return (
              <tr key={user.id}>
                <td>{getNumber(index)}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.role?.displayName}</td>
                <td>{user.status}</td>
                <td style={{ textAlign: 'right' }}>
                  <Button
                    onClick={function (): void {
                      onEdit(user);
                    }}
                    size="sm"
                  >
                    Edit
                  </Button>{' '}
                  <Button
                    variant="danger"
                    onClick={function (): void {
                      onDelete(user);
                    }}
                    size="sm"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={6} className="text-center">
          Tidak ada data
        </td>
      </tr>
    );
  }

  return (
    <>
      <Table responsive hover bordered>
        <thead>
          <tr>
            <th>No</th>
            <th>Nama</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      <TablePagination
        limit={limit}
        last={totalPage}
        current={page}
        onClick={onChangePage}
      />
    </>
  );
}

export default memo(TableUser);

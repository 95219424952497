import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const Content = styled.li``;
export const Title = styled.span`
  display: inline-block;
  width: 100%;
  color: #ffffff;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`;
export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const Item = styled.li``;
export const NoLink = styled.span``;
export const Link = styled(NavLink).attrs({
  style: ({ isActive }) => ({
    backgroundColor: isActive ? 'rgba(255,255,255,0.2)' : 'transparent',
  }),
})`
  display: inline-block;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }
`;

export const Text = styled.span``;
export const Icon = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 16px;
  background-color: #ffffff;
`;

import { routePaths } from './path';
import { PERMISSION_LABELS } from 'utils';

export const adminMenu = {
  id: 1,
  title: 'Admin Panel',
  path: '',
  items: [
    {
      id: 2,
      title: PERMISSION_LABELS.USER,
      path: routePaths.MANAGEMENT_USER,
      items: [],
    },
    {
      id: 3,
      title: PERMISSION_LABELS.ROLE,
      path: routePaths.MANAGEMENT_ROLE,
      items: [],
    },
  ],
};

export const opsMenu = {
  id: 6,
  title: 'Ops Panel',
  path: '',
  items: [
    {
      id: 14,
      title: PERMISSION_LABELS.GENERATE_QR,
      path: routePaths.GENERATE_QR,
      items: [],
    },
  ],
};

export const financeMenu = {
  id: 9,
  title: 'Finance Panel',
  path: '',
  items: [
    {
      id: 13,
      title: PERMISSION_LABELS.CLEANUP_DATA,
      path: routePaths.CLEANUP_DATA,
      items: [],
    },
    {
      id: 10,
      title: PERMISSION_LABELS.CONSOLIDATE_DATA,
      path: routePaths.CONSOLIDATE_DATA,
      items: [],
    },
    {
      id: 7,
      title: PERMISSION_LABELS.CHARGING_SETUP,
      path: routePaths.CHARGING_SETUP,
      items: [],
    },
    {
      id: 8,
      title: PERMISSION_LABELS.MERCHANT_TYPE,
      path: routePaths.MERCHANT_TYPE,
      items: [],
    },
    {
      id: 11,
      title: PERMISSION_LABELS.FINALIZE_SETTLEMENT,
      path: routePaths.FINALIZE_SETTLEMENT,
      items: [],
    },
    {
      id: 12,
      title: PERMISSION_LABELS.CONSOLIDATE_REPORT,
      path: routePaths.CONSOLIDATE_REPORT,
      items: [],
    },
    {
      id: 14,
      title: PERMISSION_LABELS.MERGE_DATA,
      path: routePaths.MERGE_DATA,
      items: [],
    },
  ],
};

export const menus = [adminMenu, opsMenu, financeMenu];

import { apoloClient, userGql, store, setProfile } from 'modules';
import { getAuthUser, isAuthenticated, MESSAGES } from 'utils';
import {
  IProfileResponse,
  IChangePasswordResponse,
  IChangePasswordRequest,
  IChangePasswordMutate,
  IChangePasswordMutateVariable,
} from './interfaces';

export async function getProfile(): Promise<IProfileResponse> {
  const id = getAuthUser();
  const isLogin = isAuthenticated();

  if (!id || !isLogin) {
    throw new Error(MESSAGES.TOKEN_EXPIRED);
  }

  const response = await apoloClient.mutate({
    mutation: userGql.PROFILE_USER,
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const result = response.data?.userProfile;

  if (!result) {
    throw new Error('Profile not found');
  }

  store.dispatch(setProfile(result));

  return result;
}

export async function changePassword({
  oldPassword,
  password,
  passwordConfirmation,
}: IChangePasswordRequest): Promise<IChangePasswordResponse> {
  const response = await apoloClient.mutate<
    IChangePasswordMutate,
    IChangePasswordMutateVariable
  >({
    mutation: userGql.CHANGE_PASSWORD,
    variables: {
      form: {
        oldPassword,
        password,
        passwordConfirmation,
      },
    },
  });

  const result = response.data?.changePassword;
  if (!result) {
    throw new Error('No data returned from server');
  }

  return result;
}

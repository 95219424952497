import { RootState } from '../index';
import {
  IListMerchantState,
  IListMerchantTypeState,
  IListIssuerState,
  IListFileTypeState,
  IListBankState,
  IListAcquirerState,
  IReportConsolidateState,
} from './types';

export function listMerchantSelector(state: RootState): IListMerchantState {
  return state.consolidation.listMerchant;
}

export function listMerchantTypeSelector(
  state: RootState,
): IListMerchantTypeState {
  return state.consolidation.listMerchantType;
}

export function listReportConsolidate(
  state: RootState,
): IReportConsolidateState {
  return state.consolidation.listReportConsolidate;
}

export function listIssuerSelector(state: RootState): IListIssuerState {
  return state.consolidation.listIssuer;
}

export function listFileTypeSelector(state: RootState): IListFileTypeState {
  return state.consolidation.listFileType;
}

export function listBankSelector(state: RootState): IListBankState {
  return state.consolidation.listBank;
}

export function listAcquirerSelector(state: RootState): IListAcquirerState {
  return state.consolidation.listAcquirer;
}

import { useState, useEffect } from 'react';

const DEFAULT_ACTIVITY_EVENTS = [
  'click',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'focus',
];

const LOCAL_STORAGE_KEYS = {
  SIGNOUT_TIMER: 1,
};

const storeLastActivityIntoStorage = (time: number): void => {
  localStorage.setItem(String(LOCAL_STORAGE_KEYS.SIGNOUT_TIMER), String(time));
};

const getLastActivityFromStorage = (): number => {
  const data = localStorage.getItem(String(LOCAL_STORAGE_KEYS.SIGNOUT_TIMER));
  return Number(data);
};

const getCurrentTime = (): number => new Date().getTime();

let scheduledSignoutTimeout: NodeJS.Timeout;
let activityEventInterval: NodeJS.Timeout;

interface Props {
  activityEvents?: Array<string>;
  timeout?: number;
  isActive?: boolean;
  signOut?: (e: string) => void;
}

const ActivityDetector = ({
  activityEvents = DEFAULT_ACTIVITY_EVENTS,
  timeout = 5 * 60 * 1000,
  isActive = false,
  signOut,
}: Props): JSX.Element => {
  const [timeoutScheduled, setTimeoutScheduled] = useState<boolean>(false);

  const scheduleSignout = (time: number): void => {
    clearTimeout(scheduledSignoutTimeout);

    scheduledSignoutTimeout = setTimeout(() => {
      const scheduledInactivityCheck = getLastActivityFromStorage();
      const currentTime = getCurrentTime();

      if (currentTime >= scheduledInactivityCheck) {
        // if already passed scheduled time, do signout
        signOut?.('User has loged out due to inactivity');
      }
    }, time);
  };

  const resetTimer = (): void => {
    clearTimeout(activityEventInterval);
    activityEventInterval = setTimeout(() => setTimeoutScheduled(false), 200);
  };

  const handleUserActivityEvent = (): void => {
    resetTimer();
  };

  const handleStorageChangeEvent = ({ key, newValue }: StorageEvent): void => {
    if (Number(key) === LOCAL_STORAGE_KEYS.SIGNOUT_TIMER) {
      scheduleSignout(Number(newValue) - getCurrentTime());
    }
  };

  const stop = (): void => {
    detachListeners();
    clearTimeout(scheduledSignoutTimeout);
    clearTimeout(activityEventInterval);
  };

  const attachListeners = (): void => {
    activityEvents.forEach((eventName) =>
      window.addEventListener(eventName, handleUserActivityEvent),
    );

    window.addEventListener('storage', handleStorageChangeEvent);
  };

  const detachListeners = (): void => {
    activityEvents.forEach((eventName) =>
      window.removeEventListener(eventName, handleUserActivityEvent),
    );

    window.removeEventListener('storage', handleStorageChangeEvent);
  };

  useEffect(() => {
    // user loged in
    if (isActive) {
      attachListeners();
      // schedule initial timeout
      setTimeoutScheduled(false);
    }
    return (): void => {
      stop();
    };
  }, [isActive]);

  useEffect(() => {
    if (!timeoutScheduled) {
      // on every user activity schedule a new signout
      scheduleSignout(timeout);

      // store scheduled time for other clients
      storeLastActivityIntoStorage(getCurrentTime() + timeout);
    }
    setTimeoutScheduled(true);
  }, [timeoutScheduled, timeout]);

  return <div />;
};

export default ActivityDetector;

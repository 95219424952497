import { debounce } from 'ts-debounce';
import styled from 'styled-components';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormState, ErrorState } from '../index';
import { FormInput } from 'components';
import { listRoleSelector } from 'modules';
import { useSelector, shallowEqual } from 'react-redux';
import { mapRolesToOptions } from 'utils';
import { getRoles } from 'services';
import { OnSelectChangeType, OptionsType } from 'types';
import { IGetRolesResponse } from 'services/role/interfaces';

const statusOptions = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Non-active',
  },
];

type Props = {
  edit: boolean;
  show: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange: OnSelectChangeType;
  form: FormState;
  error: ErrorState;
  disabled?: boolean;
  onSubmit: (e: React.SyntheticEvent) => void;
  onClose: () => void;
};

const BtnWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`;

function FormUser({
  show,
  edit,
  form,
  error,
  disabled,
  onClose,
  onChange,
  onSelectChange,
  onSubmit,
}: Props): JSX.Element {
  const { data: roles } = useSelector(listRoleSelector, shallowEqual);
  const roleOptions = mapRolesToOptions(roles);

  async function loadRoleOptions(
    inputValue: string,
  ): Promise<Array<OptionsType>> {
    const asyncF = async (): Promise<IGetRolesResponse> =>
      await getRoles(
        {
          search: { keyword: inputValue },
          page: 0,
          limit: 25,
          order: { sortBy: 'ASC', orderBy: 'DISPLAY_NAME' },
        },
        false,
      );

    const g = debounce(asyncF, 500);
    const res = await g();
    return mapRolesToOptions(res.data);
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {edit ? 'Edit User' : 'Tambah User'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormInput
            type="text"
            name="name"
            label="Nama User"
            placeholder="Masukan Nama User"
            value={form.name}
            onChange={onChange}
            error={Boolean(error.name)}
            errorMessage={error.name}
            horizontal
            disabled={disabled}
            max={25}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="email"
            name="email"
            label="Email"
            placeholder="Masukan email"
            value={form.email}
            onChange={onChange}
            error={Boolean(error.email)}
            errorMessage={error.email}
            horizontal
            disabled={disabled}
            max={50}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="select"
            name="roleId"
            label="Role"
            placeholder="Pilih Role"
            value={form.roleId}
            onSelectChange={onSelectChange}
            loadOptions={loadRoleOptions}
            error={Boolean(error.roleId)}
            errorMessage={error.roleId}
            options={roleOptions}
            horizontal
            async
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="select"
            name="status"
            label="Status"
            placeholder="Pilih Status"
            value={form.status}
            onSelectChange={onSelectChange}
            error={Boolean(error.status)}
            errorMessage={error.status}
            options={statusOptions}
            horizontal
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="password"
            name="password"
            label="Password"
            placeholder={edit ? 'Ubah password' : 'Buat password'}
            value={form.password}
            onChange={onChange}
            error={Boolean(error.password)}
            errorMessage={error.password}
            horizontal
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormInput
            type="password"
            name="passwordConfirmation"
            label="Password Confirmation"
            placeholder="Masukan konfirmasi password"
            value={form.passwordConfirmation}
            onChange={onChange}
            error={Boolean(error.passwordConfirmation)}
            errorMessage={error.passwordConfirmation}
            horizontal
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <BtnWrapper>
            <Button disabled={disabled} type="submit">
              <strong>{edit ? 'Ubah Pengaturan' : 'Tambah User'}</strong>
            </Button>
          </BtnWrapper>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FormUser;

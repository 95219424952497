import { PayloadAction } from '@reduxjs/toolkit';
import { ILoadingState, ILoadingScreenState } from './types';

export function setLoadingScreen(
  state: ILoadingState,
  action: PayloadAction<ILoadingScreenState>,
): void {
  state.loadingScreen = {
    ...state.loadingScreen,
    ...action.payload,
  };
}

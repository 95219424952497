import { apoloClient, store, setAcquirers, acquirerGql } from 'modules';
import { MESSAGES } from 'utils';
import { IAcquirer } from 'types';
import {
  IGetAcquirersRequest,
  IGetAcquirersResponse,
  IListAcquirerQuery,
  IListAcquirerQueryVariables,
  IDetailAcquirerQuery,
  IDetailAcquirerQueryVariables,
} from './interfaces';

export async function getAcquirers(
  input: IGetAcquirersRequest,
  saveToRedux = true,
): Promise<IGetAcquirersResponse> {
  try {
    if (saveToRedux) {
      store.dispatch(setAcquirers({ loading: true, error: false }));
    }

    const response = await apoloClient.query<
      IListAcquirerQuery,
      IListAcquirerQueryVariables
    >({
      query: acquirerGql.LIST_ACQUIRER,
      variables: {
        input,
      },
    });

    if (!response?.data?.listAcquirer) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    const {
      page,
      total,
      limit,
      totalPage,
      acquirers: data,
    } = response.data.listAcquirer;

    if (saveToRedux) {
      store.dispatch(
        setAcquirers({
          page,
          total,
          limit,
          totalPage,
          data,
          loading: false,
          error: false,
        }),
      );
    }

    return {
      page,
      total,
      limit,
      totalPage,
      data,
    };
  } catch (err) {
    if (saveToRedux) {
      store.dispatch(setAcquirers({ loading: false, error: true }));
    }
    throw err;
  }
}

export async function detailAcquirer(id: string): Promise<IAcquirer> {
  const response = await apoloClient.query<
    IDetailAcquirerQuery,
    IDetailAcquirerQueryVariables
  >({
    query: acquirerGql.DETAIL_ACQUIRER,
    variables: {
      id,
    },
  });

  if (!response?.data?.detailAcquirer) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.detailAcquirer;
}

import { PERMISSIONS } from 'utils';

export const routePaths = {
  NOT_FOUND_404: '*',
  HOME: '/',
  LOGIN: 'login',
  MANAGEMENT_USER: PERMISSIONS.USER,
  MANAGEMENT_ROLE: PERMISSIONS.ROLE,
  CHARGING_SETUP: PERMISSIONS.CHARGING_SETUP,
  MERCHANT_TYPE: PERMISSIONS.MERCHANT_TYPE,
  CLEANUP_DATA: PERMISSIONS.CLEANUP_DATA,
  CONSOLIDATE_DATA: PERMISSIONS.CONSOLIDATE_DATA,
  FINALIZE_SETTLEMENT: PERMISSIONS.FINALIZE_SETTLEMENT,
  GENERATE_QR: PERMISSIONS.GENERATE_QR,
  CONSOLIDATE_REPORT: PERMISSIONS.CONSOLIDATE_REPORT,
  MERGE_DATA: PERMISSIONS.MERGE_DATA,
};

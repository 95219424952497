import styled from 'styled-components';
import { Modal, Button, Form } from 'react-bootstrap';
import { FormState, ErrorState } from '../index';
import { FormInput, FormCheck } from 'components';
import { roleCheckboxOptions } from 'utils';

type Props = {
  edit: boolean;
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
  form: FormState;
  error: ErrorState;
  disabled?: boolean;
  onSubmit: (e: React.SyntheticEvent) => void;
  onClose: () => void;
};

const BtnWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`;

function FormRole({
  show,
  edit,
  form,
  error,
  disabled,
  onClose,
  onChange,
  onSubmit,
}: Props): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {edit ? 'Edit Management Role' : 'Tambah Management Role'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormInput
            type="text"
            name="displayName"
            label="Nama Role"
            placeholder="Masukan Nama Role"
            value={form.displayName}
            onChange={onChange}
            error={Boolean(error.displayName)}
            errorMessage={error.displayName}
            horizontal
            max={20}
            disabled={disabled}
            horizontalSize={{
              label: 4,
              input: 8,
            }}
          />
          <FormCheck
            id="permission-checkbox"
            label="Menu"
            name="permissions"
            options={roleCheckboxOptions}
            value={form.permissions}
            error={Boolean(error.permissions)}
            errorMessage={error.permissions}
            horizontal
            horizontalSize={{
              label: 4,
              input: 8,
            }}
            disabled={disabled}
            onChange={onChange}
          />
          <BtnWrapper>
            <Button disabled={disabled} type="submit">
              <strong>{edit ? 'Ubah Pengaturan' : 'Tambah Role'}</strong>
            </Button>
          </BtnWrapper>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FormRole;

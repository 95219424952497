import { gql } from '@apollo/client';

export const LIST_USER = gql`
  query getUsers($listUserInput: ListUserInput!) {
    listUser(listUserInput: $listUserInput) {
      page
      limit
      total
      totalPage
      users {
        id
        name
        email
        status
        role {
          id
          name
          displayName
          permissions
        }
      }
    }
  }
`;

export const DETAIL_USER = gql`
  query getUser($id: String!) {
    detailUser(id: $id) {
      id
      name
      email
      status
      role {
        id
        name
        displayName
        permissions
        status
      }
    }
  }
`;

export const PROFILE_USER = gql`
  query {
    userProfile {
      id
      name
      email
      status
      role {
        id
        name
        displayName
        permissions
        status
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($form: CreateUserInput!) {
    createUser(createUserInput: $form) {
      id
      name
      email
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($form: UpdateUserInput!) {
    updateUser(updateUserInput: $form) {
      id
      name
      email
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      name
      email
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($form: ChangePasswordInput!) {
    changePassword(changePasswordInput: $form) {
      id
      name
      email
    }
  }
`;

import styled from 'styled-components';
import { COLORS } from 'utils';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 240px;
  font-size: 14px;
  overflow-y: auto;
  background-color: ${COLORS.dark};
  z-index: 10px;
`;

export const Content = styled.div`
  padding-top: 32px;
`;

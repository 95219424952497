import { gql } from '@apollo/client';

export const LIST_CONSOLIDATE_REPORT = gql`
  query listRecon($input: ListReconInput!) {
    listRecon(listReconInput: $input) {
      page
      limit
      total
      totalPage
      recons {
        id
        status
        reconDate
        issuer {
          name
        }
        merchantType {
          name
        }
        successfulReconLink
        failedReconLink
        createdAt
        issuerId
        merchantTypeId
      }
    }
  }
`;

export const RE_RUN = gql`
  mutation reRunRecon($id: String!) {
    reRunRecon(id: $id) {
      id
      status
    }
  }
`;

import { Suspense, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { DashboardContainer } from 'containers';
import {
  adminRoutes,
  dashboardMainRoutes,
  opsRoutes,
  financeRoutes,
} from 'routes';
import { Navigate } from 'react-router-dom';
import { profileSelector } from 'modules';
import { useSelector, shallowEqual } from 'react-redux';
import { isAuthenticated, PERMISSIONS } from 'utils';
import { loadingScreen, errorBag, popupExpiredSession } from 'controls';
import { getProfile, logout } from 'services';
import { ActivityDetector, PageInfo } from 'components';

function Dashboard(): JSX.Element {
  const profile = useSelector(profileSelector, shallowEqual);
  const isLogin = isAuthenticated();
  const navigate = useNavigate();

  function onSignOut(): void {
    logout();
    popupExpiredSession();
    navigate('/login', { replace: true });
  }

  function checkPermission(path: string): boolean {
    if (profile?.role.permissions.includes(path)) {
      return true;
    }

    return false;
  }

  function renderAdminRoutes(): JSX.Element {
    return (
      <>
        {adminRoutes.map(({ path, Element }, i) => {
          if (checkPermission(path)) {
            return (
              <Route
                key={i}
                path={path}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Element />
                  </Suspense>
                }
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  function renderOpsRoutes(): JSX.Element {
    return (
      <>
        {opsRoutes.map(({ path, Element }, i) => {
          if (checkPermission(path)) {
            const newPath =
              path === PERMISSIONS.GENERATE_QR ? path + '/*' : path;

            return (
              <Route
                key={i}
                path={newPath}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Element />
                  </Suspense>
                }
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  function renderFinanceRoutes(): JSX.Element {
    return (
      <>
        {financeRoutes.map(({ path, Element }, i) => {
          if (checkPermission(path)) {
            return (
              <Route
                key={i}
                path={path}
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Element />
                  </Suspense>
                }
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  function renderMainRoutes(): JSX.Element {
    return (
      <>
        {dashboardMainRoutes.map(({ path, Element }, i) => (
          <Route
            key={i}
            path={path}
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Element />
              </Suspense>
            }
          />
        ))}
      </>
    );
  }

  useEffect(
    function () {
      if (isLogin && !profile) {
        loadingScreen(true);
        getProfile()
          .then(function () {
            loadingScreen(false);
          })
          .catch(function (err) {
            loadingScreen(false);
            errorBag(err);
          });
      }
    },
    [profile, isLogin],
  );

  if (!isLogin) {
    return <Navigate to="/login" replace />;
  }

  return (
    <DashboardContainer>
      <PageInfo title="Dashboard" />
      <ActivityDetector isActive={true} timeout={1800000} signOut={onSignOut} />
      <Routes>
        {renderAdminRoutes()}
        {renderOpsRoutes()}
        {renderFinanceRoutes()}
        {renderMainRoutes()}
      </Routes>
    </DashboardContainer>
  );
}

export default Dashboard;

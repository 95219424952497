import { createSlice } from '@reduxjs/toolkit';
import { IPermissionState } from './types';
import * as reducers from './reducers';

export const permissionState: IPermissionState = {
  listPermission: {
    page: 0,
    limit: 10,
    total: 0,
    totalPage: 1,
    error: false,
    loading: false,
    data: [],
  },
};

export const permissionSlice = createSlice({
  name: 'role',
  initialState: permissionState,
  reducers,
});

export const { setPermissions, clearPermissions } = permissionSlice.actions;

import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';

export const InputPasswordWrapper = styled.div`
  position: relative;
`;

export const InputPassword = styled(Form.Control)`
  padding-right: 42px;
`;

export const IconButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 6px;
  margin-right: 4px;
  background-color: transparent;
  border: none;
  outline: none;
`;
export const Icon = styled(ReactSVG)`
  opacity: 0.6;
`;

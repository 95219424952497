import { createSlice } from '@reduxjs/toolkit';
import { IRoleState } from './types';
import * as reducers from './reducers';

export const roleState: IRoleState = {
  listRole: {
    page: 0,
    limit: 10,
    total: 0,
    totalPage: 1,
    error: false,
    loading: false,
    data: [],
  },
};

export const roleSlice = createSlice({
  name: 'role',
  initialState: roleState,
  reducers,
});

export const { setRoles, clearRoles } = roleSlice.actions;

import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f1f4f5;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 12px;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 50px 200px 24px;
`;

export const Avatar = styled.img.attrs({
  alt: '',
})`
  width: 50px;
  height: 50px;
`;

export const Content = styled.div``;
export const Title = styled.span`
  display: flex;
  font-weight: bold;
`;
export const Subtitle = styled.span`
  display: inline-block;
`;

export const IconWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  outline: none;
`;

export const DrodownIcon = styled.div`
  width: 10px;
  height: 10px;
  border-color: #000;
  border-width: 3px;
  border-style: solid;
  border-right-width: 0;
  border-top-width: 0;
  transform: rotate(-45deg);
`;

import { memo } from 'react';
import { Card, Button } from 'react-bootstrap';
import {
  Wrapper,
  ImageWrapper,
  Image,
  Label,
  Message,
  ButtonWrapper,
} from './styles';

interface Props {
  label?: string;
  message?: string;
  success?: boolean;
  onClick?: () => void;
  link?: string;
}

function CardCleanUp({ label, message, success, link }: Props): JSX.Element {
  return (
    <>
      <Card className="mb-3" style={{ height: 250 }}>
        <Card.Body>
          <Wrapper>
            <ImageWrapper>
              <Image />
            </ImageWrapper>
            {label && <Label>{label}</Label>}
            {message && <Message>{message}</Message>}
          </Wrapper>
        </Card.Body>
      </Card>
      {success && (
        <ButtonWrapper>
          <a href={link}>
            <Button type="button" variant="info text-light">
              Download Merged File
            </Button>
          </a>
        </ButtonWrapper>
      )}
    </>
  );
}

export default memo(CardCleanUp);

import { store, apoloClient, bankGql, setBanks } from 'modules';
import { MESSAGES } from 'utils';
import {
  IGetBanksRequest,
  IGetBanksResponse,
  IListBankQuery,
  IListBankQueryVariables,
} from './interfaces';

export async function getBanks(
  input: IGetBanksRequest,
  saveToRedux = true,
): Promise<IGetBanksResponse> {
  try {
    if (saveToRedux) {
      store.dispatch(setBanks({ loading: true, error: false }));
    }

    const response = await apoloClient.query<
      IListBankQuery,
      IListBankQueryVariables
    >({
      query: bankGql.LIST_BANK,
      variables: {
        input,
      },
    });

    if (!response.data?.listBank) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    const {
      page,
      limit,
      totalPage,
      total,
      banks: data,
    } = response.data.listBank;

    if (saveToRedux) {
      store.dispatch(
        setBanks({
          page,
          limit,
          total,
          totalPage,
          data,
          error: false,
          loading: false,
        }),
      );
    }

    return {
      page,
      limit,
      total,
      totalPage,
      data,
    };
  } catch (err) {
    if (saveToRedux) {
      store.dispatch(setBanks({ loading: false, error: true }));
    }
    throw err;
  }
}

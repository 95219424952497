import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MainContent, ActionHeader, PageInfo } from 'components';
import { useForm, useDebounce } from 'hooks';
import { listRoleSelector, profileSelector } from 'modules';
import { IRole, GlobalStatusType } from 'types';
import { useSelector, shallowEqual } from 'react-redux';
import {
  getRoles,
  createRole,
  updateRole,
  deleteRole,
  getProfile,
} from 'services';
import { popupMessage, errorBag, loadingScreen, confirmation } from 'controls';
import { isAuthenticated, validator, PERMISSION_LABELS } from 'utils';
import FormRole from './FormRole';
import TableRole from './TableRole';

export type FormState = {
  id: string;
  name: string;
  displayName: string;
  status: GlobalStatusType | string;
  permissions: Array<string>;
};

export type ErrorState = {
  name: string;
  displayName: string;
  status: string;
  permissions: string;
};

const initialFormState: FormState = {
  id: '',
  name: '',
  displayName: '',
  status: 'ACTIVE',
  permissions: [],
};

const initialErrorState: ErrorState = {
  name: '',
  displayName: '',
  status: '',
  permissions: '',
};

function Role(): JSX.Element {
  const profile = useSelector(profileSelector, shallowEqual);
  const listRole = useSelector(listRoleSelector, shallowEqual);

  const roles: Array<IRole> = listRole.data || [];
  const listLoading = listRole.loading;

  const [formRole, setFormRole] = useState<boolean>(false);
  const [form, setForm] = useForm(initialFormState);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [error, setError] = useState(initialErrorState);
  const [searchParams, setSearchParam] = useSearchParams();

  const defaultLimit = 10;
  const page = searchParams.get('page') || 0;
  const limit = searchParams.get('limit') || defaultLimit;
  const search = searchParams.get('search') || '';

  const debouncedSearchQuery = useDebounce(search, 500);

  // variable
  const isEdit = Boolean(form.id);
  const isLogin = isAuthenticated();

  function onChangeFormInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setForm(name, value);
    const field = name as keyof typeof error;
    // eslint-disable-next-line security/detect-object-injection
    if (error[field] !== '') {
      setError((prev) => ({ ...prev, [name]: '' }));
    }
  }

  function onCloseForm(): void {
    setFormRole(false);
    setForm('reset', null);
  }

  function onAdd(): void {
    setFormRole(true);
  }

  function onEdit(data: IRole): void {
    setForm(
      {
        id: data.id,
        name: data.name,
        displayName: data.displayName,
        permissions: data.permissions,
        status: data.status,
      },
      null,
    );
    setFormRole(true);
  }

  function confirmDelete(data: IRole): void {
    loadingScreen(true);
    deleteRole(data.id)
      .then(function () {
        loadingScreen(false);
        popupMessage({
          show: true,
          type: 'success',
          title: 'Berhasil Dihapus',
          message: 'Hi, Role berhasil dihapus',
        });
        getRoles({
          page: 0,
          limit: 25,
        });
      })
      .catch(function (err) {
        loadingScreen(false);
        errorBag(err);
      });
  }

  function onDelete(data: IRole): void {
    confirmation({
      title: 'Mau Hapus?',
      onConfirmed: () => confirmDelete(data),
    });
  }

  function validateForm(): [boolean, ErrorState] {
    const newError = { ...error };

    if (!form.displayName) {
      newError.displayName = 'Mohon masukan nama role';
    } else if (!validator.isAlphabetWithSpaceOnly(form.displayName)) {
      newError.displayName = 'Nama role hanya boleh alpabet';
    }

    if (!form.permissions || form.permissions.length === 0) {
      newError.permissions = 'Mohon pilih minimal 1 menu';
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  async function onSubmit(e: React.SyntheticEvent): Promise<void> {
    e?.preventDefault();

    const [valid, newError] = validateForm();
    if (!valid) {
      setError(newError);
      return;
    }

    const validForm: FormState = { ...form };
    validForm.name = validForm.displayName
      .trim()
      .toLowerCase()
      .replace(/\s/g, '-');
    validForm.status = 'ACTIVE';

    try {
      setFormLoading(true);

      if (isEdit) {
        await updateRole(validForm);
      } else {
        await createRole(validForm);
      }
      setFormLoading(false);
      onCloseForm();

      popupMessage({
        show: true,
        type: 'success',
        title: isEdit ? 'Berhasil Diubah' : 'Berhasil Dibuat',
        message: isEdit
          ? 'Hi, Role berhasil diubah'
          : 'Hi, Role baru berhasil dibuat',
      });

      if (isEdit && form.id === profile?.role.id) {
        await getProfile();
      }

      getRoles({
        page: Number(page),
        limit: Number(limit),
        ...(search && {
          search: {
            keyword: search,
          },
        }),
      });
    } catch (err) {
      setFormLoading(false);
      errorBag(err);
    }
  }

  function onSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target;

    const urlParams = new URLSearchParams();

    if (value) {
      urlParams.set('search', value);
    } else {
      urlParams.delete('search');
    }
    urlParams.set('page', String(0));
    if (limit) {
      urlParams.set('limit', String(defaultLimit));
    }
    setSearchParam(urlParams);
  }

  function onSubmitSearch(e: React.SyntheticEvent): void {
    e?.preventDefault();
  }

  function onChangePage(e: React.MouseEvent<HTMLAnchorElement>): void {
    const dataPage = e.currentTarget.getAttribute('data-page');
    const pageNumber = Number(dataPage);
    const urlParams = new URLSearchParams();
    urlParams.set('page', String(pageNumber));

    if (limit) {
      urlParams.set('limit', String(limit));
    }
    setSearchParam(urlParams);
  }

  useEffect(
    function () {
      if (profile && isLogin) {
        getRoles({
          search: {
            keyword: debouncedSearchQuery,
          },
          page: Number(page),
          limit: Number(limit),
        }).catch(function (err) {
          errorBag(err);
        });
      }
    },
    [profile, isLogin, debouncedSearchQuery, page, limit],
  );

  return (
    <>
      <PageInfo title={PERMISSION_LABELS.ROLE} />
      <MainContent
        title={PERMISSION_LABELS.ROLE}
        headerRightElement={
          <ActionHeader
            addTitle="Tambah Role"
            searchPlaceholder="Search..."
            onAdd={onAdd}
            onChange={onSearch}
            onSubmit={onSubmitSearch}
            value={search}
          />
        }
      >
        <TableRole
          limit={Number(listRole.limit) || Number(limit)}
          page={Number(listRole.page) || Number(page)}
          totalPage={Number(listRole.total)}
          onChangePage={onChangePage}
          loading={listLoading}
          data={roles}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </MainContent>
      <FormRole
        edit={isEdit}
        show={formRole}
        form={form}
        error={error}
        disabled={formLoading}
        onSubmit={onSubmit}
        onChange={onChangeFormInput}
        onClose={onCloseForm}
      />
    </>
  );
}

export default Role;

import {
  Wrapper,
  Content,
  Title,
  List,
  Item,
  NoLink,
  Link,
  Text,
  Icon,
} from './styles';

export type SideMenuProps = {
  id?: string | number;
  title?: string;
  path?: string;
  items: Array<SideMenuProps>;
};

function SidebarMenu({ title, items }: SideMenuProps): JSX.Element {
  return (
    <Wrapper>
      <Content>
        <Title>{title}</Title>
        {items.length > 0 && (
          <List>
            {items.map((item) => {
              if (!item.path) {
                return (
                  <Item key={item.id}>
                    <NoLink>{item.title}</NoLink>
                  </Item>
                );
              }

              return (
                <Item key={item.id}>
                  <Link to={item.path}>
                    <Icon />
                    <Text>{item.title}</Text>
                  </Link>
                </Item>
              );
            })}
          </List>
        )}
      </Content>
    </Wrapper>
  );
}

export default SidebarMenu;

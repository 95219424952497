import { memo, useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import HelperText from '../HelperText';
import { ILabelValue, HorizontalSizeType } from 'types';

interface ICheckbox extends ILabelValue {
  disabled?: boolean;
}

interface IChangeValue {
  target: {
    name: string;
    value: Array<string>;
  };
}

type OnChangeType = (
  e: IChangeValue | React.ChangeEvent<HTMLInputElement>,
) => void | React.ChangeEventHandler;

interface Props {
  id?: string;
  name: string;
  label: string;
  value?: Array<string>;
  options: Array<ICheckbox>;
  error?: boolean;
  errorMessage?: string;
  valid?: boolean;
  validMessage?: string;
  message?: string;
  horizontal?: boolean;
  disabled?: boolean;
  onChange: OnChangeType;
  horizontalSize?: HorizontalSizeType;
}

function FormCheck({
  name,
  label,
  value = [],
  error,
  valid,
  message,
  errorMessage,
  validMessage,
  options,
  horizontal,
  disabled,
  horizontalSize,
  onChange,
}: Props): JSX.Element {
  const [optionState, setOption] = useState<Array<ICheckbox>>([]);
  const [valueState, setValue] = useState<Array<string>>([]);

  function onChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>): void {
    const {
      name: inputName,
      value: inputValue,
      checked: inputChecked,
    } = e.target;

    let currentValue = [...valueState];

    const existing = currentValue.includes(inputValue);

    if (inputChecked) {
      // check existing value
      if (!existing) {
        currentValue.push(inputValue);
      }
    } else {
      if (existing) {
        currentValue = currentValue.filter(function (val) {
          if (val !== inputValue) {
            return true;
          }
          return false;
        });
      }
    }

    setValue(currentValue);
    onChange({
      target: {
        name: inputName,
        value: currentValue,
      },
    });
  }

  function isChecked(option: ICheckbox): boolean {
    if (valueState.length > 0) {
      const hasValue: boolean = valueState.includes(option.value);

      return hasValue;
    }
    return false;
  }

  function renderCheckbox(): JSX.Element | null {
    if (optionState.length > 0) {
      return (
        <>
          {optionState.map(function (option, index) {
            return (
              <Form.Check
                key={index}
                name={name}
                type="checkbox"
                id={`checkbox-${name}-${option.value}`}
                disabled={disabled}
              >
                <Form.Check.Input
                  type="checkbox"
                  isValid={valid}
                  isInvalid={error}
                  name={name}
                  onChange={onChangeCheckbox}
                  value={option.value}
                  checked={isChecked(option)}
                  disabled={disabled}
                />
                <Form.Check.Label>{option.label}</Form.Check.Label>
              </Form.Check>
            );
          })}
        </>
      );
    }

    return null;
  }

  useEffect(
    function () {
      if (options.length > 0) {
        setOption(options);
      }
    },
    [options],
  );

  useEffect(
    function () {
      if (value.length > 0) {
        setValue(value);
      }
    },
    [value],
  );

  useEffect(function () {
    return function (): void {
      setOption([]);
      setValue([]);
    };
  }, []);

  if (horizontal) {
    return (
      <Form.Group as={Row}>
        <Form.Label column sm={horizontalSize?.label}>
          Menu
        </Form.Label>
        <Col sm={horizontalSize?.input}>
          {renderCheckbox()}
          <HelperText
            control={false}
            error={error}
            valid={valid}
            errorMessage={errorMessage}
            validMessage={validMessage}
            message={message}
          />
        </Col>
      </Form.Group>
    );
  }

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      {renderCheckbox()}
      <HelperText
        control={false}
        error={error}
        valid={valid}
        errorMessage={errorMessage}
        validMessage={validMessage}
        message={message}
      />
    </Form.Group>
  );
}

export default memo(FormCheck);

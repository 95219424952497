import { ILabelValue, IRole, IMerchantType, IIssuer, IFileType } from 'types';
import {
  roleCheckboxOptions,
  NUMBER_TYPES,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_LABELS,
} from './constants';
import XLSX from 'xlsx';

export function setAuthToken(token: string): void {
  localStorage.setItem('reconAuth', token);
}

export function getAuthToken(): string | null {
  return localStorage.getItem('reconAuth');
}

export function clearAuthToken(): void {
  localStorage.removeItem('reconAuth');
}

export function isAuthenticated(): boolean {
  return getAuthToken() ? true : false;
}

export function setAuthUser(id: string): void {
  localStorage.setItem('reconUser', id);
}

export function getAuthUser(): string | null {
  return localStorage.getItem('reconUser');
}

export function clearAuthUser(): void {
  localStorage.removeItem('reconUser');
}

export function pad(n: string, width: number, z?: string): string {
  z = z || '0';
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function mapUserRolePermissions(
  permissions: Array<string>,
): Array<ILabelValue> {
  const result = roleCheckboxOptions.filter(function (perm) {
    const match = permissions.includes(perm.value);
    return match;
  });

  return result;
}

export function mapRolesToOptions(roles?: Array<IRole>): Array<ILabelValue> {
  if (!roles) {
    return [];
  }

  return roles.map((role) => ({
    label: role.displayName,
    value: role.id,
  }));
}

export function mapMerchantTypesToOptions(
  data?: Array<IMerchantType>,
): Array<ILabelValue> {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export function createOptions<T>(
  data: Array<T>,
  labelKey: string,
  valueKey: string,
  emptyOption?: boolean,
): Array<ILabelValue> {
  let options: Array<ILabelValue> = [];

  if (emptyOption) {
    options.push({
      label: 'Tidak ada',
      value: '',
    });
  }

  if (!data) {
    return options;
  }

  options = [
    ...options,
    ...data.map((item: T): ILabelValue => {
      // eslint-disable-next-line security/detect-object-injection
      const label = item[labelKey as keyof T];
      // eslint-disable-next-line security/detect-object-injection
      const value = item[valueKey as keyof T];

      return {
        label: label as unknown as string,
        value: value as unknown as string,
        ...item,
      };
    }),
  ];

  return options;
}

export function mapIssuersToOptions(data?: Array<IIssuer>): Array<ILabelValue> {
  if (!data) {
    return [];
  }

  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export function mapFileTypesToOption(
  data?: Array<IFileType>,
): Array<ILabelValue> {
  if (!data) return [];

  return data.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line security/detect-object-injection
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function readSheetFileToData(
  file: File | Blob,
): Promise<Array<unknown>> {
  return new Promise(function (resolve, reject) {
    if (!file) {
      reject(new Error('File is required'));
    }

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e: ProgressEvent<FileReader>): void => {
      /* Parse data */
      const bstr = e?.target?.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      // eslint-disable-next-line security/detect-object-injection
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        raw: false,
        rawNumbers: false,
        blankrows: false,
      });

      return resolve(data);
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
}

export function decurrency(currency?: string): number {
  if (!currency) return 0;
  const number = Number(currency.replace(/[^0-9\.]+/g, ''));
  return number;
}

export function parseJson<T = unknown>(s: string): T | null {
  try {
    return JSON.parse(s);
  } catch (err) {
    return null;
  }
}

export function getTableNumber(
  index: number,
  limit: number,
  page: number,
): number {
  return page * limit + index + 1;
}

export function getNumberTypeText(
  value?: number,
  type?: string,
  defaultValue?: string,
): string {
  switch (type) {
    case NUMBER_TYPES.AMOUNT.toLowerCase():
      return `Rp. ${value}`;
    case NUMBER_TYPES.PERCENTAGE.toLowerCase():
      return `${value}%`;
    default:
      return typeof value === 'number'
        ? value.toString()
        : (defaultValue as string);
  }
}

export function getTransactionTypeLabel(
  value?: string,
  defaultValue?: string,
): string {
  switch (value?.toUpperCase()) {
    case TRANSACTION_TYPES.QRIS.toUpperCase():
      return TRANSACTION_TYPE_LABELS.QRIS;
    case TRANSACTION_TYPES.CARD.toUpperCase():
      return TRANSACTION_TYPE_LABELS.CARD;
    case TRANSACTION_TYPES.CARD_ONLINE.toUpperCase():
      return TRANSACTION_TYPE_LABELS.CARD_ONLINE;
    case TRANSACTION_TYPES.VIRTUAL_ACCOUNT.toUpperCase():
      return TRANSACTION_TYPE_LABELS.VIRTUAL_ACCOUNT;
    case TRANSACTION_TYPES.EWALLET.toUpperCase():
      return TRANSACTION_TYPE_LABELS.EWALLET;
    case TRANSACTION_TYPES.GIFTCARD.toUpperCase():
      return TRANSACTION_TYPE_LABELS.GIFTCARD;
    default:
      return value ? value : (defaultValue as string);
  }
}

export function paginate(
  totalItems: number,
  currentPage = 1,
  pageSize = 10,
  maxPages = 10,
): {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: Array<number>;
} {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage: number;
  let endPage: number;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 0;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 0;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i,
  );

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
}

export const urlSpaceFormatter = (url = ''): string => {
  return url.split(' ').join('%20');
};
export const headerFormatter = (word = ''): string => {
  const space = word.trim().split(' ').join('_');
  return space.replace(/[\(\)]/g, '');
};
export const removeDot = (word = ''): string => {
  return word.trim().split('.').join('');
};

import { createSlice } from '@reduxjs/toolkit';
import { IAccountState } from './types';
import * as reducers from './reducers';

export const accountState: IAccountState = {
  profile: null,
};

export const accountSlice = createSlice({
  name: 'profile',
  initialState: accountState,
  reducers,
});

export const { setProfile, clearProfile } = accountSlice.actions;

import { Accordion } from 'react-bootstrap';
import {
  Wrapper,
  ErrorWrapper,
  CountWrapper,
  ErrorText,
  CountBox,
  CountTitle,
  CountValue,
} from './styles';

interface Props {
  errors: Array<string>;
  uploaded: number;
  success: number;
  failed: number;
}

function ContentMessage({
  errors,
  uploaded,
  success,
  failed,
}: Props): JSX.Element {
  function renderError(): JSX.Element | null {
    if (!errors || errors.length === 0) {
      return null;
    }

    return (
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <strong className="text-danger">
              Terdapat {errors.length} error
            </strong>
          </Accordion.Header>
          <Accordion.Body style={{ overflowY: 'auto', maxHeight: 300 }}>
            <ErrorWrapper>
              {errors.map((error, index) => (
                <ErrorText key={index}>{error}</ErrorText>
              ))}
            </ErrorWrapper>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <Wrapper>
      {renderError()}
      <CountWrapper>
        <CountBox>
          <CountTitle>Uploaded</CountTitle>
          <CountValue>{uploaded}</CountValue>
        </CountBox>
        <CountBox>
          <CountTitle className="text-success">Success</CountTitle>
          <CountValue>{success}</CountValue>
        </CountBox>
        <CountBox>
          <CountTitle className="text-danger">Failed</CountTitle>
          <CountValue>{failed}</CountValue>
        </CountBox>
      </CountWrapper>
    </Wrapper>
  );
}

export default ContentMessage;

import { createSlice } from '@reduxjs/toolkit';
import { IErrorState } from './types';
import * as reducers from './reducers';

export const errorState: IErrorState = {
  errorBag: {
    error: undefined,
    callback: undefined,
  },
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: errorState,
  reducers,
});

export const { setErrorBag, clearErrorBag } = errorSlice.actions;

import { memo } from 'react';
import { OptionsType, OnButtonOptionChangeType } from 'types';
import { Button, ButtonGroup } from 'react-bootstrap';

interface Props {
  name: string;
  options: Array<OptionsType>;
  value?: OptionsType;
  onChange: OnButtonOptionChangeType;
}

function FormOptionButton({
  options,
  value,
  name,
  onChange,
}: Props): JSX.Element | null {
  if (options.length === 0) return null;

  function onClickBtn(option: OptionsType): void {
    onChange({ name, value: option });
  }

  return (
    <ButtonGroup className="d-flex border rounded" aria-label="Basic example">
      {options.map((option, index) => {
        const selected = value && option.value === value?.value;

        return (
          <Button
            onClick={(): void => onClickBtn(option)}
            key={Number(index)}
            variant={selected ? 'primary' : 'light'}
          >
            {option.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

export default memo(FormOptionButton);

import { gql } from '@apollo/client';

export const LIST_ROLE = gql`
  query getRoles($listRoleInput: ListRoleInput!) {
    listRole(listRoleInput: $listRoleInput) {
      page
      limit
      total
      totalPage
      roles {
        id
        name
        displayName
        status
        permissions
      }
    }
  }
`;

export const DETAIL_ROLE = gql`
  query getRoles($id: String!) {
    detailRole(id: $id) {
      id
      name
      displayName
      status
      permission
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation createRole($form: CreateRoleInput!) {
    createRole(createRoleInput: $form) {
      id
      name
      displayName
      status
      permissions
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($form: UpdateRoleInput!) {
    updateRole(updateRoleInput: $form) {
      id
      name
      displayName
      status
      permissions
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($id: String!) {
    deleteRole(id: $id) {
      id
      name
      displayName
      status
      permissions
    }
  }
`;

import styled from 'styled-components';
import { Modal, Button, Form } from 'react-bootstrap';
import { ErrorState } from '../index';
import { FileUpload } from 'components';
import { OnChangeFileUpload } from 'types';

type Props = {
  show: boolean;
  error: ErrorState;
  disabled?: boolean;
  onSubmit: (e: React.SyntheticEvent) => void;
  onClose: () => void;
  onChangeFile: OnChangeFileUpload;
};

const BtnWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`;

function FormUploadComponent({
  show,
  error,
  disabled,
  onClose,
  onSubmit,
  onChangeFile,
}: Props): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Tambah Banyak Merchant
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FileUpload
            name="merchantFile"
            label="Merchant File"
            error={Boolean(error.merchantFile)}
            errorMessage={error.merchantFile}
            onChange={onChangeFile}
          />
          <BtnWrapper>
            <Button disabled={disabled} type="submit">
              <strong>Tambah Merchant</strong>
            </Button>
          </BtnWrapper>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default FormUploadComponent;

import { memo } from 'react';
import { imgLoading } from 'assets';
import { Wrapper, Content, Text, Image } from './styles';

interface Props {
  show?: boolean;
}

function LoadingScreen({ show }: Props): JSX.Element {
  return (
    <Wrapper show={show}>
      <Content>
        <Image src={imgLoading} />
        <Text>Loading...</Text>
      </Content>
    </Wrapper>
  );
}

export default memo(LoadingScreen);

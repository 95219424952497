/* eslint-disable max-len */
/* eslint-disable no-useless-escape */

type RegexType = {
  email: RegExp;
  alphabetWithSpace: RegExp;
};

const regex: RegexType = {
  alphabetWithSpace: /^[a-zA-Z ]*$/,
  email:
    // eslint-disable-next-line security/detect-unsafe-regex
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
};

export { regex };

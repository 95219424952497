import {
  PERMISSIONS,
  PERMISSION_LABELS,
  STATUS,
  NUMBER_TYPES,
  NUMBER_TYPE_LABELS,
  STATUSREPORT,
} from './global';

export const roleCheckboxOptions = [
  { label: PERMISSION_LABELS.USER, value: PERMISSIONS.USER },
  { label: PERMISSION_LABELS.ROLE, value: PERMISSIONS.ROLE },
  {
    label: PERMISSION_LABELS.CHARGING_SETUP,
    value: PERMISSIONS.CHARGING_SETUP,
  },
  { label: PERMISSION_LABELS.MERCHANT_TYPE, value: PERMISSIONS.MERCHANT_TYPE },
  { label: PERMISSION_LABELS.CLEANUP_DATA, value: PERMISSIONS.CLEANUP_DATA },
  {
    label: PERMISSION_LABELS.CONSOLIDATE_DATA,
    value: PERMISSIONS.CONSOLIDATE_DATA,
  },
  {
    label: PERMISSION_LABELS.FINALIZE_SETTLEMENT,
    value: PERMISSIONS.FINALIZE_SETTLEMENT,
  },
  {
    label: PERMISSION_LABELS.GENERATE_QR,
    value: PERMISSIONS.GENERATE_QR,
  },
  {
    label: PERMISSION_LABELS.CONSOLIDATE_REPORT,
    value: PERMISSIONS.CONSOLIDATE_REPORT,
  },
  {
    label: PERMISSION_LABELS.MERGE_DATA,
    value: PERMISSIONS.MERGE_DATA,
  },
];

export const statusOptions = [
  {
    value: STATUS.ACTIVE,
    label: 'Active',
  },
  {
    value: STATUS.INACTIVE,
    label: 'Non-active',
  },
];

export const statusReportOptions = [
  {
    value: STATUSREPORT.SUCCESS,
    label: 'Success',
  },
  {
    value: STATUSREPORT.PARTIAL_SUCCESS,
    label: 'Partial Success',
  },
  {
    value: STATUSREPORT.FAILED,
    label: 'Failed',
  },
  {
    value: STATUSREPORT.SYSTEM_FAILED,
    label: 'System Failed',
  },
  {
    value: STATUSREPORT.WAITING,
    label: 'Waiting',
  },
];

export const numberTypeOptions = [
  {
    value: NUMBER_TYPES.PERCENTAGE,
    label: NUMBER_TYPE_LABELS.PERCENTAGE,
  },
  {
    value: NUMBER_TYPES.AMOUNT,
    label: NUMBER_TYPE_LABELS.AMOUNT,
  },
];

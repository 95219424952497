import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  error?: boolean;
  valid?: boolean;
  control?: boolean;
  message?: string;
  errorMessage?: string;
  validMessage?: string;
}

function HelperText({
  error,
  valid,
  control,
  message,
  errorMessage,
  validMessage,
}: Props): JSX.Element | null {
  function renderControl(): JSX.Element | null {
    if (error && errorMessage) {
      return (
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      );
    }

    if (valid && validMessage) {
      return (
        <Form.Control.Feedback type="valid">
          {validMessage}
        </Form.Control.Feedback>
      );
    }

    if (message) {
      return <Form.Text>{message}</Form.Text>;
    }

    return null;
  }

  function renderText(): JSX.Element | null {
    if (error && errorMessage) {
      return <Form.Text className="text-danger">{errorMessage}</Form.Text>;
    }

    if (valid && validMessage) {
      return <Form.Text className="text-success">{validMessage}</Form.Text>;
    }

    if (message) {
      return <Form.Text>{message}</Form.Text>;
    }

    return null;
  }

  if (control) {
    return renderControl();
  }

  return renderText();
}

export default React.memo(HelperText);

import { gql } from '@apollo/client';

export const CLOUD_STORAGE_URL = gql`
  query getCloudStorageUrl($fileNames: [String!]!) {
    getCloudStorageUrl(fileNames: $fileNames) {
      downloadUrls
      uploadUrls
    }
  }
`;

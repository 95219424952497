import { Row, Col } from 'react-bootstrap';
import { FileUpload } from 'components';
import { ISSUERS, MERCHANT_TYPES } from 'utils';
import { Props } from './interfaces';
import './style.css';
function FormFileComponent({ form, error, onChangeFile }: Props): JSX.Element {
  if (
    form.issuer?.label === ISSUERS.LINK_AJA &&
    form.merchantType?.label === MERCHANT_TYPES.MSME
  ) {
    return (
      <>
        <Row>
          <Col xs={12} lg>
            <FileUpload
              name="linkAjaMCDReportFile"
              label="LA MSME Report"
              error={Boolean(error.linkAjaMCDReportFile)}
              errorMessage={error.linkAjaMCDReportFile}
              onChange={onChangeFile}
            />
          </Col>
          <Col xs={12} lg>
            <FileUpload
              name="ytiSettlementFile"
              label="YTI Settlement"
              error={Boolean(error.ytiSettlementFile)}
              errorMessage={error.ytiSettlementFile}
              onChange={onChangeFile}
            />
          </Col>
          <Col xs={12} lg>
            <FileUpload
              name="youtapBalanceFile"
              label="Youtap Balance"
              error={Boolean(error.youtapBalanceFile)}
              errorMessage={error.youtapBalanceFile}
              onChange={onChangeFile}
            />
          </Col>
          <Col xs={12} lg>
            <FileUpload
              name="phpTransactionFile"
              label="PHP Transaction"
              error={Boolean(error.phpTransactionFile)}
              errorMessage={error.phpTransactionFile}
              onChange={onChangeFile}
            />
          </Col>
        </Row>
        <Row className="row">
          <Col lg>
            <FileUpload
              name="opsSettlementFile"
              label="File Manual Operation"
              description="Optional"
              error={Boolean(error.opsSettlementFile)}
              errorMessage={error.opsSettlementFile}
              onChange={onChangeFile}
            />
          </Col>
        </Row>
      </>
    );
  }

  if (
    form.issuer?.label === ISSUERS.LINK_AJA &&
    form.merchantType?.label === MERCHANT_TYPES.MCD
  ) {
    return (
      <Row>
        {/* <Col xs={12} lg>
          <FileUpload
            name="linkAjaMcdPortalSettlementFile"
            label="LA Portal Settlement"
            error={Boolean(error.linkAjaMcdPortalSettlementFile)}
            errorMessage={error.linkAjaMcdPortalSettlementFile}
            onChange={onChangeFile}
          />
        </Col> */}
        <Col xs={12} lg>
          <FileUpload
            name="ytiSettlementFile"
            label="YTI Settlement"
            error={Boolean(error.ytiSettlementFile)}
            errorMessage={error.ytiSettlementFile}
            onChange={onChangeFile}
          />
        </Col>
        <Col xs={12} lg>
          <FileUpload
            name="ytiMcdSettlementFile"
            label="YTI MCD Settlement"
            error={Boolean(error.ytiMcdSettlementFile)}
            errorMessage={error.ytiMcdSettlementFile}
            onChange={onChangeFile}
          />
        </Col>
        <Col xs={12} lg>
          <FileUpload
            name="linkAjaMCDReportFile"
            label="LA MCD Report"
            error={Boolean(error.linkAjaMCDReportFile)}
            errorMessage={error.linkAjaMCDReportFile}
            onChange={onChangeFile}
          />
        </Col>
      </Row>
    );
  }

  if (
    form.issuer?.label === ISSUERS.OVO &&
    form.merchantType?.label === MERCHANT_TYPES.MCD
  ) {
    return (
      <>
        <Row>
          <Col xs={12} lg>
            <FileUpload
              name="ovoSettlementFile"
              label="Ovo Settlement"
              error={Boolean(error.ovoSettlementFile)}
              errorMessage={error.ovoSettlementFile}
              onChange={onChangeFile}
            />
          </Col>
          <Col xs={12} lg>
            <FileUpload
              name="ytiMcdSettlementFile"
              label="MCD Settlement"
              error={Boolean(error.ytiMcdSettlementFile)}
              errorMessage={error.ytiMcdSettlementFile}
              onChange={onChangeFile}
            />
          </Col>
        </Row>
      </>
    );
  }

  if (
    form.issuer?.label === ISSUERS.LINK_AJA &&
    form.merchantType?.label === MERCHANT_TYPES.EXPANSION
  ) {
    return (
      <>
        <Row>
          <Col xs={12} lg>
            <FileUpload
              name="ytiSettlementFile"
              label="YTI Settlement"
              error={Boolean(error.ytiSettlementFile)}
              errorMessage={error.ytiSettlementFile}
              onChange={onChangeFile}
            />
          </Col>
          <Col xs={12} lg>
            <FileUpload
              name="dataExpansionFile"
              label="Data Expansion"
              error={Boolean(error.dataExpansionFile)}
              errorMessage={error.dataExpansionFile}
              onChange={onChangeFile}
            />
          </Col>
        </Row>
      </>
    );
  }

  if (
    form.issuer?.label === ISSUERS.SHOPEE &&
    form.merchantType?.label === MERCHANT_TYPES.MCD
  ) {
    return (
      <Row>
        <Col xs={12} lg>
          <FileUpload
            name="issuerFile"
            label="File from Issuer"
            error={Boolean(error.issuerFile)}
            errorMessage={error.issuerFile}
            onChange={onChangeFile}
          />
        </Col>
        <Col xs={12} lg>
          <FileUpload
            name="ytiMcdSettlementFile"
            label="YTI MCD Settlement Report"
            error={Boolean(error.ytiMcdSettlementFile)}
            errorMessage={error.ytiMcdSettlementFile}
            onChange={onChangeFile}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col xs={12} lg>
        <FileUpload
          name="issuerFile"
          label="File from Issuer"
          error={Boolean(error.issuerFile)}
          errorMessage={error.issuerFile}
          onChange={onChangeFile}
        />
      </Col>
      <Col xs={12} lg>
        <FileUpload
          name="ytiSettlementFile"
          label="YTI Settlement Report"
          error={Boolean(error.ytiSettlementFile)}
          errorMessage={error.ytiSettlementFile}
          onChange={onChangeFile}
        />
      </Col>
    </Row>
  );
}

export default FormFileComponent;

import { regex } from './regex';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MinMaxType = string | Array<any> | number;

type ValidatorType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isEmpty: (value: any) => boolean;
  isEmail: (email: string) => boolean;
  isAlphabetWithSpaceOnly: (value: string) => boolean;
  min: (value: MinMaxType, range: number) => boolean;
  max: (value: MinMaxType, range: number) => boolean;
};

const validator: ValidatorType = {
  isEmpty: function (value) {
    if (!value) return true;
    if (value && typeof value.length === 'undefined') {
      if (Object.keys(value).length === 0) {
        return true;
      }
    }

    if (
      value &&
      typeof value !== 'string' &&
      typeof value.length === 'number'
    ) {
      if (value.length === 0) {
        return true;
      }
    }

    return false;
  },
  isEmail: function (email) {
    if (typeof email !== 'string') return false;
    const string = email.toLowerCase();
    return regex.email.test(string);
  },
  min: function (value, range) {
    if (typeof value === 'string') {
      if (value.length < range) {
        return false;
      }
    }

    if (typeof value === 'number') {
      if (value < range) {
        return false;
      }
    }

    if (typeof value === 'object' && typeof value.length !== 'undefined') {
      if (Array(value).length < range) {
        return false;
      }
    }
    return true;
  },
  max: function (value, range) {
    if (typeof value === 'string') {
      if (String(value).length > range) {
        return false;
      }
    }

    if (typeof value === 'number') {
      if (value > range) {
        return false;
      }
    }

    if (typeof value === 'object' && typeof value.length !== 'undefined') {
      if (Array(value).length > range) {
        return false;
      }
    }

    return true;
  },
  isAlphabetWithSpaceOnly: function (str) {
    if (regex.alphabetWithSpace.test(str)) return true;
    return false;
  },
};

export { validator };

import { memo, useState } from 'react';
import { TablePagination } from 'components';
import { Table, Button } from 'react-bootstrap';
import { IMerchant, IChargingSetup } from 'types';
import {
  getTableNumber,
  getNumberTypeText,
  getTransactionTypeLabel,
} from 'utils';
import { Td, Th } from './styles';

interface Props {
  loading?: boolean;
  onEdit: (data: IMerchant) => void;
  onDelete: (data: IMerchant) => void;
  data: Array<IMerchant>;
  page: number;
  limit: number;
  totalPage: number;
  onChangePage: React.MouseEventHandler;
}

function TableComponent({
  data,
  onEdit,
  onDelete,
  loading,
  page,
  limit,
  totalPage,
  onChangePage,
}: Props): JSX.Element {
  const [expanded, setExpanded] = useState<string>('');

  function onClickDetail(id: string): void {
    setExpanded(expanded !== id ? id : '');
  }

  function renderTableDetailBody(
    chargingSetups?: Array<IChargingSetup>,
  ): JSX.Element {
    if (!chargingSetups || chargingSetups.length === 0) {
      return (
        <tr>
          <Td colSpan={12} className="text-center">
            Tidak ada charging setup
          </Td>
        </tr>
      );
    }

    return (
      <>
        {chargingSetups.map((chargingSetup, index) => {
          return (
            <tr key={index}>
              <Td>
                {getTransactionTypeLabel(chargingSetup.transactionType, '-')}
              </Td>
              <Td>{chargingSetup.acquirer?.name || '-'}</Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.mdr,
                  chargingSetup.mdrType,
                  '-',
                )}
              </Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.mdrOnUs,
                  chargingSetup.mdrOnUsType,
                  '-',
                )}
              </Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.mdrOffUs,
                  chargingSetup.mdrOffUsType,
                  '-',
                )}
              </Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.charging,
                  chargingSetup.chargingType,
                  '-',
                )}
              </Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.chargingOnUs,
                  chargingSetup.chargingOnUsType,
                  '-',
                )}
              </Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.chargingOffUs,
                  chargingSetup.chargingOffUsType,
                  '-',
                )}
              </Td>
              <Td>
                {getNumberTypeText(
                  chargingSetup.serviceFee,
                  chargingSetup.serviceFeeType,
                  '-',
                )}
              </Td>
            </tr>
          );
        })}
      </>
    );
  }

  function renderTableBody(): JSX.Element {
    if (loading) {
      return (
        <tr>
          <td colSpan={8} className="text-center">
            Loading...
          </td>
        </tr>
      );
    }

    if (data.length > 0) {
      return (
        <>
          {data.map(function (item, index) {
            return (
              <>
                <tr key={item.id}>
                  <Td>{getTableNumber(index, page, limit)}</Td>
                  <Td>{item.name}</Td>
                  <Td>{item.mid}</Td>
                  <Td>{item.merchantType?.name || '-'}</Td>
                  <Td>{item.address || '-'}</Td>
                  <Td>{item.ownerName || '-'}</Td>
                  <Td>{`${item.bankName}: ${item.bankAccount}` || '-'}</Td>
                  <Td style={{ textAlign: 'right' }}>
                    <Button
                      onClick={function (): void {
                        onEdit(item);
                      }}
                      size="sm"
                      style={{ fontSize: '11px' }}
                    >
                      Edit
                    </Button>{' '}
                    <Button
                      variant="danger"
                      onClick={function (): void {
                        onDelete(item);
                      }}
                      size="sm"
                      style={{ fontSize: '11px' }}
                    >
                      Delete
                    </Button>{' '}
                    <Button
                      variant="secondary"
                      onClick={(): void => onClickDetail(item.id as string)}
                      size="sm"
                      style={{ fontSize: '11px' }}
                    >
                      Detail
                    </Button>
                  </Td>
                </tr>
                {expanded === item.id && (
                  <tr>
                    <td colSpan={8} style={{ backgroundColor: '#fefefe' }}>
                      <Table responsive bordered>
                        <thead>
                          <tr>
                            <Th>Tipe</Th>
                            <Th>Acquiring Bank</Th>
                            <Th>MDR</Th>
                            <Th>MDR on us</Th>
                            <Th>MDR off us</Th>
                            <Th>Charging</Th>
                            <Th>Charging on us</Th>
                            <Th>Charging off us</Th>
                            <Th>Service Fee</Th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderTableDetailBody(item.chargingSetups)}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        </>
      );
    }

    return (
      <tr>
        <td colSpan={8} className="text-center">
          Tidak ada data
        </td>
      </tr>
    );
  }

  return (
    <>
      <Table responsive bordered>
        <thead>
          <tr>
            <Th style={{ minWidth: 50 }}>No</Th>
            <Th style={{ minWidth: 150 }}>Nama Merchant</Th>
            <Th style={{ minWidth: 150 }}>MID</Th>
            <Th style={{ minWidth: 100 }}>Merchant Type</Th>
            <Th style={{ minWidth: 200 }}>Alamat</Th>
            <Th style={{ minWidth: 150 }}>Pemilik</Th>
            <Th style={{ minWidth: 150 }}>Bank</Th>
            <Th style={{ minWidth: 180 }}>Action</Th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
      <TablePagination
        limit={limit}
        last={totalPage}
        current={page}
        onClick={onChangePage}
      />
    </>
  );
}

export default memo(TableComponent);

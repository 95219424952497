import { Form, Row, Col, Button } from 'react-bootstrap';

interface Props {
  onAdd: () => void;
  addTitle: string;
  onSubmit: (e: React.SyntheticEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  value: string;
}

function ActionHeader({
  value,
  onAdd,
  addTitle,
  onSubmit,
  onChange,
  searchPlaceholder,
}: Props): JSX.Element {
  return (
    <Form onSubmit={onSubmit}>
      <Row className="no-gutters">
        <Col sm={6} className="p-0">
          <Form.Control
            onChange={onChange}
            placeholder={searchPlaceholder || 'Search...'}
            value={value}
          />
        </Col>
        <Col sm={6}>
          <Button onClick={onAdd} className="d-block w-100">
            {addTitle || 'Tambah'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default ActionHeader;

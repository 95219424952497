import styled from 'styled-components';

export const IconWrapper = styled.div`
  text-align: center;
  padding-bottom: 16px;
`;

export const ContentWrapper = styled.div`
  padding: 24px;
`;

export const Icon = styled.img.attrs({
  alt: '',
  title: '',
})`
  width: 100px;
  height: 100px;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-bottom: 16px;
`;

export const Message = styled.div`
  text-align: center;
  padding-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
`;

import { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Card, Button, Form } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormInput, PageInfo } from 'components';
import { Wrapper, Content } from './styles';
import { useForm } from 'hooks';
import { validator, isAuthenticated } from 'utils';
import { login, getProfile } from 'services';
import { errorBag } from 'controls';

interface FormState {
  email: string;
  password: string;
  recaptha: string;
}

interface ErrorState {
  email: string;
  password: string;
  recaptha: string;
}

const initialFormState: FormState = {
  email: '',
  password: '',
  recaptha: '',
};

const initialErrorState: ErrorState = {
  email: '',
  password: '',
  recaptha: '',
};

const Style = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
`;

function Login(): JSX.Element | null {
  const navigate = useNavigate();
  const [form, setForm] = useForm(initialFormState);
  const [error, setError] = useState(initialErrorState);
  const [loading, setLoading] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  function isDisabledButton(): boolean {
    if (loading) {
      return true;
    }

    if (form.email && form.password) {
      return false;
    }

    return true;
  }

  function onChangeFormInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setForm(name, value);
    const field = name as keyof typeof error;
    // eslint-disable-next-line security/detect-object-injection
    if (error[field] !== '') {
      setError((prev) => ({ ...prev, [name]: '' }));
    }
  }

  function validateForm(): [boolean, ErrorState] {
    const newError = { ...error };

    if (!form.email) {
      newError.email = 'Mohon masukan email';
    } else if (!validator.isEmail(form.email)) {
      newError.email = 'Format email tidak valid';
    }

    if (!form.password) {
      newError.password = 'Masukan password';
    } else if (!validator.min(form.password, 8)) {
      newError.password = 'Password minimal 8 karakter';
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  async function onSubmitForm(e: React.SyntheticEvent): Promise<void> {
    try {
      e?.preventDefault();
      const [valid, newError] = validateForm();
      if (!valid) {
        setError(newError);
        return;
      }

      if (!executeRecaptcha) {
        throw new Error('Validasi recaptha tidak tersedia');
      }

      setLoading(true);

      const token = await executeRecaptcha('login_recaptha');

      await login({
        email: form.email,
        password: form.password,
        humanKey: token,
      });
      await getProfile();
      setLoading(false);
      navigate('/', { replace: true });
    } catch (err) {
      setLoading(false);
      errorBag(err);
    }
  }

  if (isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  return (
    <Wrapper>
      <PageInfo title="Login" description="Login Reconcile application" />
      <Style />
      <Content>
        <Card className="w-100 shadow">
          <Card.Body>
            <Form autoComplete="off" onSubmit={onSubmitForm}>
              <FormInput
                type="email"
                name="email"
                label="Email"
                placeholder="Enter email"
                value={form.email}
                disabled={loading}
                onChange={onChangeFormInput}
                error={Boolean(error.email)}
                errorMessage={error.email}
              />
              <FormInput
                type="password"
                name="password"
                label="Password"
                placeholder="Enter password"
                value={form.password}
                disabled={loading}
                onChange={onChangeFormInput}
                error={Boolean(error.password)}
                errorMessage={error.password}
              />
              <div className="mb-3"></div>
              <Button
                disabled={isDisabledButton()}
                variant="primary"
                type="submit"
              >
                {loading ? 'Loading...' : 'Login'}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Content>
    </Wrapper>
  );
}

export default Login;

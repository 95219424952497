import {
  store,
  setPopupMessage,
  clearPopupMessage,
  setFloatingUser,
  setPopupConfirmation,
  clearPopupConfirmation,
  setFormChangePassword,
} from 'modules';
import {
  IPopupMessageState as Options,
  IPopupConfirmationState as ConfirmationOptions,
} from 'modules/redux/popup/types';

export function popupMessage({
  show,
  type,
  title,
  message,
  onClose,
}: Options): void {
  if (!show) {
    store.dispatch(clearPopupMessage());
  } else {
    store.dispatch(
      setPopupMessage({
        show,
        title,
        type,
        message,
        onClose,
      }),
    );
  }
}

export function popupSystemError(): void {
  popupMessage({
    show: true,
    type: 'warning',
    title: 'Maaf sedang terjadi gangguan!',
    message:
      'Mohon maaf, saat ini sistem mengalami gangguan. Mohon refresh kembali halaman Kamu atau hubungi team teknis internal.',
  });
}

export function popupGraphQLError(message: string): void {
  popupMessage({
    show: true,
    type: 'warning',
    title: 'Error',
    message,
  });
}

export function popupExpiredSession(): void {
  popupMessage({
    show: true,
    type: 'warning',
    title: 'Sesi Berakhir',
    message:
      'Mohon maaf, sesi kamu sudah berakhir. silahkan login kembali untuk masuk aplikasi',
  });
}

export function floatingUser(show: boolean): void {
  store.dispatch(setFloatingUser(show));
}

export function confirmation({
  show = true,
  ...rest
}: ConfirmationOptions): void {
  if (!show) {
    store.dispatch(clearPopupConfirmation());
  } else {
    store.dispatch(setPopupConfirmation({ ...rest, show }));
  }
}

export function formChangePassword(show: boolean): void {
  store.dispatch(setFormChangePassword(show));
}

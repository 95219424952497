import { FormInput } from 'components';
import { listMerchantTypeSelector, listIssuerSelector } from 'modules';
import { IGetMerchantTypesResponse } from 'services/merchantType/interfaces';
import { IGetIssuersResponse } from 'services/issuer/interface';
import { getMerchantTypes, getIssuers } from 'services';
import { useSelector, shallowEqual } from 'react-redux';
import { mapMerchantTypesToOptions, mapIssuersToOptions } from 'utils';
import { OptionsType } from 'types';
import { debounce } from 'ts-debounce';
import { Props } from './interfaces';

function FormComponent({
  form,
  error,
  onSelectChange,
  disabled,
  onChange,
}: Props): JSX.Element {
  const { data: merchantTypes } = useSelector(
    listMerchantTypeSelector,
    shallowEqual,
  );

  const { data: issuers } = useSelector(listIssuerSelector, shallowEqual);

  const merchanTypeOptions = mapMerchantTypesToOptions(merchantTypes);
  const issuerOptions = mapIssuersToOptions(issuers);

  async function loadMerchantTypeOptions(
    inputValue: string,
  ): Promise<Array<OptionsType>> {
    const asyncF = async (): Promise<IGetMerchantTypesResponse> =>
      await getMerchantTypes(
        {
          search: { keyword: inputValue },
          page: 0,
          limit: 25,
          order: { sortBy: 'ASC', orderBy: 'NAME' },
        },
        false,
      );

    const g = debounce(asyncF, 500);
    const res = await g();
    return mapMerchantTypesToOptions(res.data);
  }

  async function loadIssuerOptions(
    inputValue: string,
  ): Promise<Array<OptionsType>> {
    const asyncF = async (): Promise<IGetIssuersResponse> =>
      await getIssuers(
        {
          search: { keyword: inputValue },
          page: 0,
          limit: 25,
          order: { sortBy: 'ASC', orderBy: 'NAME' },
        },
        false,
      );

    const g = debounce(asyncF, 500);
    const res = await g();
    return mapIssuersToOptions(res.data);
  }

  return (
    <>
      <FormInput
        type="select"
        name="merchantType"
        label="Recon Merchant Type"
        placeholder="Pilih Type"
        value={form.merchantType?.value}
        onSelectChange={onSelectChange}
        loadOptions={loadMerchantTypeOptions}
        error={Boolean(error.merchantType)}
        errorMessage={error.merchantType}
        async
        horizontal
        options={merchanTypeOptions}
        disabled={disabled}
        horizontalSize={{
          label: 4,
          input: 8,
        }}
      />
      <FormInput
        type="date"
        name="date"
        label="Recon Date"
        placeholder="Masukan Tanggal"
        value={form.date}
        onChange={onChange}
        error={Boolean(error.date)}
        errorMessage={error.date}
        horizontal
        disabled={disabled}
        horizontalSize={{
          label: 4,
          input: 8,
        }}
      />
      <FormInput
        type="select"
        name="issuer"
        label="Issuer Company"
        placeholder="Pilih issuer"
        value={form.issuer?.value}
        async
        options={issuerOptions}
        onSelectChange={onSelectChange}
        loadOptions={loadIssuerOptions}
        error={Boolean(error.issuer)}
        errorMessage={error.issuer}
        horizontal
        disabled={disabled}
        horizontalSize={{
          label: 4,
          input: 8,
        }}
      />
    </>
  );
}

export default FormComponent;

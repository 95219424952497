export enum MESSAGES {
  TOKEN_EXPIRED = 'Anda tidak berhak mengakses halaman ini.',
  NO_RETURN_DATA = 'Server tidak mengembalikan data',
  FILE_NOT_MANDIRI_SETTLEMENT = 'File bukan Mandiri Settlement',
  FILE_NOT_KREDIVO_SETTLEMENT = 'File bukan Kredivo Settlement',
  FILE_NOT_SHOPEE_SETTLEMENT = 'File bukan Shopee Settlement',
  FILE_NOT_LINKAJA_SETTLEMENT = 'File bukan LinkAja Settlement',
  FILE_NOT_YTI_SETTLEMENT = 'File bukan YTI Settlement',
  FILE_NOT_LINKAJA_MCD_REPORT = 'File bukan LinkAja MCD Report File',
  FILE_NOT_LINKAJA_MSME_REPORT = 'File bukan LinkAja MSME Report File',
  FILE_NOT_BALANCE_REPORT = 'File bukan Youtap Balance Report File',
  FILE_NOT_PHP_TRANSACTION = 'File bukan PHP Transaction File',
  FILE_NOT_MCD_SETTLEMENT = 'File bukan MCD Settlement File',
  FILE_NOT_OPERATIONAL_SETTLEMENT = 'File bukan Manual Operation File',
  FILE_WRONG = 'Template File salah',
  FILE_NOT_LINKAJA_DATAEXPANSION = 'File bukan LinkAja Data Expansion Report File',
  FILE_NOT_OVO_SETTLEMENT = 'File bukan OVO Settlement File',
}

import styled from 'styled-components';
import { COLORS } from 'utils';
import { imgUpload, imgDoc, imgDocError } from 'assets';

export const Wrapper = styled.div`
  border-radius: 16px;
  background-color: ${COLORS.gray};
  text-align: center;
  padding: 16px;
`;

export const Label = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: ${COLORS.blue};
`;
export const Subtitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: ${COLORS.gray3};
`;

export const ImageWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const ImageErrorWrapper = styled.div`
  display: flex;
`;

export const ImageErrorOuter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const ErrorTextWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorFileName = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: block;
`;

export const ImageUpload = styled.img.attrs({
  alt: '',
  src: imgUpload,
})`
  width: 70px;
  height: 50px;
  margin-bottom: 8px;
`;

export const ImageLabel = styled.span`
  display: block;
  opacity: 0.4;
`;

export const ImageUploadSuccess = styled.img.attrs({
  alt: '',
  src: imgDoc,
})`
  width: 80px;
  margin-bottom: 8px;
`;

export const ImageUploadFail = styled.img.attrs({
  alt: '',
  src: imgDocError,
})`
  width: 80px;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const FileName = styled.span`
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 16px;
`;

export const FileSize = styled.span`
  opacity: 0.4;
`;

export const ErrorText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${COLORS.red};
  margin-bottom: 8px;
`;

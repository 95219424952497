import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { MainContent, ActionHeader, PageInfo } from 'components';
import { useForm, useDebounce } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import {
  getMerchantTypes,
  deleteMerchantType,
  createMerchantType,
  updateMerchantType,
} from 'services';
import { IMerchantType, GlobalStatusType, OptionsType } from 'types';
import { listMerchantTypeSelector, profileSelector } from 'modules';
import Form from './Form';
import Table from './Table';
import { ActionMeta, SingleValue } from 'react-select';
import { isAuthenticated, PERMISSION_LABELS } from 'utils';
import { popupMessage, errorBag, loadingScreen, confirmation } from 'controls';

export type FormState = {
  id: string;
  name: string;
  status?: GlobalStatusType | string;
};

export type ErrorState = {
  name: string;
  status: string;
};

const initialFormState: FormState = {
  id: '',
  name: '',
  status: 'ACTIVE',
};

const initialErrorState: ErrorState = {
  name: '',
  status: '',
};

function MerchantType(): JSX.Element {
  // state
  const profile = useSelector(profileSelector, shallowEqual);
  const list = useSelector(listMerchantTypeSelector, shallowEqual);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formComponent, setFormComponent] = useState<boolean>(false);
  const [form, setForm] = useForm(initialFormState);
  const [error, setError] = useState(initialErrorState);

  const [searchParams, setSearchParam] = useSearchParams();
  const defaultLimit = 10;
  const page = searchParams.get('page') || 0;
  const limit = searchParams.get('limit') || defaultLimit;
  const search = searchParams.get('search') || '';

  const debouncedSearchQuery = useDebounce(search, 500);

  const items = list.data || [];
  const isLogin = isAuthenticated();

  // variable
  const isEdit = Boolean(form.id);

  function onChangePage(e: React.MouseEvent<HTMLAnchorElement>): void {
    const dataPage = e.currentTarget.getAttribute('data-page');
    const pageNumber = Number(dataPage);
    const urlParams = new URLSearchParams();
    urlParams.set('page', String(pageNumber));

    if (limit) {
      urlParams.set('limit', String(limit));
    }
    setSearchParam(urlParams);
  }

  function onChangeFormInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setForm(name, value);
    const field = name as keyof typeof error;

    // eslint-disable-next-line security/detect-object-injection
    if (error[field] !== '') {
      setError((prev) => ({ ...prev, [name]: '' }));
    }
  }

  function onCloseForm(): void {
    setFormComponent(false);
    setForm('reset', null);
    setError(initialErrorState);
  }

  function onAdd(): void {
    setFormComponent(true);
  }

  function onEdit(data: IMerchantType): void {
    setForm(
      {
        id: data.id,
        name: data.name,
        status: data?.status?.toUpperCase(),
      },
      null,
    );
    setFormComponent(true);
  }

  function confirmDelete(data: IMerchantType): void {
    loadingScreen(true);
    deleteMerchantType(data.id)
      .then(function () {
        loadingScreen(false);
        popupMessage({
          show: true,
          type: 'success',
          title: 'Berhasil Dihapus',
          message: 'Hi, Merchant Type berhasil dihapus',
        });
        getMerchantTypes({
          page: 0,
          limit: 25,
        });
      })
      .catch(function (err) {
        errorBag(err);
      });
  }

  function onDelete(data: IMerchantType): void {
    confirmation({
      title: 'Mau Hapus?',
      onConfirmed: () => confirmDelete(data),
    });
  }

  function validateForm(): [boolean, ErrorState] {
    const newError = { ...error };

    if (!form.name) {
      newError.name = 'Mohon masukan nama merchant type';
    }

    if (!form.status) {
      newError.status = 'Mohon pilih status';
    }

    const invalid = Object.values(newError).some((e) => e !== '');
    return [!invalid, newError];
  }

  async function onSubmit(e: React.SyntheticEvent): Promise<void> {
    e?.preventDefault();

    const [valid, newError] = validateForm();
    if (!valid) {
      setError(newError);
      return;
    }

    try {
      setLoadingForm(true);
      if (isEdit) {
        await updateMerchantType({
          id: form.id,
          name: form.name,
          status: form.status,
        });
      } else {
        await createMerchantType({ name: form.name, status: form.status });
      }
      setLoadingForm(false);
      onCloseForm();

      popupMessage({
        show: true,
        type: 'success',
        title: isEdit ? 'Berhasil Diubah' : 'Berhasil Dibuat',
        message: isEdit
          ? 'Hi, Data User berhasil diubah'
          : 'Hi, User baru berhasil dibuat',
      });

      getMerchantTypes({
        page: 0,
        limit: 25,
      });
    } catch (err) {
      setLoadingForm(false);
      errorBag(err);
    }
  }

  function onSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target;
    const urlParams = new URLSearchParams();

    if (value) {
      urlParams.set('search', value);
    } else {
      urlParams.delete('search');
    }
    urlParams.set('page', String(0));
    if (limit) {
      urlParams.set('limit', String(defaultLimit));
    }
    setSearchParam(urlParams);
  }

  function onSubmitSearch(e: React.SyntheticEvent): void {
    e?.preventDefault();
  }

  function onChangeSelectInput(
    selectValue: SingleValue<OptionsType>,
    meta: ActionMeta<OptionsType>,
  ): void {
    const value = selectValue?.value;
    setForm(meta.name, value);
    const field = meta.name as keyof typeof error;

    // eslint-disable-next-line security/detect-object-injection
    if (error[field] !== '') {
      setError((prev) => ({ ...prev, [field]: '' }));
    }
  }

  useEffect(
    function () {
      if (profile && isLogin) {
        getMerchantTypes({
          search: {
            keyword: search,
          },
          page: 0,
          limit: 25,
        }).catch(function (err) {
          errorBag(err);
        });
      }
    },
    [profile, isLogin, debouncedSearchQuery],
  );

  return (
    <>
      <PageInfo title={PERMISSION_LABELS.MERCHANT_TYPE} />
      <MainContent
        title={PERMISSION_LABELS.MERCHANT_TYPE}
        headerRightElement={
          <ActionHeader
            addTitle="Tambah Type"
            searchPlaceholder="Search..."
            onAdd={onAdd}
            onChange={onSearch}
            onSubmit={onSubmitSearch}
            value={search}
          />
        }
      >
        <Table
          limit={Number(list.limit) || Number(limit)}
          page={Number(list.page) || Number(page)}
          totalPage={Number(list.total)}
          onChangePage={onChangePage}
          data={items}
          loading={list.loading}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </MainContent>
      <Form
        edit={isEdit}
        show={formComponent}
        form={form}
        error={error}
        disabled={loadingForm}
        onSubmit={onSubmit}
        onChange={onChangeFormInput}
        onSelectChange={onChangeSelectInput}
        onClose={onCloseForm}
      />
    </>
  );
}

export default MerchantType;

import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
export const HeaderContent = styled.div``;
export const Title = styled.span`
  font-size: 28px;
  font-weight: 500;
`;

export const Body = styled.div``;

export const Footer = styled.div``;

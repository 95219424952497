import { memo, Fragment } from 'react';
import { Pagination } from 'react-bootstrap';
import { paginate } from 'utils';

interface Props {
  first?: number;
  limit: number;
  last: number;
  current: number;
  onClick?: React.MouseEventHandler | undefined;
}

const TablePagination = ({
  first = 0,
  limit,
  last,
  current,
  onClick,
}: Props): JSX.Element | null => {
  const disablePrev = current <= 0;
  const items = Array.from({ length: last }, (_, i) => i);
  const disabledPagination = !items || items.length === 0;

  const pagination = paginate(items.length, current, limit, 5);
  const disabledNext = current >= pagination.totalPages - 1;

  if (disabledPagination) return null;

  return (
    <Pagination className="class-pagination">
      <Pagination.First
        data-page={first}
        disabled={disablePrev}
        onClick={!disablePrev ? onClick : undefined}
      />
      <Pagination.Prev
        data-page={current - 1}
        disabled={disablePrev}
        onClick={!disablePrev ? onClick : undefined}
      />
      {pagination.pages.map((page) => {
        const activePage = current === page;

        if (page === pagination.totalPages) {
          return null;
        }

        return (
          <Fragment key={page}>
            <Pagination.Item
              data-page={page}
              active={activePage}
              onClick={!activePage ? onClick : undefined}
            >
              {page + 1}
            </Pagination.Item>
          </Fragment>
        );
      })}
      {current !== pagination.totalPages - 1 &&
        !pagination.pages.includes(pagination.totalPages - 1) && (
          <>
            <Pagination.Item disabled>...</Pagination.Item>
            <Pagination.Item
              data-page={pagination.totalPages - 1}
              active={current === pagination.totalPages}
              onClick={current !== pagination.totalPages ? onClick : undefined}
            >
              {pagination.totalPages}
            </Pagination.Item>
          </>
        )}
      <Pagination.Next
        data-page={current + 1}
        disabled={disabledNext}
        onClick={!disabledNext ? onClick : undefined}
      />
      <Pagination.Last
        data-page={pagination.totalPages - 1}
        disabled={disabledNext}
        onClick={!disabledNext ? onClick : undefined}
      />
    </Pagination>
  );
};

export default memo(TablePagination);

import { PayloadAction } from '@reduxjs/toolkit';
import { IErrorState, IErrorBagState } from './types';
import { errorState as initialState } from './states';

export function setErrorBag(
  state: IErrorState,
  action: PayloadAction<IErrorBagState>,
): void {
  state.errorBag = {
    ...state.errorBag,
    ...action.payload,
  };
}

export function clearErrorBag(state: IErrorState): void {
  state.errorBag = initialState.errorBag;
}

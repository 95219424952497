import { apoloClient, cloudStorageGql } from 'modules';
import { ICloudStorage } from 'types';
import { MESSAGES } from 'utils';
import { GetCloudStorageInput, GetCloudStorageResponse } from './interfaces';

export async function getCloudStorage(
  fileNames: string,
): Promise<ICloudStorage> {
  const response = await apoloClient.query<
    GetCloudStorageResponse,
    GetCloudStorageInput
  >({
    query: cloudStorageGql.CLOUD_STORAGE_URL,
    variables: {
      fileNames,
    },
  });

  if (!response.data?.getCloudStorageUrl) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.getCloudStorageUrl;
}

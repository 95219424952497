import {
  IPopupMessageState,
  IFloatingUserState,
  IPopupConfirmationState,
  IFormChangePasswordState,
} from './types';
import { RootState } from '../index';

export function popupMessageSelector(state: RootState): IPopupMessageState {
  return state.popup.popupMessage;
}

export function floatingUserSelector(state: RootState): IFloatingUserState {
  return state.popup.floatingUser;
}

export function popupConfirmationSelector(
  state: RootState,
): IPopupConfirmationState {
  return state.popup.popupConfirmation;
}

export function formChangePasswordSelector(
  state: RootState,
): IFormChangePasswordState {
  return state.popup.formChangePassword;
}

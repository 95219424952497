import { apoloClient, store, setMerchantTypes, merchantTypeGql } from 'modules';
import { IMerchantType } from 'types';
import {
  IGetMerchantTypesRequest,
  IGetMerchantTypesResponse,
  IListMerchantTypeQuery,
  IListMerchantTypeQueryVariables,
  IDetailMerchantTypeQuery,
  IDetailMerchantTypeQueryVariables,
  ICreateMerchantTypeRequest,
  ICreateMerchantTypeMutate,
  ICreateMerchantTypeMutateVariables,
  IUpdateMerchantTypeRequest,
  IUpdateMerchantTypeMutate,
  IUpdateMerchantTypeMutateVariables,
  IDeleteMerchantTypeMutate,
  IDeleteMerchantTypeMutateVariables,
} from './interfaces';
import { MESSAGES } from 'utils';

export async function getMerchantTypes(
  input: IGetMerchantTypesRequest,
  storeToRedux = true,
): Promise<IGetMerchantTypesResponse> {
  try {
    if (storeToRedux) {
      store.dispatch(setMerchantTypes({ loading: true }));
    }
    const response = await apoloClient.query<
      IListMerchantTypeQuery,
      IListMerchantTypeQueryVariables
    >({
      query: merchantTypeGql.LIST_MERCHANT_TYPE,
      variables: {
        input,
      },
    });

    const {
      page,
      limit,
      total,
      totalPage,
      merchantTypes: data,
    } = response.data.listMerchantType;

    if (storeToRedux) {
      store.dispatch(
        setMerchantTypes({
          page,
          limit,
          total,
          totalPage,
          data,
          loading: false,
          error: false,
        }),
      );
    }

    return {
      page,
      limit,
      total,
      totalPage,
      data,
    };
  } catch (err) {
    if (storeToRedux) {
      store.dispatch(setMerchantTypes({ loading: false, error: true }));
    }

    throw err;
  }
}

export async function getMerchantType(id: string): Promise<IMerchantType> {
  const response = await apoloClient.query<
    IDetailMerchantTypeQuery,
    IDetailMerchantTypeQueryVariables
  >({
    query: merchantTypeGql.DETAIL_MERCHANT_TYPE,
    variables: {
      id,
    },
  });

  return response.data.detailMerchantType;
}

export async function createMerchantType(
  form: ICreateMerchantTypeRequest,
): Promise<IMerchantType> {
  const response = await apoloClient.mutate<
    ICreateMerchantTypeMutate,
    ICreateMerchantTypeMutateVariables
  >({
    mutation: merchantTypeGql.CREATE_MERCHANT_TYPE,
    variables: {
      form,
    },
  });

  if (!response.data?.createMerchantType) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data?.createMerchantType;
}

export async function updateMerchantType(
  form: IUpdateMerchantTypeRequest,
): Promise<IMerchantType> {
  const response = await apoloClient.mutate<
    IUpdateMerchantTypeMutate,
    IUpdateMerchantTypeMutateVariables
  >({
    mutation: merchantTypeGql.UPDATE_MERCHANT_TYPE,
    variables: {
      form,
    },
  });

  if (!response.data?.updateMerchantType) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.updateMerchantType;
}

export async function deleteMerchantType(id: string): Promise<IMerchantType> {
  const response = await apoloClient.mutate<
    IDeleteMerchantTypeMutate,
    IDeleteMerchantTypeMutateVariables
  >({
    mutation: merchantTypeGql.DELETE_MERCHANT_TYPE,
    variables: {
      id,
    },
  });

  if (!response.data?.deleteMerchantType) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.deleteMerchantType;
}

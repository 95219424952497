import { PayloadAction } from '@reduxjs/toolkit';
import {
  IPopupState,
  IPopupMessageState,
  IPopupConfirmationState,
} from './types';
import { popupState as initialState } from './states';

export function setPopupMessage(
  state: IPopupState,
  action: PayloadAction<IPopupMessageState>,
): void {
  state.popupMessage = {
    ...state.popupMessage,
    ...action.payload,
  };
}

export function clearPopupMessage(state: IPopupState): void {
  state.popupMessage = initialState.popupMessage;
}

export function setFloatingUser(
  state: IPopupState,
  action: PayloadAction<boolean>,
): void {
  state.floatingUser.show = action.payload;
}

export function setPopupConfirmation(
  state: IPopupState,
  action: PayloadAction<IPopupConfirmationState>,
): void {
  state.popupConfirmation = {
    ...state.popupConfirmation,
    ...action.payload,
  };
}

export function clearPopupConfirmation(state: IPopupState): void {
  state.popupConfirmation = initialState.popupConfirmation;
}

export function setFormChangePassword(
  state: IPopupState,
  action: PayloadAction<boolean>,
): void {
  state.formChangePassword.show = action.payload;
}

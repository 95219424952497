import { Sidebar, Navbar } from 'components';
import { SideMenuProps } from 'components/Sidebar/SidebarMenu';
import { MainWrapper } from './styles';
import { menus } from 'routes';
import { profileSelector } from 'modules';
import { useSelector, shallowEqual } from 'react-redux';

type DashboardContainerProps = {
  children?: React.ReactElement | React.ReactNode | JSX.Element | null;
};

function DashboardContainer({
  children,
}: DashboardContainerProps): JSX.Element {
  const profile = useSelector(profileSelector, shallowEqual);
  const permission = profile?.role.permissions || [];

  function checkPermission(): Array<SideMenuProps> {
    let newMenu = menus.filter(function (menu) {
      const submenu = menu.items.some(function (sub) {
        const itHasSubmenu = permission.includes(sub.path);
        return itHasSubmenu;
      });

      if (submenu) {
        return true;
      }

      return false;
    });

    newMenu = newMenu.map(function (menu) {
      const submenu = menu.items.filter(function (sub) {
        const itHasSubmenu = permission.includes(sub.path);
        return itHasSubmenu;
      });
      return {
        ...menu,
        items: submenu,
      };
    });

    return newMenu;
  }

  return (
    <>
      <Navbar />
      <Sidebar items={checkPermission()} />
      <MainWrapper>{children}</MainWrapper>
    </>
  );
}

export default DashboardContainer;

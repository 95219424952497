import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { imgUser } from 'assets';
import { floatingUser, confirmation, formChangePassword } from 'controls';
import { logout } from 'services';
import { profileSelector } from 'modules';
import { useSelector, shallowEqual } from 'react-redux';
import {
  Wrapper,
  ContentWrapper,
  Title,
  Subtitle,
  AvatarWrapper,
  Avatar,
  ButtonWrapper,
  ModalDialog,
  CloseIcon,
} from './styles';

interface Props {
  show?: boolean;
  onClose?: () => void;
}

function FloatingUser({ show, onClose }: Props): JSX.Element {
  const navigate = useNavigate();
  const profile = useSelector(profileSelector, shallowEqual);

  function onClosePopup(): void {
    floatingUser(false);
    onClose?.();
  }

  function confirmLogout(): void {
    onClosePopup();
    logout();
    navigate('/login', { replace: true });
  }

  function onClickLogout(): void {
    floatingUser(false);
    confirmation({
      title: 'Mau Keluar?',
      onConfirmed: confirmLogout,
      onCancel: () => floatingUser(true),
    });
  }

  function onClickChangePassword(): void {
    formChangePassword(true);
    floatingUser(false);
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onClosePopup}
        size="sm"
        backdrop="static"
        dialogAs={ModalDialog}
      >
        <Modal.Body>
          <Wrapper>
            <CloseIcon onClick={onClosePopup} />
            <AvatarWrapper>
              <Avatar src={imgUser} />
            </AvatarWrapper>
            <ContentWrapper>
              <Title>{profile?.name}</Title>
              <Subtitle>{profile?.email}</Subtitle>
            </ContentWrapper>
            <ButtonWrapper>
              <Button onClick={onClickChangePassword}>Change Password</Button>
              <Button variant="danger" onClick={onClickLogout}>
                Logout
              </Button>
            </ButtonWrapper>
          </Wrapper>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default memo(FloatingUser);

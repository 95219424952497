import { apoloClient, store, setMerchants, merchantGql } from 'modules';
import { IMerchant, IBulkCreateMerchant } from 'types';
import {
  IGetMerchantsRequest,
  IGetMerchantsResponse,
  IListMerchantQuery,
  IListMerchantQueryVariables,
  IDetailMerchantQuery,
  IDetailMerchantQueryVariables,
  ICreateMerchantRequest,
  ICreateMerchantMutate,
  ICreateMerchantMutateVariables,
  IUpdateMerchantRequest,
  IUpdateMerchantMutate,
  IUpdateMerchantMutateVariables,
  IDeleteMerchantMutate,
  IDeleteMerchantMutateVariables,
  IBulkCreateMerchantMutate,
  IBulkCreateMerchantMutateVariables,
} from './interfaces';
import { MESSAGES } from 'utils';

export async function getMerchants(
  input: IGetMerchantsRequest,
  storeToRedux = true,
): Promise<IGetMerchantsResponse> {
  try {
    if (storeToRedux) {
      store.dispatch(setMerchants({ loading: true }));
    }
    const response = await apoloClient.query<
      IListMerchantQuery,
      IListMerchantQueryVariables
    >({
      query: merchantGql.LIST_MERCHANT,
      variables: {
        input,
      },
    });

    const {
      page,
      limit,
      total,
      totalPage,
      merchants: data,
    } = response.data.listMerchant;

    if (storeToRedux) {
      store.dispatch(
        setMerchants({
          page,
          limit,
          total,
          totalPage,
          data,
          loading: false,
          error: false,
        }),
      );
    }

    return {
      page,
      limit,
      total,
      totalPage,
      data,
    };
  } catch (err) {
    if (storeToRedux) {
      store.dispatch(setMerchants({ loading: false, error: true }));
    }

    throw err;
  }
}

export async function getMerchant(id: string): Promise<IMerchant> {
  const response = await apoloClient.query<
    IDetailMerchantQuery,
    IDetailMerchantQueryVariables
  >({
    query: merchantGql.DETAIL_MERCHANT,
    variables: {
      id,
    },
  });

  return response.data.detailMerchant;
}

export async function createMerchant(
  form: ICreateMerchantRequest,
): Promise<IMerchant> {
  const response = await apoloClient.mutate<
    ICreateMerchantMutate,
    ICreateMerchantMutateVariables
  >({
    mutation: merchantGql.CREATE_MERCHANT,
    variables: {
      form,
    },
  });

  if (!response.data?.createMerchant) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data?.createMerchant;
}

export async function updateMerchant(
  form: IUpdateMerchantRequest,
): Promise<IMerchant> {
  const response = await apoloClient.mutate<
    IUpdateMerchantMutate,
    IUpdateMerchantMutateVariables
  >({
    mutation: merchantGql.UPDATE_MERCHANT,
    variables: {
      form,
    },
  });

  if (!response.data?.updateMerchant) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.updateMerchant;
}

export async function deleteMerchant(id: string): Promise<IMerchant> {
  const response = await apoloClient.mutate<
    IDeleteMerchantMutate,
    IDeleteMerchantMutateVariables
  >({
    mutation: merchantGql.DELETE_MERCHANT,
    variables: {
      id,
    },
  });

  if (!response.data?.deleteMerchant) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.deleteMerchant;
}

export async function bulkCreateMerchant(
  file: File | Blob,
): Promise<IBulkCreateMerchant> {
  const response = await apoloClient.mutate<
    IBulkCreateMerchantMutate,
    IBulkCreateMerchantMutateVariables
  >({
    mutation: merchantGql.BULK_CREATE_MERCHANT,
    variables: {
      file,
    },
  });

  if (!response.data?.bulkCreateMerchant) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data?.bulkCreateMerchant;
}

import { gql } from '@apollo/client';

export const LIST_MERCHANT_TYPE = gql`
  query listMerchantType($input: ListMerchantTypeInput!) {
    listMerchantType(listMerchantTypeInput: $input) {
      page
      limit
      total
      totalPage
      merchantTypes {
        id
        name
        status
      }
    }
  }
`;

export const DETAIL_MERCHANT_TYPE = gql`
  query detailMerchantType($id: String!) {
    detailMerchantType(id: $id) {
      id
      name
      status
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_MERCHANT_TYPE = gql`
  mutation createMerchantType($form: CreateMerchantTypeInput!) {
    createMerchantType(createMerchantTypeInput: $form) {
      id
      name
      status
    }
  }
`;

export const UPDATE_MERCHANT_TYPE = gql`
  mutation updateMerchantType($form: UpdateMerchantTypeInput!) {
    updateMerchantType(updateMerchantTypeInput: $form) {
      id
      name
      status
    }
  }
`;

export const DELETE_MERCHANT_TYPE = gql`
  mutation deleteMerchantType($id: String!) {
    deleteMerchantType(id: $id) {
      id
      name
      status
    }
  }
`;

import styled from 'styled-components';

interface WrapperProps {
  show?: boolean;
}

export const Wrapper = styled.div<Pick<WrapperProps, 'show'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: 0.3s;
  opacity: 0;
  z-index: -99999;
  ${(p): string =>
    p.show
      ? `
    z-index: 999999;
    opacity: 1;
  `
      : ''}
`;

export const Content = styled.div`
  text-align: center;
`;

export const Image = styled.img.attrs({
  alt: '',
})`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
`;

export const Text = styled.span`
  font-weight: bold;
  display: block;
`;

/* eslint-disable max-len */
import { ChangeEventHandler, useState } from 'react';
import {
  Col,
  Form,
  FormControlProps,
  FormLabelProps,
  FormSelectProps,
  Row,
} from 'react-bootstrap';
import ReactSelect from 'react-select';
import {
  HorizontalSizeType,
  OptionsType,
  OnSelectChangeType,
  LoadOptions,
} from 'types';
import { icEye, icEyeDisable } from 'assets';
import ReactAsyncSelect from 'react-select/async';

import {
  InputPassword,
  InputPasswordWrapper,
  IconButton,
  Icon,
} from './styles';

import HelperText from '../HelperText';

type FormInputProps = {
  id?: string;
  max?: number | string;
  min?: number | string;
  name?: string;
  step?: string;
  // eslint-disable-next-line prettier/prettier
  type:
    | 'text'
    | 'password'
    | 'email'
    | 'select'
    | 'textarea'
    | 'date'
    | 'time'
    | 'number';
  async?: boolean;
  label?: string;
  value?: string | number;
  error?: boolean;
  valid?: boolean;
  native?: boolean;
  message?: string;
  pattern?: string;
  onChange?: ChangeEventHandler;
  required?: boolean;
  disabled?: boolean;
  options?: Array<OptionsType>;
  placeholder?: string;
  errorMessage?: string;
  validMessage?: string;
  horizontal?: boolean;
  loadOptions?: LoadOptions;
  onSelectChange?: OnSelectChangeType;
  horizontalSize?: HorizontalSizeType;
  labelProps?: FormLabelProps;
  inputProps?: FormControlProps;
  selectProps?: FormSelectProps;
  extendInput?: JSX.Element | null;
};

function FormInput({
  id,
  max,
  min,
  name,
  step,
  type = 'text',
  label,
  async,
  value,
  error,
  valid,
  native,
  message,
  required,
  disabled,
  onChange,
  options,
  pattern,
  extendInput,
  loadOptions,
  horizontal,
  labelProps,
  inputProps,
  selectProps,
  placeholder,
  errorMessage,
  validMessage,
  onSelectChange,
  horizontalSize = { label: 2, input: 10, extendInput: 0 },
}: FormInputProps): JSX.Element | null {
  const [visible, setVisible] = useState<boolean>(false);

  function getControlHelperText(): boolean {
    if (type === 'password') {
      return false;
    }

    if (type === 'select' && !native) {
      return false;
    }

    return true;
  }

  function renderLabel(): JSX.Element | null {
    if (!label) return null;

    if (horizontal) {
      return (
        <Form.Label column sm={horizontalSize.label} {...labelProps}>
          {label}
        </Form.Label>
      );
    }

    return <Form.Label {...labelProps}>{label}</Form.Label>;
  }

  function renderInput(): JSX.Element | null {
    if (type === 'select') {
      if (native) {
        return (
          <Form.Select
            name={name}
            value={value}
            isValid={valid}
            required={required}
            isInvalid={error}
            disabled={disabled}
            onChange={onChange}
            {...selectProps}
          >
            {placeholder && <option label={placeholder} />}
            {options &&
              options?.length > 0 &&
              options.map((option, index) => (
                <option key={index} {...option} />
              ))}
          </Form.Select>
        );
      }

      const valueOptions =
        options?.filter((option) => option.value === value) || [];

      if (async) {
        return (
          <ReactAsyncSelect
            name={name}
            placeholder={placeholder}
            cacheOptions
            onChange={onSelectChange}
            defaultOptions={options}
            value={valueOptions}
            loadOptions={loadOptions}
          />
        );
      }

      return (
        <ReactSelect
          name={name}
          options={options}
          value={valueOptions}
          onChange={onSelectChange}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      );
    }

    if (type === 'password') {
      return (
        <InputPasswordWrapper>
          <InputPassword
            name={name}
            type={visible ? 'text' : 'password'}
            value={value}
            isValid={valid}
            required={required}
            isInvalid={error}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            autoComplete="new-password"
            {...inputProps}
          />
          <IconButton type="button" onClick={(): void => setVisible((v) => !v)}>
            {visible ? <Icon src={icEyeDisable} /> : <Icon src={icEye} />}
          </IconButton>
        </InputPasswordWrapper>
      );
    }

    return (
      <Form.Control
        name={name}
        type={type}
        value={value}
        isValid={valid}
        required={required}
        isInvalid={error}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="new-password"
        maxLength={max}
        max={max}
        min={min}
        step={step}
        pattern={pattern}
        {...inputProps}
      />
    );
  }

  function renderExtendInput(): JSX.Element | null {
    if (!extendInput || !horizontal) return null;

    return <Col sm={horizontalSize.extendInput}>{extendInput}</Col>;
  }

  if (horizontal) {
    return (
      <Form.Group as={Row} className="mb-3" controlId={id}>
        {renderLabel()}
        <Col sm={horizontalSize.input}>
          {renderInput()}
          <HelperText
            control={getControlHelperText()}
            error={error}
            errorMessage={errorMessage}
            valid={valid}
            validMessage={validMessage}
            message={message}
          />
        </Col>
        {renderExtendInput()}
      </Form.Group>
    );
  }

  return (
    <Form.Group className="mb-3" controlId={id}>
      {renderLabel()}
      {renderInput()}
      <HelperText
        control={getControlHelperText()}
        error={error}
        errorMessage={errorMessage}
        valid={valid}
        validMessage={validMessage}
        message={message}
      />
    </Form.Group>
  );
}

export default FormInput;

import { store, fileTypeGql, setFileTypes, apoloClient } from 'modules';
import { MESSAGES } from 'utils';
import { IFileType } from 'types';
import {
  IGetFileTypesRequest,
  IGetFileTypesResponse,
  IListFileTypeQuery,
  IListFileTypeQueryVariables,
  IDetailFileTypeQuery,
  IDetailFileTypeQueryVariables,
  ICreateFileTypeRequest,
  ICreateFileTypeMutate,
  ICreateFileTypeMutateVariables,
  IUpdateFileTypeRequest,
  IUpdateFileTypeMutate,
  IUpdateFileTypeMutateVariables,
  IDeleteFileTypeMutate,
  IDeleteFileTypeMutateVariables,
} from './interface';

export async function getFileTypes(
  input: IGetFileTypesRequest,
  storeToRedux = true,
): Promise<IGetFileTypesResponse> {
  try {
    if (storeToRedux) {
      store.dispatch(setFileTypes({ loading: true }));
    }
    const response = await apoloClient.query<
      IListFileTypeQuery,
      IListFileTypeQueryVariables
    >({
      query: fileTypeGql.LIST_FILE_TYPE,
      variables: {
        input,
      },
    });

    const result = response.data?.listFileType;

    if (!result) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    const { page, total, totalPage, limit, fileTypes: data } = result;

    if (storeToRedux) {
      store.dispatch(
        setFileTypes({
          page,
          total,
          totalPage,
          limit,
          data,
          loading: false,
          error: false,
        }),
      );
    }

    return {
      page,
      total,
      totalPage,
      limit,
      data,
    };
  } catch (err) {
    if (storeToRedux) {
      store.dispatch(setFileTypes({ loading: false, error: true }));
    }
    throw err;
  }
}

export async function getFileType(id: string): Promise<IFileType> {
  const response = await apoloClient.query<
    IDetailFileTypeQuery,
    IDetailFileTypeQueryVariables
  >({
    query: fileTypeGql.DETAIL_FILE_TYPE,
    variables: {
      id,
    },
  });

  return response.data?.detailFileType;
}

export async function createFileType(
  form: ICreateFileTypeRequest,
): Promise<IFileType> {
  const response = await apoloClient.mutate<
    ICreateFileTypeMutate,
    ICreateFileTypeMutateVariables
  >({
    mutation: fileTypeGql.CREATE_FILE_TYPE,
    variables: {
      form,
    },
  });

  if (!response.data?.createFileType) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.createFileType;
}

export async function updateFileType(
  form: IUpdateFileTypeRequest,
): Promise<IFileType> {
  const response = await apoloClient.mutate<
    IUpdateFileTypeMutate,
    IUpdateFileTypeMutateVariables
  >({
    mutation: fileTypeGql.UPDATE_FILE_TYPE,
    variables: {
      form,
    },
  });

  if (!response.data?.updateFileType) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.updateFileType;
}

export async function deleteFileType(id: string): Promise<IFileType> {
  const response = await apoloClient.mutate<
    IDeleteFileTypeMutate,
    IDeleteFileTypeMutateVariables
  >({
    mutation: fileTypeGql.DELETE_FILE_TYPE,
    variables: {
      id,
    },
  });

  if (!response.data?.deleteFileType) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return response.data.deleteFileType;
}

import { apoloClient, authGql, store, clearProfile } from 'modules';
import {
  setAuthToken,
  setAuthUser,
  clearAuthToken,
  clearAuthUser,
} from 'utils';
import { ILoginRequest, ILoginResponse } from './interfaces';

export async function login({
  email,
  password,
  humanKey,
}: ILoginRequest): Promise<ILoginResponse> {
  const response = await apoloClient.mutate({
    mutation: authGql.LOGIN,
    variables: {
      user: {
        email,
        password,
        humanKey,
      },
    },
  });

  const accessToken = response?.data?.login?.accessToken;
  const id = response?.data?.login?.id;

  if (!accessToken) {
    throw new Error('AccessToken not returned from response!');
  }

  if (!id) {
    throw new Error('User Id not returned from response!');
  }

  setAuthToken(accessToken);
  setAuthUser(id);

  return {
    id,
    accessToken,
  };
}

export function logout(): void {
  clearAuthToken();
  clearAuthUser();
  localStorage.clear();
  store.dispatch(clearProfile());
  apoloClient.resetStore();
}

import { store, apoloClient, roleGql, setRoles } from 'modules';
import {
  IGetRolesRequest,
  IGetRolesResponse,
  IGetRolesQuery,
  IGetRolesQueryVariables,
  IGetRoleQuery,
  IGetRoleQueryVariables,
  ICreateRoleRequest,
  ICreateRoleMutate,
  ICreateRoleMutateVariables,
  IUpdateRoleRequest,
  IUpdateRoleMutate,
  IUpdateRoleMutateVariables,
  IDeleteRoleMutate,
  IDeleteRoleMutateVariables,
} from './interfaces';
import { IRole } from 'types';
import { MESSAGES } from 'utils';

export async function getRoles(
  params?: IGetRolesRequest,
  storeToRedux = true,
): Promise<IGetRolesResponse> {
  try {
    if (storeToRedux) store.dispatch(setRoles({ loading: true }));

    const response = await apoloClient.query<
      IGetRolesQuery,
      IGetRolesQueryVariables
    >({
      query: roleGql.LIST_ROLE,
      variables: {
        listRoleInput: params,
      },
      fetchPolicy: 'network-only',
    });

    const result = response.data.listRole;

    if (!result) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    const dataRoles = {
      page: result.page,
      limit: result.limit,
      total: result.total,
      totalPage: result.totalPage,
      data: result.roles,
      loading: false,
      error: false,
    };

    if (storeToRedux) {
      store.dispatch(setRoles(dataRoles));
    }
    return dataRoles;
  } catch (err) {
    store.dispatch(setRoles({ loading: false, error: true }));
    throw err;
  }
}

export async function getRole(id: string): Promise<IRole> {
  const response = await apoloClient.query<
    IGetRoleQuery,
    IGetRoleQueryVariables
  >({
    query: roleGql.DETAIL_ROLE,
    variables: {
      id,
    },
  });

  const result = response.data?.detailRole;
  if (!result) {
    throw new Error('No data return from server!');
  }

  return result;
}

export async function createRole({
  name,
  displayName,
  status,
  permissions,
}: ICreateRoleRequest): Promise<IRole> {
  const response = await apoloClient.mutate<
    ICreateRoleMutate,
    ICreateRoleMutateVariables
  >({
    mutation: roleGql.CREATE_ROLE,
    variables: {
      form: {
        name,
        displayName,
        status,
        permissions,
      },
    },
  });

  const result = response.data?.createRole;
  if (!result) {
    throw new Error('No data returned from server');
  }

  return result;
}

export async function updateRole({
  id,
  name,
  displayName,
  status,
  permissions,
}: IUpdateRoleRequest): Promise<IRole> {
  const response = await apoloClient.mutate<
    IUpdateRoleMutate,
    IUpdateRoleMutateVariables
  >({
    mutation: roleGql.UPDATE_ROLE,
    variables: {
      form: {
        id,
        name,
        displayName,
        status,
        permissions,
      },
    },
  });

  const result = response.data?.updateRole;

  if (!result) {
    throw new Error(MESSAGES.NO_RETURN_DATA);
  }

  return result;
}

export async function deleteRole(id: string): Promise<IRole> {
  const response = await apoloClient.mutate<
    IDeleteRoleMutate,
    IDeleteRoleMutateVariables
  >({
    mutation: roleGql.DELETE_ROLE,
    variables: {
      id,
    },
  });

  const result = response.data?.deleteRole;

  if (!result) {
    throw new Error('No data returned from server');
  }

  return result;
}

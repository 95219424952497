import { gql } from '@apollo/client';

export const MERGE_LINKAJA = gql`
  mutation mergeDataLinkAja(
    $laReport1: Upload
    $laReport2: Upload
    $laReport3: Upload
  ) {
    mergeDataLinkAja(
      laReport1: $laReport1
      laReport2: $laReport2
      laReport3: $laReport3
    ) {
      mergedLinkAjaReport
    }
  }
`;

export const MERGE_DATA_EXCEL = gql`
  mutation mergeDataExcel($mergeDataInput: MergeDataInput!) {
    mergeDataExcel(mergeDataInput: $mergeDataInput) {
      mergedReport
    }
  }
`;

export const MERGE_DATA_CSV = gql`
  mutation mergeDataCsv($mergeDataCsvInput: MergeDataCsvInput!) {
    mergeDataCsv(mergeDataCsvInput: $mergeDataCsvInput) {
      mergedReport
    }
  }
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: calc(100% - 240px);
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: red;
  height: 110px;
  background-color: #ffffff;
  z-index: 10;
`;

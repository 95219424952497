import { PayloadAction } from '@reduxjs/toolkit';
import { IListPermissionState, IPermissionState } from './types';
import { permissionState as initialState } from './states';

export function setPermissions(
  state: IPermissionState,
  action: PayloadAction<IListPermissionState>,
): void {
  state.listPermission = {
    ...state.listPermission,
    ...action.payload,
  };
}

export function clearPermissions(state: IPermissionState): void {
  state.listPermission = initialState.listPermission;
}

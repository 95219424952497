import { apoloClient, userGql, store, setUsers } from 'modules';
import { IUser } from 'types';
import {
  IGetUserRequest,
  IGetUserResponse,
  ICreateForm,
  IUpdateUserMutate,
  IUpdateUserMutateVariables,
  IUpdateForm,
  IGetUserQueryVariables,
  IGetUserQuery,
  IDeleteUserMutate,
  IDeleteUserMutateVariables,
  ICreateUserMutate,
  ICreateUserMutateVariables,
} from './interfaces';
import { MESSAGES } from 'utils';

export async function getUsers(
  params?: IGetUserRequest,
): Promise<IGetUserResponse> {
  store.dispatch(setUsers({ loading: true }));

  try {
    const response = await apoloClient.query<
      IGetUserQuery,
      IGetUserQueryVariables
    >({
      query: userGql.LIST_USER,
      variables: {
        listUserInput: params,
      },
      fetchPolicy: 'network-only',
    });

    const data = response.data;

    if (!data) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    const result = data?.listUser;

    const dataUsers = {
      page: result?.page,
      limit: result?.limit,
      total: result?.total,
      totalPage: result?.totalPage,
      data: result?.users,
    };

    store.dispatch(
      setUsers({
        ...dataUsers,
        loading: false,
        error: false,
      }),
    );

    return dataUsers;
  } catch (err) {
    store.dispatch(
      setUsers({
        loading: false,
        error: true,
      }),
    );
    throw err;
  }
}

export async function createUser({
  name,
  email,
  roleId,
  status,
  password,
  passwordConfirmation,
}: ICreateForm): Promise<IUser> {
  try {
    const response = await apoloClient.mutate<
      ICreateUserMutate,
      ICreateUserMutateVariables
    >({
      mutation: userGql.CREATE_USER,
      variables: {
        form: {
          name,
          email,
          roleId,
          status,
          password,
          passwordConfirmation,
        },
      },
    });

    const result = response.data?.createUser;

    if (!result) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    return {
      id: result.id,
      name: result.name,
      email: result.email,
      status: result.status,
      role: result.role,
    };
  } catch (err) {
    throw err;
  }
}

export async function updateUser({
  id,
  name,
  email,
  roleId,
  status,
  password,
  passwordConfirmation,
}: IUpdateForm): Promise<IUser> {
  try {
    const response = await apoloClient.mutate<
      IUpdateUserMutate,
      IUpdateUserMutateVariables
    >({
      mutation: userGql.UPDATE_USER,
      variables: {
        form: {
          id,
          email,
          name,
          roleId,
          status,
          ...(password && { password }),
          ...(passwordConfirmation && { passwordConfirmation }),
        },
      },
      fetchPolicy: 'network-only',
    });

    const result = response.data?.updateUser;

    if (!result) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    return result;
  } catch (err) {
    throw err;
  }
}

export async function deleteUser(id: string): Promise<IUser> {
  try {
    const response = await apoloClient.mutate<
      IDeleteUserMutate,
      IDeleteUserMutateVariables
    >({
      mutation: userGql.DELETE_USER,
      variables: {
        id,
      },
    });

    const result = response.data?.deleteUser;

    if (!result) {
      throw new Error(MESSAGES.NO_RETURN_DATA);
    }

    return result;
  } catch (err) {
    throw err;
  }
}

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --bs-warning: #FF751D;
    --bs-warning-rgb: 255,117,29;
  }
`;

export default GlobalStyle;

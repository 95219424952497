import { memo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

interface Props {
  title: string;
  description?: string;
}

function PageInfo({ title, description }: Props): JSX.Element {
  return (
    <Helmet defaultTitle="Reconcile" titleTemplate="%s - Reconcile">
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}

PageInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

PageInfo.defaultProps = {
  title: '',
  description: '',
};

export default memo(PageInfo);

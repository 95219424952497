import { memo, useEffect } from 'react';
import { errorBagSelector } from 'modules';
import {
  popupGraphQLError,
  errorBag as errorBagAction,
  popupSystemError,
} from 'controls';
import { useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { parseJson } from 'utils';

function ErrorBag(): null {
  const navigate = useNavigate();
  const errorBag = useSelector(errorBagSelector, shallowEqual);

  useEffect(
    function () {
      if (errorBag.error) {
        // check if the error from backend including backend validation (GraphQL return 200)
        if (
          errorBag.error?.graphQLErrors &&
          errorBag.error?.graphQLErrors.length > 0
        ) {
          const message = errorBag.error?.graphQLErrors[0].message;
          const messageJson = parseJson<Error>(message);

          if (messageJson?.message) {
            popupGraphQLError(messageJson.message);
          } else {
            popupGraphQLError(message);
          }
        }
        // check if the error because frontend can't communicate with backend (GraphQL return 400)
        else if (errorBag.error?.networkError) {
          popupSystemError();
        } else {
          popupSystemError();
        }
        errorBagAction(undefined);
      }
    },
    [errorBag.error, errorBag.callback, navigate],
  );

  return null;
}

export default memo(ErrorBag);

import { gql } from '@apollo/client';

export const LIST_MERCHANT = gql`
  query listMerchant($input: ListMerchantInput!) {
    listMerchant(listMerchantInput: $input) {
      page
      limit
      total
      totalPage
      merchants {
        id
        mid
        name
        address
        ownerName
        bankId
        bankName
        bankAccount
        createdAt
        updatedAt
        merchantType {
          id
          name
          createdAt
          updatedAt
        }
        chargingSetups {
          id
          acquirerId
          acquirer {
            id
            name
            createdAt
            updatedAt
          }
          transactionType
          mdr
          mdrType
          mdrOnUs
          mdrOnUsType
          mdrOffUs
          mdrOffUsType
          charging
          chargingType
          chargingOnUs
          chargingOnUsType
          chargingOffUs
          chargingOffUsType
          serviceFee
          serviceFeeType
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DETAIL_MERCHANT = gql`
  query detailMerchant($id: String!) {
    detailMerchant(id: $id) {
      id
      mid
      name
      address
      ownerName
      bankId
      bankName
      bankAccount
      createdAt
      updatedAt
      merchantType {
        id
        name
        createdAt
        updatedAt
      }
      chargingSetups {
        id
        acquirerId
        acquirer {
          id
          name
          createdAt
          updatedAt
        }
        transactionType
        mdr
        mdrType
        mdrOnUs
        mdrOnUsType
        mdrOffUs
        mdrOffUsType
        charging
        chargingType
        chargingOnUs
        chargingOnUsType
        chargingOffUs
        chargingOffUsType
        serviceFee
        serviceFeeType
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_MERCHANT = gql`
  mutation createMerchant($form: CreateMerchantInput!) {
    createMerchant(createMerchantInput: $form) {
      id
    }
  }
`;

export const UPDATE_MERCHANT = gql`
  mutation updateMerchant($form: UpdateMerchantInput!) {
    updateMerchant(updateMerchantInput: $form) {
      id
    }
  }
`;

export const DELETE_MERCHANT = gql`
  mutation deleteMerchant($id: String!) {
    deleteMerchant(id: $id) {
      id
    }
  }
`;

export const BULK_CREATE_MERCHANT = gql`
  mutation bulkCreateMerchant($file: Upload!) {
    bulkCreateMerchant(merchantFile: $file) {
      errorMessages
      uploadedData
      successfulData
      failedData
    }
  }
`;

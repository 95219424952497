import { PayloadAction } from '@reduxjs/toolkit';
import { IListRoleState, IRoleState } from './types';
import { roleState as initialState } from './states';

export function setRoles(
  state: IRoleState,
  action: PayloadAction<IListRoleState>,
): void {
  state.listRole = {
    ...state.listRole,
    ...action.payload,
  };
}

export function clearRoles(state: IRoleState): void {
  state.listRole = initialState.listRole;
}

import dayjs from 'dayjs';
import { decurrency, pad, readSheetFileToData } from './helper';
import {
  YtiSettlementInput,
  ShopeeSettlementInput,
  KredivoSettlementInput,
  MandiriSettlementInput,
  YtiMcdSettlementInput,
  LinkAjaMCDReportInput,
  BalanceReportInput,
  PhpDeliveryReportInput,
  LinkAjaMCDPortalSettlementInput,
} from 'services/recon/interfaces';
import { ISSUERS } from 'utils';
import { headerFormatter, removeDot } from '../utils';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface MandiriLivinSettlementField {
  NMID?: string;
  MID?: string;
  AMOUNT?: string;
  BANK_ACCOUNT?: string;
  BANK_ACCOUNT_NAME?: string;
  ISSUER_NAME?: string;
  MDR_AMOUNT?: string;
  MERCHANT_OFFICIAL?: string;
  NET_AMOUNT?: string;
  REFF_ID_INVOICE_NO?: string;
  REFFERENCE_NUMBER?: string;
  TID?: string;
  TRADING_NAME?: string;
  TRXDATE?: string;
  TRXTIME?: string;
}

interface KredivoSettlementField {
  NAME?: string;
  TRANSACTION_DATE?: string | null;
  USER_ID?: string;
  SETTLEMENT_DATE?: string | null;
  CANCELLATION_DATE?: string | null;
  ORDER_ID?: string;
  TRANSACTION_ID?: string;
  AMOUNT?: string;
  TYPE?: string;
  STATUS?: string;
  SUB_MERCHANT?: string;
}

interface ShopeeSettlementField {
  PARTNER_MERCHANT?: string;
  PARTNER_MERCHANT_ID?: string;
  MERCHANT_NAME?: string;
  TRANSACTION_TYPE?: string;
  MERCHANT_SCOPE?: string;
  TRANSACTION_ID?: string;
  REFERENCE_ID?: string;
  PARENT_ID?: string;
  EXTERNAL_REFERENCE_ID?: string;
  ISSUER_IDENTIFIER?: string;
  TRANSACTION_AMOUNT?: number;
  FEE_MDR?: number;
  SETTLEMENT_AMOUNT?: number;
  TERMINAL_ID?: string;
  CREATE_TIME?: string;
  UPDATE_TIME?: string;
  ADJUSTMENT_REASON?: string;
  ENTITY_ID?: string;
  COFUNDING_AMOUNT?: number;
  REWARD_AMOUNT?: number;
  REWARD_TYPE?: string;
  PROMO_TYPE?: string;
  PAYMENT_METHOD?: string;
  CURRENCY_CODE?: string;
  VOUCHER_PROMOTION_EVENT_NAME?: string;
}

// Partner Merchant,Partner Merchant ID,Merchant name,Transaction type,Merchant Scope,Transaction ID,
// Reference ID,Parent ID,External reference ID,Issuer Identifier, Transaction amount , Fee (MDR) ,
// Settlement amount ,Terminal ID,Create time,Update time,Adjustment Reason,Entity ID,Cofunding Amount,
// Reward Amount,Reward Type,Promo Type,Payment Method,Tanggal

interface YtiMcdSettlementField {
  TRANSACTION_DATE?: string;
  TRANSACTION_TIME?: string;
  BANK_NAME?: string;
  COMP_CODE?: string;
  COMP_NAME?: string;
  STORE_CODE?: string;
  STORE_NAME?: string;
  MID?: string;
  TID?: string;
  YT_REF?: string;
  APPROVAL_CODE?: string;
  STAN?: string;
  TRANSACTION_ID?: string;
  BIT_NAME?: string;
  AMOUNT?: string;
  MDR_AMOUNT?: string;
  NET_AMOUNT?: string;
  UPLOAD_DATE?: string;
  TRADING_DATE?: string;
  POS_NO?: string;
  TRX_NO?: string;
  PROMO_TAG?: string;
  PROMO_ORIGINAL_AMOUNT?: string;
  PAYMENT_DATE?: string;
  PAYMENT_DETAIL?: string;
  TOTAL_PAYMENT_AMOUNT?: string;
  PAYMENT_TRX_RECON?: string;
  ISSUER_TRX_ID?: string;
}

interface YtiSettlementField {
  ISSUER?: string;
  TXN_DATE?: string;
  TXN_TYPE?: string;
  ISSUER_PAYEE?: string;
  YTI_TXN_REF?: string;
  ISSUER_TXN_REF?: string;
  ACCOUNT_ID?: string;
  YTI_MERCHANT_NAME?: string;
  YTI_MERCHANT_ID?: string;
  AMT?: string;
  LA?: string;
  DIFF?: string;
  TGL?: string;
  MERCHANT_TYPE?: string;
  TERMINAL_ID?: string;
}

interface LinkAjaMcdReportField {
  EID?: string;
  TRANSACTION_DATE?: string;
  TRANSACTION_TIME?: string;
  COMPANY_CODE?: string;
  MID?: string;
  TID?: string;
  CARD_NO?: string;
  TRANSACTION_AMOUNT?: string;
  REF_NO?: string;
  LINKED_REF_NUMB?: string;
  REASON_TYPE?: string;
  SEQUENCED_ID?: string;
  LINKED_TRANSACTION_TIME?: string;
  ISSUER_ID?: string;
  RETRIEVAL_REFERENCE_NUMBER?: string;
  ORIGINAL_TIME?: string;
}

interface LinkAjaMcdPortalSettlementField {
  RECEIPT_NO?: string;
  COMPLETION_TIME?: string;
  INITIATION_TIME?: string;
  DETAILS?: string;
  TRANSACTION_STATUS?: string;
  CURRENCY?: string;
  PAID_IN?: string;
  WITHDRAWN?: string;
  BALANCE?: string;
  REASON_TYPE?: string;
  OPPOSITE_PARTY?: string;
  LINKED_TRANSACTION_ID?: string;
  LA?: string;
  YTI?: string;
  DIFF?: string;
  MERCHANT_NAME?: string;
  MONTH?: string;
}

interface BalanceReportField {
  ACCOUNT_ID?: string;
  CUST_ID?: string;
  MERCHANT_NAME?: string;
  CURRENT_BALANCE?: string;
  OWNER_NAME?: string;
  BANK_ACC_NUMBER?: string;
  BANK_NAME?: string;
}

interface PhpDeliveryReportField {
  ISSUER?: string;
  TXN_DATE?: string;
  TXN_TYPE?: string;
  ISSUER_PAYEE?: string;
  YT_TX_REF?: string;
  ISSUER_TRX_ID?: string;
  ISSUER_TXN_REF?: string;
  YTI_TXN_EXTREF?: string;
  ACCOUNT_ID?: string;
  MERCHANT_NAME?: string;
  MERCHANT_MID?: string;
  AMT?: string;
  ONGKIR?: string;
  NET_AMOUNT?: string;
  TRX_TYPE?: string;
  PLATFORM?: string;
  SHIPPER_EXTERNAL_ID?: string;
  TRX_TIME?: string;
}

const mandiriSettlementHeaders = [
  'NMID',
  'MID',
  'AMOUNT',
  'BANK_ACCOUNT',
  'BANK_ACCOUNT_NAME',
  'ISSUER_NAME',
  'MDR_AMOUNT',
  'MERCHANT_OFFICIAL',
  'NET_AMOUNT',
  'REFF_ID/INVOICE_NO',
  'REFFERENCE_NUMBER',
  'TID',
  'TRADING_NAME',
  'TRXDATE',
  'TRXTIME',
];

const kredivoSettlementHeaders = [
  'NAME',
  'TRANSACTION_DATE',
  'USER_ID',
  'SETTLEMENT_DATE',
  'CANCELLATION_DATE',
  'ORDER_ID',
  'TRANSACTION_ID',
  'AMOUNT',
  'TYPE',
  'STATUS',
  'SUB_MERCHANT',
];

const shopeeSettlementHeaders = [
  'MERCHANT_HOST',
  'PARTNER_MERCHANT_ID',
  'MERCHANT_STORE_NAME',
  'TRANSACTION_TYPE',
  'MERCHANT_SCOPE',
  'TRANSACTION_ID',
  'REFERENCE_ID',
  'PARENT_ID',
  'EXTERNAL_REFERENCE_ID',
  'ISSUER_IDENTIFIER',
  'TRANSACTION_AMOUNT',
  'FEE_MDR',
  'SETTLEMENT_AMOUNT',
  'TERMINAL_ID',
  'CREATE_TIME',
  'UPDATE_TIME',
  'ADJUSTMENT_REASON',
  'ENTITY_ID',
  'FEE_COFOUNDING',
  'REWARD_AMOUNT',
  'REWARD_TYPE',
  'PROMO_TYPE',
  'PAYMENT_METHOD',
  'CURRENCY_CODE',
  'VOUCHER_PROMOTION_EVENT_NAME',
];

const mandiriYtiMcdSettlementHeaders = [
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'BANK_NAME',
  'COMP_CODE',
  'COMP_NAME',
  'STORE_CODE',
  'STORE_NAME',
  'MID',
  'TID',
  'YT_REF',
  'APPROVAL_CODE',
  'STAN',
  'TRANSACTION_ID',
  'BIT_NAME',
  'AMOUNT',
  'MDR_AMOUNT',
  'NET_AMOUNT',
  'UPLOAD_DATE',
  'TRADING_DATE',
  'POS_NO',
  'TRX_NO',
  'PROMO_TAG',
  'PROMO_ORIGINAL_AMOUNT',
  'PAYMENT_DATE',
  'PAYMENT_DETAIL',
  'TOTAL_PAYMENT_AMOUNT',
  'PAYMENT_TRX_RECON',
  'ISSUER_TRX_ID',
];

const kredivoYtiMcdSettlementHeaders = [
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'BANK_NAME',
  'COMP_CODE',
  'COMP_NAME',
  'STORE_CODE',
  'STORE_NAME',
  'MID',
  'TID',
  'YT_REF',
  'APPROVAL_CODE',
  'STAN',
  'TRANSACTION_ID',
  'BIT_NAME',
  'AMOUNT',
  'MDR_AMOUNT',
  'NET_AMOUNT',
  'UPLOAD_DATE',
  'TRADING_DATE',
  'POS_NO',
  'TRX_NO',
  'PROMO_TAG',
  'PROMO_ORIGINAL_AMOUNT',
  'PAYMENT_DATE',
  'PAYMENT_DETAIL',
  'TOTAL_PAYMENT_AMOUNT',
  'PAYMENT_TRX_RECON',
];

const shopeeYtiMcdSettlementHeaders = [
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'BANK_NAME',
  'COMP_CODE',
  'COMP_NAME',
  'STORE_CODE',
  'STORE_NAME',
  'MID',
  'TID',
  'YT_REF',
  'APPROVAL_CODE',
  'STAN',
  'TRANSACTION_ID',
  'BIT_NAME',
  'AMOUNT',
  'MDR_AMOUNT',
  'NET_AMOUNT',
  'UPLOAD_DATE',
  'TRADING_DATE',
  'POS_NO',
  'TRX_NO',
  'PROMO_TAG',
  'PROMO_ORIGINAL_AMOUNT',
  'PAYMENT_DATE',
  'PAYMENT_DETAIL',
  'TOTAL_PAYMENT_AMOUNT',
  'PAYMENT_TRX_RECON',
];
const linkAjaMCDReportMergeHeaders = [
  'EID',
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'COMPANY_CODE',
  'MID',
  'MDR',
  'NET_AMOUNT',
  'TID',
  'CARD_NO',
  'TRANSACTION_AMOUNT',
  'REF_NO',
  'LINKED_REF_NUMB',
  'REASON_TYPE',
  'SEQUENCED_ID',
  'LINKED_TRANSACTION_TIME',
  'ISSUER_ID',
  'RETRIEVAL_REFERENCE_NUMBER',
  'ORIGINAL_TIME',
];

const linkAjaMSMEReportMergeHeaders = [
  'EID',
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'COMPANY_CODE',
  'MID',
  'TID',
  'CARD_NO',
  'TRANSACTION_AMOUNT',
  'MDR',
  'NET_AMOUNT',
  'REF_NO',
  'LINKED_REF_NUMB',
  'REASON_TYPE',
  'SEQUENCED_ID',
  'LINKED_TRANSACTION_TIME',
  'ISSUER_ID',
  'RETRIEVAL_REFERENCE_NUMBER',
  'ORIGINAL_TIME',
  'CHARGEBACKORIGINALBUSINESSTRANSACTIONID',
  'ADDITIONALDATANATIONAL',
  'MERCHANTNAMELOCATION',
  'REMARK',
];

const linkAjaDataExpansionHeaders = [
  'NO',
  'TOP ORG NAME',
  'BIZ ORG NAME',
  'SHORT CODE',
  'ODERID',
  'LINKEDORDERID',
  'LINKEDORDERID CREATE TIME',
  'LINKEDORDERID END TIME',
  'INVOICE ID',
  'TRANS END TIME',
  'TRANS INITIATE TIME',
  'TRANSACTION TYPE',
  'TRANSACTION SCENARIO',
  'TRANS STATUS',
  'GATEWAY',
  'TID',
  'TRX ID',
  'TRANSACTION REFERENCE NUMBER',
  'BILL REF NUMBER',
  'NOTE',
  'RECHARGED MSISDN',
  'PARTNER TRX ID',
  'APPLINK TRX ID',
  'ACCOUNT',
  'DEBIT',
  'CREDIT',
  'BALANCE',
];
const linkAjaMCDReportHeaders = [
  'EID',
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'COMPANY_CODE',
  'MID',
  'TID',
  'CARD_NO',
  'TRANSACTION_AMOUNT',
  'REF_NO',
  'LINKED_REF_NUMB',
  'REASON_TYPE',
  'SEQUENCED_ID',
  'LINKED_TRANSACTION_TIME',
  'ISSUER_ID',
  'RETRIEVAL_REFERENCE_NUMBER',
  'ORIGINAL_TIME',
];

const balanceReportHeaders = [
  'ACCOUNT_ID',
  'CUST_ID',
  'MERCHANT_NAME',
  'CURRENT_BALANCE',
  'OWNER_NAME',
  'BANK_ACC_NUMBER',
  'BANK_NAME',
];

const phpDeliveryReportHeaders = [
  'ISSUER',
  'TXN_DATE',
  'TXN_TYPE',
  'ISSUER_PAYEE',
  'YT_TX_REF',
  'ISSUER_TRX_ID',
  'ISSUER_TXN_REF',
  'YTI_TXN_EXTREF',
  'ACCOUNT_ID',
  'MERCHANT_NAME',
  'MERCHANT_MID',
  'AMT',
  'TERMINAL_ID',
  'ONGKIR',
  'NET_AMOUNT',
  'TRX_TYPE',
  'PLATFORM',
  'SHIPPER_EXTERNAL_ID',
  'TRX_TIME',
  'NEW_ONGKIR',
  'STATUS',
  'PROVIDER',
  'SERVICE_FEE',
];

const ytiSettlementHeaders = [
  'ISSUER',
  'TXN_DATE',
  'TXN_TYPE',
  'ISSUER_PAYEE',
  'YTI_TXN_REF',
  'ISSUER_TXN_REF',
  'YTI_TXN_EXTREF',
  'ACCOUNT_ID',
  'YTI_MERCHANT_NAME',
  'YTI_MERCHANT_ID',
  'AMT',
  'TERMINAL_ID',
];

const ytiSettlementMergeDataHeaders = [
  'ISSUER',
  'TXN_DATE',
  'TXN_TYPE',
  'ISSUER_PAYEE',
  'VOUCHER_CODE',
  'YTI_TXN_REF',
  'ISSUER_TXN_REF',
  'YTI_TXN_EXTREF',
  'ACCOUNT_ID',
  'YTI_MERCHANT_NAME',
  'YTI_MERCHANT_ID',
  'AMT',
  'TERMINAL_ID',
];
const ytiMcdSettlementField = [
  'TRANSACTION_DATE',
  'TRANSACTION_TIME',
  'BANK_NAME',
  'COMP_CODE',
  'COMP_NAME',
  'STORE_CODE',
  'STORE_NAME',
  'MID',
  'TID',
  'YT_REF',
  'APPROVAL_CODE',
  'STAN',
  'TRANSACTION_ID',
  'BIT_NAME',
  'AMOUNT',
  'MDR_AMOUNT',
  'NET_AMOUNT',
  'UPLOAD_DATE',
  'TRADING_DATE',
  'POS_NO',
  'TRX_NO',
  'PROMO_TAG',
  'PROMO_ORIGINAL_AMOUNT',
  'PAYMENT_DATE',
  'PAYMENT_DETAIL',
  'TOTAL_PAYMENT_AMOUNT',
  'PAYMENT_TRX_RECON',
];
const opsSettlementField = [
  'RECEIPT_NO',
  'COMPLETION_TIME',
  'AMOUNT',
  'ACCT_ID',
  'MERCHANT_NAME',
  'MERCHANT_ID',
  'BANK_ACCOUNT_NAME',
  'BANK_ACCOUNT_NUMBER',
  'BANK_NAME',
];
const ovoSettlementField = [
  'TRANSACTIONDATE',
  'TRANSACTIONTIME',
  'GROUPID',
  'GROUPNAME',
  'MERCHANTID',
  'MERCHANTNAME',
  'STORECODE',
  'STORENAME',
  'TERMINALID',
  'MERCHANTINVOICE',
  'APPROVALCODE',
  'GATEAWAYREFERENCE',
  'TRANSACTIONTYPE',
  'TRANSACTIONAMOUNT',
  'CASHAMOUNTUSED',
  'OVOPOINTUSED',
  'MDROVOCASH',
  'NETTAMOUNTOVOCASH',
  'MDROVOPOINT',
  'NETTAMOUNTOVOPOINT',
  'OVOPAYLATTERUSED',
  'MDROVOPAYLATER',
  'NETTSETTLEMENT',
  'BILLINGID',
  'REFFNO',
  'TRACENO',
  'SAVINGSAMOUNTUSED',
  'NOREKENINGMERCHANT',
  'BANKTUJUAN',
  'POINTFUNDEDMERCHANT',
  'POINTFUNDEDOVO',
  'POINTID',
  'CAMPAIGNNAME',
  'PROMOMODULE',
  'REFUNDOVOCASH',
  'REFUNDOVOPOINT',
  'REFUNDOVOPAYLATER',
  'ORDERID',
  'ORIGINALREFID',
  'ORIGINALTRXDATE',
];
function cleanValueData(value: unknown): unknown {
  if (typeof value === 'string') {
    if (!value) return '';

    if (value.toLowerCase() === '(Filled manually)'.toLowerCase()) {
      return '';
    }
    let newValue = value;

    if (value.indexOf("'") !== -1 && value.indexOf("'") === 0) {
      newValue = value.replace(/'/g, '');
    }

    return newValue;
  }

  if (typeof value === 'undefined') {
    return '';
  }

  return value;
}

function createHeaderField(s: string): string {
  return s
    .trim()
    .toUpperCase()
    .replace(/\s/g, '_')
    .replace(/\//g, '_')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/\./g, '');
}

export async function validateMandiriSettlementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== mandiriSettlementHeaders.length) {
    return false;
  }

  const validHeader = mandiriSettlementHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateLinkAjaMCDReportHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== linkAjaMCDReportHeaders.length) {
    return false;
  }

  const validHeader = linkAjaMCDReportHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateLinkAjaDataExpansionHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== linkAjaDataExpansionHeaders.length) {
    return false;
  }

  const validHeader = linkAjaDataExpansionHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateLinkAjaMCDReportMergeHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== linkAjaMCDReportMergeHeaders.length) {
    return false;
  }

  const validHeader = linkAjaMCDReportHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}

export async function validateLinkAjaMCDMSMEReportMergeHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== linkAjaMSMEReportMergeHeaders.length) {
    return false;
  }

  const validHeader = linkAjaMSMEReportMergeHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateBalanceReportHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== balanceReportHeaders.length) {
    return false;
  }

  const validHeader = balanceReportHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validatePhpDeliveryReportHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== phpDeliveryReportHeaders.length) {
    return false;
  }

  const validHeader = phpDeliveryReportHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateYtiSettelementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== ytiSettlementHeaders.length) {
    return false;
  }

  const validHeader = ytiSettlementHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateYtiSettelementMergeDataHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== ytiSettlementMergeDataHeaders.length) {
    return false;
  }

  const validHeader = ytiSettlementMergeDataHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateMcdSettelementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  if (sheetheaders.length !== ytiMcdSettlementField.length) {
    return false;
  }

  const validHeader = ytiMcdSettlementField.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateOpsSettlementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) =>
    createHeaderField(removeDot(v.toUpperCase())),
  );

  if (sheetheaders.length !== opsSettlementField.length) {
    return false;
  }

  const validHeader = opsSettlementField.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateOvoSettlementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) =>
    createHeaderField(removeDot(v.toUpperCase())),
  );

  if (sheetheaders.length !== ovoSettlementField.length) {
    return false;
  }

  const validHeader = ovoSettlementField.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateKredivoSettlementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  if (sheetheaders.length !== kredivoSettlementHeaders.length) {
    return false;
  }

  const validHeader = kredivoSettlementHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateShopeeSettlementHeader(
  file: File | Blob,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;

  sheetheaders = sheetheaders.map((v) =>
    createHeaderField(headerFormatter(v.toUpperCase())),
  );

  if (sheetheaders.length !== shopeeSettlementHeaders.length) {
    return false;
  }

  const validHeader = shopeeSettlementHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}

function getHeaderIssuer(issuer: string): Array<string> {
  switch (issuer) {
    case ISSUERS.MANDIRI_LIVIN:
      return mandiriYtiMcdSettlementHeaders;
    case ISSUERS.KREDIVO:
      return kredivoYtiMcdSettlementHeaders;
    case ISSUERS.SHOPEE:
      return shopeeYtiMcdSettlementHeaders;
    default:
      return [];
  }
}

export async function validateYtiMcdSettlementHeader(
  file: File | Blob,
  issuer: string,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  const ytiMcdSettlementHeaders = getHeaderIssuer(issuer);

  if (sheetheaders.length !== ytiMcdSettlementHeaders.length) {
    return false;
  }

  const validHeader = ytiMcdSettlementHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}
export async function validateYtiMcdReportHeader(
  file: File | Blob,
  issuer: string,
): Promise<boolean> {
  const data = await readSheetFileToData(file);

  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  const ytiMcdSettlementHeaders = getHeaderIssuer(issuer);

  if (sheetheaders.length !== ytiMcdSettlementHeaders.length) {
    return false;
  }

  const validHeader = ytiMcdSettlementHeaders.some((v) => {
    const valid = sheetheaders.includes(v);
    return valid;
  });

  if (!validHeader) {
    return false;
  }

  return true;
}

export function mappingMandiriSettlement(
  data: Array<unknown>,
): Array<MandiriSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    const b = d as MandiriLivinSettlementField;

    return b;
  });

  const result = newSheetData.map((sheetdata): MandiriSettlementInput => {
    let trxdate = dayjs(sheetdata?.TRXDATE, 'DD-MM-YYYY').format('YYYY-MM-DD');

    if (trxdate === 'Invalid Date') {
      const refixdate = String(sheetdata?.TRXDATE).split('-');
      const y = refixdate[2];
      const m = refixdate[1];
      const d = refixdate[0];
      trxdate = `${y}-${m}-${d}`;
    }
    return {
      nmid: sheetdata?.NMID,
      mid: sheetdata?.MID,
      amount: decurrency(sheetdata?.AMOUNT),
      bankAccount: sheetdata?.BANK_ACCOUNT,
      bankAccountName: sheetdata?.BANK_ACCOUNT_NAME,
      issuerIdentifier: sheetdata?.ISSUER_NAME,
      mdrAmount: decurrency(sheetdata?.MDR_AMOUNT),
      merchantOfficial: sheetdata?.MERCHANT_OFFICIAL,
      netAmount: decurrency(sheetdata?.NET_AMOUNT),
      referenceId: sheetdata?.REFF_ID_INVOICE_NO,
      referenceNumber: sheetdata?.REFFERENCE_NUMBER,
      tid: sheetdata?.TID,
      tradingName: sheetdata?.TRADING_NAME,
      transactionDate: trxdate,
      transactionTime: sheetdata?.TRXTIME
        ? pad(sheetdata?.TRXTIME, 6)
        : '00:00:00',
    };
  });

  return result;
}

export function mappingKredivoSettlement(
  data: Array<unknown>,
): Array<KredivoSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    const b = d as KredivoSettlementField;

    return b;
  });

  const result = newSheetData.map((sheetdata): KredivoSettlementInput => {
    return {
      amount: decurrency(sheetdata?.AMOUNT),
      cancellationDate: sheetdata?.CANCELLATION_DATE
        ? dayjs(sheetdata?.CANCELLATION_DATE).format('YYYY-MM-DD')
        : null,
      name: sheetdata?.NAME,
      orderId: sheetdata?.ORDER_ID,
      settlementDate: sheetdata?.SETTLEMENT_DATE
        ? dayjs(sheetdata?.SETTLEMENT_DATE).format('YYYY-MM-DD')
        : null,
      status: sheetdata?.STATUS,
      subMerchant: sheetdata?.SUB_MERCHANT,
      transactionDate: sheetdata?.TRANSACTION_DATE || null,
      transactionId: sheetdata?.TRANSACTION_ID,
      type: sheetdata?.TYPE,
      userId: sheetdata?.USER_ID,
    };
  });

  return result;
}

export function mappingShopeeSettlement(
  data: Array<unknown>,
): Array<ShopeeSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    const b = d as ShopeeSettlementField;

    return b;
  });

  const result = newSheetData.map((sheetdata): ShopeeSettlementInput => {
    return {
      partnerMerchant: sheetdata?.PARTNER_MERCHANT,
      partnerMerchantId: sheetdata?.PARTNER_MERCHANT_ID,
      merchantName: sheetdata?.MERCHANT_NAME,
      transactionType: sheetdata?.TRANSACTION_TYPE,
      adjustmentReason: sheetdata?.ADJUSTMENT_REASON,
      cofundingAmount: Number(sheetdata?.COFUNDING_AMOUNT),
      createTime: sheetdata?.CREATE_TIME,
      updateTime: sheetdata?.UPDATE_TIME,
      entityId: sheetdata?.ENTITY_ID,
      externalReferenceId: sheetdata?.EXTERNAL_REFERENCE_ID,
      fee: Number(sheetdata?.FEE_MDR),
      issuerIdentifier: sheetdata?.ISSUER_IDENTIFIER,
      merchantScope: sheetdata?.MERCHANT_SCOPE,
      parentId: sheetdata?.PARENT_ID,
      promoType: sheetdata?.PROMO_TYPE,
      referenceId: sheetdata?.REFERENCE_ID,
      rewardAmount: Number(sheetdata?.REWARD_AMOUNT),
      rewardType: sheetdata?.REWARD_TYPE,
      settlementAmount: Number(sheetdata?.SETTLEMENT_AMOUNT),
      terminalId: sheetdata?.TERMINAL_ID,
      transactionAmount: Number(sheetdata.TRANSACTION_AMOUNT),
      transactionId: sheetdata?.TRANSACTION_ID,
    };
  });

  return result;
}

export function mappingLinkAjaMcdReport(
  data: Array<unknown>,
): Array<LinkAjaMCDReportInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));

  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    const b = d as LinkAjaMcdReportField;

    return b;
  });

  const result = newSheetData.map((sheetdata): LinkAjaMCDReportInput => {
    return {
      eid: sheetdata.EID,
      cardNo: sheetdata.CARD_NO,
      companyCode: sheetdata?.COMPANY_CODE,
      issuerId: sheetdata.ISSUER_ID,
      linkedRefNumb: sheetdata.LINKED_REF_NUMB,
      linkedTransactionTime: sheetdata.LINKED_TRANSACTION_TIME,
      mid: sheetdata.MID,
      originalTime: sheetdata.ORIGINAL_TIME,
      reasonType: sheetdata.REASON_TYPE,
      refNo: sheetdata.REF_NO,
      retrievalReferenceNumber: sheetdata.RETRIEVAL_REFERENCE_NUMBER,
      sequencedId: sheetdata.SEQUENCED_ID,
      tid: sheetdata?.TID,
      transactionAmount: decurrency(sheetdata?.TRANSACTION_AMOUNT),
      transactionDate: sheetdata?.TRANSACTION_DATE,
      transactionTime: sheetdata?.TRANSACTION_TIME,
    };
  });

  return result;
}

export function mappingLinkAjaMcdPortalSettlement(
  data: Array<unknown>,
): Array<LinkAjaMCDPortalSettlementInput> {
  let sheetheaders = data[5] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  const sheetdatas = data.slice(6) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    return d;
  });

  const result = newSheetData.map(
    (
      sheetdata: LinkAjaMcdPortalSettlementField,
    ): LinkAjaMCDPortalSettlementInput => {
      return {
        balance: decurrency(sheetdata.BALANCE),
        completionTime: dayjs(
          sheetdata?.COMPLETION_TIME,
          'DD/MM/YYYY HH:mm:ss',
        ).toISOString(),
        curency: sheetdata.CURRENCY,
        details: sheetdata.DETAILS,
        initiationTime: dayjs(
          sheetdata?.INITIATION_TIME,
          'DD/MM/YYYY HH:mm:ss',
        ).toISOString(),
        linkedTransactionId: sheetdata.LINKED_TRANSACTION_ID,
        oppositeParty: sheetdata.OPPOSITE_PARTY,
        paidIn: decurrency(sheetdata.PAID_IN),
        reasonType: sheetdata.REASON_TYPE,
        receiptNo: sheetdata.RECEIPT_NO,
        transactionStatus: sheetdata.TRANSACTION_STATUS,
        withdrawn: decurrency(sheetdata.WITHDRAWN),
      };
    },
  );

  return result;
}

export function mappingYtiMcdSettlement(
  data: Array<unknown>,
): Array<YtiMcdSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    return d;
  });

  const result = newSheetData.map(
    (sheetdata: YtiMcdSettlementField): YtiMcdSettlementInput => {
      return {
        transactionDate: sheetdata?.TRANSACTION_DATE,
        transactionTime: sheetdata?.TRANSACTION_TIME,
        bankName: sheetdata?.BANK_NAME,
        compCode: sheetdata?.COMP_CODE,
        compName: sheetdata?.COMP_NAME,
        storeCode: sheetdata?.STORE_CODE,
        storeName: sheetdata?.STORE_NAME,
        mid: sheetdata?.MID,
        tid: sheetdata?.TID,
        ytRef: sheetdata?.YT_REF,
        approvalCode: sheetdata?.APPROVAL_CODE,
        stan: sheetdata?.STAN,
        transactionId: sheetdata?.TRANSACTION_ID,
        bitName: sheetdata?.BIT_NAME,
        amount: decurrency(sheetdata?.AMOUNT),
        mdrAmount: decurrency(sheetdata?.MDR_AMOUNT),
        netAmount: decurrency(sheetdata?.NET_AMOUNT),
        uploadDate: sheetdata?.UPLOAD_DATE,
        tradingDate: sheetdata?.TRADING_DATE,
        posNo: sheetdata?.POS_NO,
        trxNo: sheetdata?.TRX_NO,
        promoTag: sheetdata?.PROMO_TAG,
        promoOriginalAmount: decurrency(sheetdata?.PROMO_ORIGINAL_AMOUNT),
        paymentDate: sheetdata?.PAYMENT_DATE,
        paymentDetail: sheetdata?.PAYMENT_DETAIL,
        totalPaymentAmount: decurrency(sheetdata?.TOTAL_PAYMENT_AMOUNT),
        paymentTrxRecon: decurrency(sheetdata?.PAYMENT_TRX_RECON),
        issuerTrxId: sheetdata?.ISSUER_TRX_ID,
      };
    },
  );

  return result;
}

export function mappingYtiSettlement(
  data: Array<unknown>,
): Array<YtiSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    return d;
  });

  const result = newSheetData.map(
    (sheetdata: YtiSettlementField): YtiSettlementInput => {
      return {
        accountId: sheetdata?.ACCOUNT_ID,
        amt: decurrency(sheetdata.AMT),
        issuer: sheetdata.ISSUER,
        issuerPayee: sheetdata.ISSUER_PAYEE,
        issuerTxnRef: sheetdata.ISSUER_TXN_REF,
        terminalId: sheetdata.TERMINAL_ID,
        txnDate: sheetdata.TXN_DATE,
        txnType: sheetdata.TXN_TYPE,
        ytiMerchantId: sheetdata.YTI_MERCHANT_ID,
        ytiMerchantName: sheetdata.YTI_MERCHANT_NAME,
        ytiTxnExref: sheetdata.YTI_TXN_REF,
        ytiTxnRef: sheetdata.YTI_TXN_REF,
      };
    },
  );

  return result;
}

export function mappingBalanceReport(
  data: Array<unknown>,
): Array<YtiSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    return d;
  });

  const result = newSheetData.map(
    (sheetdata: BalanceReportField): BalanceReportInput => {
      return {
        accountId: sheetdata.ACCOUNT_ID,
        bankAccNumber: sheetdata.BANK_ACC_NUMBER,
        bankName: sheetdata.BANK_NAME,
        custId: sheetdata.CUST_ID,
        merchantName: sheetdata.MERCHANT_NAME,
        ownerName: sheetdata.OWNER_NAME,
        totalAmount: decurrency(sheetdata.CURRENT_BALANCE),
      };
    },
  );

  // result = result.slice(0, 9);

  return result;
}

export function mappingPhpDeliveryReport(
  data: Array<unknown>,
): Array<YtiSettlementInput> {
  let sheetheaders = data[0] as Array<string>;
  sheetheaders = sheetheaders.map((v) => createHeaderField(v.toUpperCase()));
  const sheetdatas = data.slice(1) as Array<Array<string>>;

  const newSheetData = sheetdatas.map((sheetdata) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    let index = 0;
    do {
      // eslint-disable-next-line security/detect-object-injection
      d[sheetheaders[index]] = cleanValueData(sheetdata[index]);
      index++;
    } while (index < sheetheaders.length);

    return d;
  });

  const result = newSheetData.map(
    (sheetdata: PhpDeliveryReportField): PhpDeliveryReportInput => {
      return {
        accountId: sheetdata.ACCOUNT_ID,
        amt: decurrency(sheetdata.AMT),
        issuer: sheetdata.ISSUER,
        issuerPayee: sheetdata.ISSUER_PAYEE,
        issuerTrxId: sheetdata.ISSUER_TRX_ID,
        issuerTxnRef: sheetdata.ISSUER_TRX_ID,
        merchantMid: sheetdata.MERCHANT_MID,
        merchantName: sheetdata.MERCHANT_NAME,
        netAmount: decurrency(sheetdata.NET_AMOUNT),
        ongkir: decurrency(sheetdata.ONGKIR),
        platform: sheetdata.PLATFORM,
        shipperExternalId: sheetdata.SHIPPER_EXTERNAL_ID,
        trxTime: sheetdata.TRX_TIME,
        trxType: sheetdata.TRX_TYPE,
        txnDate: sheetdata.TXN_DATE,
        txnType: sheetdata.TXN_TYPE,
        ytTxRef: sheetdata.YT_TX_REF,
        ytiTxnExref: sheetdata.YTI_TXN_EXTREF,
      };
    },
  );

  return result;
}
